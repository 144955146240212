import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaLinkedin, FaTwitter, FaFacebookSquare } from "react-icons/fa";
import post from "../../images/post.jpg";

const Post = () => {
  useEffect(() => {
    AOS.init({ duration: 1500, easing: "ease-out-quart" });
  }, []);

  return (
    <div className="bg-[#0a0a0a] text-white font-sans tracking-wide overflow-hidden">
      {/* Hero Section - Minimalist Cinematic Opener */}
      <section
        className="relative min-h-screen flex items-center justify-center text-center bg-cover bg-fixed overflow-hidden"
        style={{
          backgroundImage: `url(${post})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-80 backdrop-blur-lg"></div>
        <div className="relative z-10 px-6 animate-fade-in" data-aos="fade-in">
          <h1 className="text-5xl md:text-6xl font-bold tracking-tight leading-tight text-white" data-aos="zoom-out" data-aos-duration="1200">
            I Have a Dream…
          </h1>
          <p className="max-w-3xl mx-auto text-lg md:text-xl mt-6 leading-relaxed text-gray-300" data-aos="fade-up" data-aos-delay="400">
          A dream where knowledge is not confined by institutions, where learning is not dictated by gatekeepers, and where teaching is not a privilege but a birthright. A dream where every person who holds a spark of knowledge—be it a retired physicist in Berlin, a self-taught musician in Chennai, or a prodigy in a remote village of Maharashtra—can share it with the world.          </p>
        </div>
      </section>

      {/* Vision Section - Clean and Minimal */}
      <section className="container mx-auto px-6 py-24 max-w-5xl text-lg leading-relaxed space-y-12">
        <div className="text-center" data-aos="fade-in">
          <h2 className="text-4xl font-bold tracking-tight">This is Totle.</h2>
          <p className="mt-4 text-lg text-gray-400" data-aos="fade-up" data-aos-delay="300">
            A revolution in education, in opportunity, in human potential.
          </p>
        </div>
      </section>

      {/* Elegant Callouts */}
      <section className="py-24 text-center bg-[#141414]">
        <h2 className="text-4xl font-bold" data-aos="fade-up">The End of Sacrifice</h2>
        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="300">
        For too long, we have been forced to make impossible choices—passion or survival, knowledge or economy, purpose or paycheck. We tell the artist to get a ‘real job,’ the researcher to chase funding, the dreamer to ‘be practical.’ How much brilliance have we lost because of this? How many Einsteins never got their patent clerk jobs, how many Da Vincis never picked up a brush?
        </p>
        <br/>
        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="400">
        It doesn’t have to be this way.
        </p>
        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="300">
        With Totle, everything you know becomes a resource, and no one has to choose between their calling and their livelihood. If you know something, you can teach it. If you want to learn, you can find the best mind to guide you—without barriers, without borders, without bureaucracy. And in doing so, you earn. Real, tangible value—because expertise deserves compensation. This is not charity. This is the realignment of incentives.        </p>
      </section>

      {/* Scrolling Experience */}
      <section className="container mx-auto px-6 py-24 grid md:grid-cols-2 gap-12">
        <div className="p-8 bg-[#1a1a1a] rounded-xl shadow-lg text-center" data-aos="fade-right">
          <h3 className="text-3xl font-semibold">The Decentralization of Knowledge</h3>
          <p className="mt-4 text-gray-400">Education today is controlled. Structured. Limited. Why should learning Japanese be confined to a classroom in Delhi when a native speaker in Osaka can teach it better? Why should a child in a remote village have no access to a world-class mathematician?</p>
        </div>
        <div className="p-8 bg-[#1a1a1a] rounded-xl shadow-lg text-center" data-aos="fade-left" data-aos-delay="300">
          <h3 className="text-3xl font-semibold">Totle will tear down these walls.</h3>
          <p className="mt-4 text-gray-400">It will connect people at a topic level, not through rigid subjects or institutions, but through curiosity and expertise. If a person is the best at what they do, they should be the one to teach it—no matter where they are, no matter who they are. And because teaching is directly incentivized, knowledge will flow freely—not as a favor, but as a marketplace of expertise where both sides win.</p>
        </div>
        <div className="p-8 bg-[#1a1a1a] rounded-xl shadow-lg text-center" data-aos="fade-left" data-aos-delay="300">
          <h3 className="text-3xl font-semibold">Beyond Learning—The Power to Build</h3>
          <p className="mt-4 text-gray-400">But I want more. Learning is not the end. Application is. Knowledge should not just be acquired; it should be used to build, to invent, to disrupt.</p>
        </div>
        <div className="p-8 bg-[#1a1a1a] rounded-xl shadow-lg text-center" data-aos="fade-left" data-aos-delay="300">
          <h3 className="text-3xl font-semibold">Totle is not just about learning—it is about creating, about turning ideas into reality.</h3>
          <p className="mt-4 text-gray-400">If someone has an innovation, an idea, or a discovery, they should have access to the tools and collaborators to make it happen. If an engineer has a groundbreaking idea, they should be able to find the right minds to test it, improve it, launch it. If an artist wants to redefine expression, they should be able to connect with the right audience, the right ecosystem.</p>
        </div>
      </section>

      {/* Call to Action */}
      <section className="text-center py-24 bg-[#1a1a1a]">
        <h2 className="text-4xl font-bold" data-aos="fade-up">Join the Revolution</h2>
        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="300">
        This isn’t wishful thinking. This is capitalism doing what it was meant to do—rewarding value, enabling progress, and distributing opportunity.        </p>
      </section>

      <section className="py-24 text-center bg-[#141414]">
        <h2 className="text-4xl font-bold" data-aos="fade-up">The Scale of the Dream</h2>
        <h4 className="text-2xl font-bold" data-aos="fade-up" data-aos-delay="300">I do not dream of millions. I dream of billions.</h4>

        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="300">
        Every person with a device, every person with a connection to the digital world, will have access to Totle. This is not an edtech company. This is the rewriting of education itself. It is a movement. A new order, where knowledge flows freely, where learning is not a transaction but a revolution.        </p>
        <br/>
        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="400">
        This is the biggest shift in education since the invention of writing. And it will happen in our lifetime.        
        </p>
      </section>

      <section className="py-24 text-center bg-[#141414]">
        <h2 className="text-4xl font-bold" data-aos="fade-up">Why This Must Exist</h2>

        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="300">
        Because the world is full of problems that remain unsolved—not for lack of intelligence, but for lack of access. Because people who have value to offer should not be shackled by outdated systems. Because the best minds should not be underutilized or ignored just because they lack a title, a degree, or a formal path. </p>
        
        <br/>
        <h4 className="text-3xl font-bold" data-aos="fade-up" data-aos-delay="300">Totle is not an idealistic dream. It is an inevitability.</h4>

        <p className="text-lg mt-4 max-w-4xl mx-auto text-gray-300 leading-relaxed" data-aos="fade-up" data-aos-delay="400">
        To every person reading this: Believe in the vision. This is not just my revolution; it is yours.        </p>
      </section>

      {/* Social Share */}
      <div className="m-12 flex justify-center space-x-6 text-2xl pb-24 text-gray-500" data-aos="fade-in">
        <a href="https://www.linkedin.com/company/totle-edtech/" className="hover:text-blue-700 transition-all duration-500">
          <FaLinkedin />
        </a>
        <a href="https://x.com/Totle_edtech" className="hover:text-blue-500 transition-all duration-500">
          <FaTwitter />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61572098313677" className="hover:text-blue-700 transition-all duration-500">
          <FaFacebookSquare />
        </a>
      </div>
    </div>
  );
};

export default Post;