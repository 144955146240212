import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaLinkedin, FaTwitter, FaFacebookSquare } from "react-icons/fa";
import why from "../../images/why.jpg"

const AI = () => {
  useEffect(() => {
    AOS.init({ duration: 1500 });
  }, []);

  return (
    <div className="bg-black text-white">
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-center text-center bg-cover bg-fixed"
        style={{
          backgroundImage: `url(${why})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-80"></div>
        <div className="relative z-10 px-6 animate-fade-in">
          <h1 className="text-6xl md:text-8xl font-extrabold tracking-wide" data-aos="fade-up">
            Why Does This Matter in the Age of AI?
          </h1>
          <p
            className="max-w-3xl mx-auto text-xl md:text-2xl mt-6 leading-relaxed"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            Artificial Intelligence is reshaping everything. From how we work to how we learn, the rules are changing at a pace no one predicted.
          </p>
        </div>
      </section>

      {/* AI Impact Section */}
      <section className="container mx-auto px-6 py-24 max-w-6xl text-lg leading-relaxed space-y-16">
        <div className="text-center" data-aos="fade-up">
          <p className="text-xl text-gray-300">
            But here’s the real question: Where does that leave us? In a world where AI can generate information instantly, where does human expertise fit in? If AI can write, teach, and solve problems, what happens to the value of human knowledge?
          </p>
          <p className="text-xl text-gray-300 mt-3">
            This is not a distant problem. It is already unfolding.
          </p>
          <p className="text-2xl text-gray-100 mt-5">
          And this is why Totle matters more than ever.          </p>
        </div>

        <div className="text-center" data-aos="fade-up">
          <h2 className="text-5xl font-bold">AI Doesn’t Replace Experts. It Exposes Them.</h2>
          <p className="mt-6 text-xl text-gray-300">
            The rise of AI isn’t eliminating knowledge—it’s forcing a new kind of accountability.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-12 text-center">
          <div data-aos="zoom-in" className="p-8 bg-gray-800 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">AI doesn’t care about degrees.</p>
            <p className="mt-2">People can no longer rely on credentials alone. AI doesn’t care about degrees; it cares about accuracy.</p>
          </div>
          <div data-aos="zoom-in" data-aos-delay="200" className="p-8 bg-gray-800 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">Fluff won’t work anymore.</p>
            <p className="mt-2">If AI can generate surface-level knowledge in seconds, the only thing that matters is depth and insight.</p>
          </div>
          <div data-aos="zoom-in" data-aos-delay="400" className="p-8 bg-gray-800 rounded-lg shadow-lg">
            <p className="text-2xl font-bold">Proof over promise.</p>
            <p className="mt-2">Expertise is no longer about what you claim to know—it’s about what you can demonstrate, explain, and apply.</p>
          </div>
        </div>
        <div className="text-center" data-aos="fade-up">
          <p className="mt-6 text-xl text-gray-300">
          AI is filtering out the noise, but what remains is real human skill, judgment, and originality.
          </p>
        </div>
      </section>

      {/* The New Role of Human Knowledge */}
      <section className="py-24 text-center bg-gray-900">
        <h2 className="text-5xl font-bold" data-aos="fade-up">The New Role of Human Knowledge</h2>
        <p className="text-xl mt-6 max-w-4xl mx-auto" data-aos="fade-up" data-aos-delay="200">
          This is where Totle comes in. Not as a competitor to AI, but as a platform that does what AI can’t.
        </p>
      </section>

      <section >
        <div className="container mx-auto px-6 py-24 grid md:grid-cols-3 gap-12">
        <div className="text-center p-8 bg-gray-800 rounded-lg shadow-lg" data-aos="zoom-in">
          <p className="text-2xl font-bold">AI can generate knowledge.</p>
          <p className="text-lg mt-2">But humans create meaning.</p>
        </div>
        <div className="text-center p-8 bg-gray-800 rounded-lg shadow-lg" data-aos="zoom-in" data-aos-delay="200">
          <p className="text-2xl font-bold">AI can process data.</p>
          <p className="text-lg mt-2">But humans drive insight.</p>
        </div>
        <div className="text-center p-8 bg-gray-800 rounded-lg shadow-lg" data-aos="zoom-in" data-aos-delay="400">
          <p className="text-2xl font-bold">AI can teach technical skills.</p>
          <p className="text-lg mt-2">But it can’t replicate experience, context, or intuition.</p>
        </div>
        </div>
     
      </section>
      <section className=" flex justify-center space-x-6 text-xl pb-24" data-aos="fade-up">
      <div className="text-center" data-aos="fade-up">
          <p className=" text-xl text-gray-300">
          Totle is not replacing education—it’s making it more human. It’s giving people direct access to real-world experts who don’t just provide answers but guide understanding.         Because in an era where AI can teach the basics, what truly matters is mastery.
          </p>
        </div>

      </section>

      <section className="py-24 text-center bg-gray-900">
        <h2 className="text-5xl font-bold" data-aos="fade-up">Monetizing Expertise in the AI Era</h2>
        <p className="text-xl mt-6 max-w-4xl mx-auto" data-aos="fade-up" data-aos-delay="200">
          There’s another reality we can’t ignore: AI is making free content infinite. That means information is losing its economic value.
        </p>
        <div className="mt-8 space-y-6 text-lg text-gray-300 max-w-5xl mx-auto" data-aos="fade-up" data-aos-delay="400">
          <p>• Courses, guides, tutorials—AI can generate them endlessly.</p>
          <p>• Traditional education platforms—most of what they teach can now be automated.</p>
          <p>• Even skilled professionals are feeling the shift—because when AI can replicate 80% of what they do, they need to double down on the 20% AI can’t.</p>
        </div>
        <div className="mt-8 space-y-6 text-lg text-gray-300 max-w-5xl mx-auto" data-aos="fade-up" data-aos-delay="600">
          <p>Totle ensures that human expertise remains valuable. It’s not about competing with AI—it’s about making real knowledge and lived experience profitable in a world drowning in automation.</p>
          <p>• If you can explain something better than AI, you have value.</p>
          <p>• If you can coach, challenge, and push someone forward, you have value.</p>
          <p>• If you have experience that goes beyond theory, you have value.</p>
          <p>And that value needs to stay in the hands of those who create it.</p>
        </div>
      </section>

      <section className="py-24 text-center">
        <h2 className="text-5xl font-bold" data-aos="fade-up">The Future Is a Collaboration—Not a Competition</h2>
        <p className="text-xl mt-6 max-w-4xl mx-auto" data-aos="fade-up" data-aos-delay="200">
          AI is not the enemy. It is a tool. A powerful one.
        </p>
        <p className="text-xl mt-6 max-w-4xl mx-auto text-gray-300" data-aos="fade-up" data-aos-delay="400">
          But tools don’t build revolutions. People do.
        </p>
        <div className="mt-8 space-y-6 text-lg text-gray-300 max-w-5xl mx-auto" data-aos="fade-up" data-aos-delay="600">
  <p>• AI will make learning faster. Totle makes it deeper.</p>
  <p>• AI will generate content. Totle connects real expertise to real learners.</p>
  <p>• AI will automate information. Totle ensures the economy of knowledge benefits those who create it.</p>
  <p>This is not about resisting AI. It’s about understanding where human intelligence thrives.</p>
</div>
      </section>

      <section className="py-24 text-center bg-gray-900">
  <h2 className="text-5xl font-bold" data-aos="fade-up">Why This Matters Now</h2>
  <p className="text-xl mt-6 max-w-4xl mx-auto" data-aos="fade-up" data-aos-delay="200">
    The world is changing faster than ever. The way people learn, teach, and earn from knowledge is shifting right now.
  </p>
  <p className="text-xl mt-6 max-w-4xl mx-auto text-gray-300" data-aos="fade-up" data-aos-delay="400">
    You can either wait for those changes to happen to you—or you can step into them.
  </p>
  <p className="text-xl mt-6 max-w-4xl mx-auto text-gray-300" data-aos="fade-up" data-aos-delay="600">
    Totle isn’t about keeping up with AI. It’s about building the future alongside it.
  </p>
  <p className="text-xl mt-6 max-w-4xl mx-auto text-gray-300" data-aos="fade-up" data-aos-delay="800">
    Because in the age of automation, real independence comes from knowing what AI can’t replace.
  </p>
  <p className="text-xl mt-6 max-w-4xl mx-auto text-gray-300" data-aos="fade-up" data-aos-delay="1000">
    And that starts now.
  </p>
</section>
  

      {/* Social Share Buttons */}
      <div className="m-12 flex justify-center space-x-6 text-xl pb-24" data-aos="fade-up">
        <a href="https://www.linkedin.com/company/totle-edtech/" className="hover:text-blue-700">
          <FaLinkedin />
        </a>
        <a href="https://x.com/Totle_edtech" className="hover:text-blue-500">
          <FaTwitter />
        </a>
        <a href="https://www.facebook.com/profile.php?id=61572098313677" className="hover:text-blue-700">
          <FaFacebookSquare />
        </a>
      </div>
    </div>
  );
};

export default AI;
