import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface Admin {
  id: string;
  name: string;
  email: string;
  role: "SuperAdmin" | "Admin";
  status: string;
}

interface AdminAuthContextType {
  isAdminLoggedIn: boolean;
  admin: Admin | null;
  loading: boolean;
  loginAdmin: (token: string, adminData: Admin) => void;
  logoutAdmin: () => void;
}

const AdminAuthContext = createContext<AdminAuthContextType | undefined>(undefined);

export const AdminAuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState<boolean>(false);
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;;

  useEffect(() => {
    const checkAdminAuthStatus = async () => {
      const token = localStorage.getItem("adminToken");

      if (!token) {
        // console.log("❌ No admin token found.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${backendUrl}/admin/auth/me`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        if (data.admin) {
          setAdmin(data.admin);
          setIsAdminLoggedIn(true);
        } else {
          adminLogout();
        }
      } catch (error) {
        console.error("❌ Error fetching admin data:", error);
        adminLogout();
      }

      setLoading(false);
    };

    checkAdminAuthStatus();
  }, []);

  const loginAdmin = (token: string, adminData: Admin) => {
    localStorage.setItem("adminToken", token);
    setAdmin(adminData);
    setIsAdminLoggedIn(true);
    navigate("/admin/dashboard", { replace: true });
  };

  const adminLogout = () => {
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminDetails");
    setIsAdminLoggedIn(false);
    setAdmin(null);
    navigate("/admin/login", { replace: true });
  };

  return (
    <AdminAuthContext.Provider value={{ isAdminLoggedIn, admin, loading, loginAdmin, logoutAdmin: adminLogout }}>
      {children}
    </AdminAuthContext.Provider>
  );
};

export const useAdminAuth = (): AdminAuthContextType => {
  const context = useContext(AdminAuthContext);
  if (!context) {
    throw new Error("useAdminAuth must be used within an AdminAuthProvider");
  }
  return context;
};
