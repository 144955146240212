import React, { useState, useEffect } from "react";
import { Card, CardHeader, CardContent } from "../../components/UI/Card";
import { Button } from "../../components/UI/Button";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/UI/Tabs";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { ChevronRight, PlusCircle, Trash2, Edit2 } from "lucide-react";
import { motion } from "framer-motion";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

// Define category types
type CategoryType = "Academics" | "Exam Prep" | "Skills" | "Arts";

// Define catalogue structure
interface CatalogueItem {
  id: string;
  name: string;
  level: "domain" | "subject" | "topic";
  parentId?: string | null;
  children?: CatalogueItem[];
  isExpanded?: boolean;
}

// Main Component
const AdminCatalogueBuilder: React.FC = () => {
  const [selectedCategory, setSelectedCategory] = useState<CategoryType | null>(null);
  const [catalogueData, setCatalogueData] = useState<CatalogueItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showAddModal, setShowAddModal] = useState<boolean>(false);
  const [newEntryName, setNewEntryName] = useState<string>("");
  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const [selectedParentId, setSelectedParentId] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const entriesPerPage = 10;
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [selectedEntries, setSelectedEntries] = useState<string[]>([]);
  const [editEntryName, setEditEntryName] = useState<string>("");
  const [editEntryId, setEditEntryId] = useState<string | null>(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState<{ isOpen: boolean; entryId: string | null }>({
    isOpen: false,
    entryId: null,
  });
  const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});


  const handleDeleteEntry = (id: string) => {
    setDeleteConfirmation({
      isOpen: true,
      entryId: id,
    });
  };

  const deleteEntry = async (id: string) => {
    setLoading(true);
    try {
      await axios.put(`/api/admin/catalogue/${id}/soft-delete`, {}, {
        headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
      });
  
      toast.success(
        <div>
          Entry moved to trash.
          <Button onClick={() => restoreEntry(id)} variant="secondary">Undo</Button>
        </div>
      );
          } catch (error) {
      toast.error("Failed to delete entry.");
    } finally {
      setLoading(false);
    }
  };
  
  const restoreEntry = async (id: string) => {
    if (!selectedCategory) return; // ✅ Prevents calling with null
    await axios.put(`/api/admin/catalogue/${id}/restore`, {}, {
      headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
    });
  
    fetchCatalogueData(selectedCategory);
    toast.success("Entry restored successfully!");
  };
  
  useEffect(() => {
    if (selectedCategory !== null) {
      fetchCatalogueData(selectedCategory);
    }
  }, [selectedCategory]);

  const buildHierarchy = (items: CatalogueItem[]) => {
    const map = new Map<string, CatalogueItem>();
  
    items.forEach((item) => {
      map.set(item.id, { ...item, children: [] });
    });
  
    const roots: CatalogueItem[] = [];
  
    items.forEach((item) => {
      if (item.parentId && map.has(item.parentId)) {
        map.get(item.parentId)!.children!.push(map.get(item.id)!);
      } else {
        roots.push(map.get(item.id)!);
      }
    });
  
    return roots;
  };
   
  const fetchCatalogueData = async (category: CategoryType) => {
    setLoading(true);
    const controller = new AbortController();
    try {
      const response = await axios.get(`/api/admin/catalogue?category=${category}`, {
        headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` },
        signal: controller.signal,
      });
  
      setCatalogueData(buildHierarchy(response.data.data));
    } catch (error) {
      if (axios.isCancel(error)) {
        console.log("Request cancelled:", error.message);
      } else {
        console.error("Error fetching catalogue data:", error);
        toast.error("Failed to fetch catalogue data.");
      }
    } finally {
      setLoading(false);
    }
    return () => controller.abort(); // Cancel previous fetch if new one starts
  };

  const startEdit = (entry: CatalogueItem) => {
    setEditEntryName(entry.name);
    setEditEntryId(entry.id);
    setShowEditModal(true);
  };

  const handleCategorySelect = (category: CategoryType) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
    }
  };
  

  const handleAddEntry = async () => {
    if (!newEntryName || !selectedLevel) {
      toast.error("Please enter a name and select a level.");
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post(
        `/api/admin/catalogue`,
        {
          name: newEntryName,
          category: selectedCategory,
          level: selectedLevel,
          parentId: selectedParentId || null // If inside a specific category
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`
          }
        }
      );

      if (catalogueData.some(entry => entry.name.toLowerCase() === newEntryName.toLowerCase())) {
        toast.error("An entry with this name already exists.");
        return;
      }

      toast.success("Entry added successfully!");
      setShowAddModal(false);
      setNewEntryName("");  // ✅ Reset input fields
      setSelectedLevel(""); // ✅ Reset dropdown
      setSelectedParentId(null); // ✅ Reset parent selection
      if (selectedCategory) {
        fetchCatalogueData(selectedCategory);
      }
    } catch (error) {
      console.error("Error adding entry:", error);
      toast.error("Failed to add entry.");
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateEntry = async () => {
    if (!editEntryName) {
      toast.error("Please enter a name.");
      return;
    }

    setLoading(true);
    try {
      await axios.put(
        `/api/admin/catalogue/${editEntryId}`,
        { name: editEntryName },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("adminToken")}`
          }
        }
      );

      toast.success("Entry updated successfully!");
      setShowEditModal(false);
      if (selectedCategory) {
        fetchCatalogueData(selectedCategory);
      }
    } catch (error) {
      console.error("Error updating entry:", error);
      toast.error("Failed to update entry.");
    } finally {
      setLoading(false);
    }
  };

  const handleDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
  
    const updatedCatalogue = [...catalogueData];
    const [movedItem] = updatedCatalogue.splice(result.source.index, 1);
    const newParentId = result.destination.droppableId !== "catalogue" ? result.destination.droppableId : null;
    movedItem.parentId = newParentId;
  
    if (newParentId) {
      const parentItem = updatedCatalogue.find((item) => item.id === newParentId);
      if (parentItem) {
        parentItem.children = [...(parentItem.children || []), movedItem];
      }
    } else {
      updatedCatalogue.splice(result.destination.index, 0, movedItem);
    }
  
    // ✅ Check if order actually changed before saving
    if (JSON.stringify(updatedCatalogue) !== JSON.stringify(catalogueData)) {
      setCatalogueData(updatedCatalogue);
      await axios.post("/api/admin/catalogue/reorder", { reorderedItems: updatedCatalogue }, {
        headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` }
      });
    }
  };
  
  
  
  const toggleSelectEntry = (id: string) => {
    setSelectedEntries((prev) =>
      prev.includes(id) ? prev.filter((entryId) => entryId !== id) : [...prev, id]
    );
  };

  const toggleExpand = (id: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: !prev[id], // Toggle expansion state
    }));
  };
 
  const handleBulkDelete = async () => {
    if (selectedEntries.length === 0) return;
  
    if (!window.confirm(`Are you sure you want to delete ${selectedEntries.length} entries?`)) return;
  
    setLoading(true);
    try {
      await Promise.all(
        selectedEntries.map((id) =>
          axios.put(`/api/admin/catalogue/${id}/soft-delete`, {}, {
            headers: { Authorization: `Bearer ${localStorage.getItem("adminToken")}` }
          })
        )
      );
  
      toast.success("Selected entries deleted successfully!");
      setSelectedEntries([]);
  
      if (selectedCategory) {
        fetchCatalogueData(selectedCategory);
      }
    } catch (error) {
      console.error("Error deleting entries:", error);
      toast.error("Failed to delete selected entries.");
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="bg-gray-900 min-h-screen p-6 text-white">
      <Card className="bg-gray-800 border border-gray-700 shadow-xl">
        <CardHeader>
          <h1 className="text-2xl font-semibold text-white">Catalogue Builder</h1>
          <p className="text-gray-400">Manage categories, subjects, topics efficiently.</p>
        </CardHeader>

        <CardContent>
          {/* ✅ Add Entry Button */}
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-xl font-semibold">
              {selectedCategory ? `${selectedCategory} Catalogue` : "Please select a category"}
            </h2>
            <Button variant="primary" onClick={() => setShowAddModal(true)} disabled={!selectedCategory}>
              <PlusCircle size={18} className="mr-2" /> Add Entry
            </Button>
          </div>

          <div className="mb-4">
            <input
              type="text"
              placeholder="Search entries..."
              className="w-full p-2 rounded bg-gray-700 text-white"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>



          {/* ✅ Category Selection Tabs */}
          <Tabs defaultValue={selectedCategory?.toLowerCase() || "academics"} className="w-full">
            <TabsList className="bg-gray-800 border-gray-700 flex justify-center">
              {["Academics", "Exam Prep", "Skills", "Arts"].map((category) => (
                <TabsTrigger
                  key={`category-${category}`}
                  value={category.toLowerCase()}
                  className="text-white hover:bg-gray-700 px-4 py-2 rounded-lg transition-all"
                  onClick={() => handleCategorySelect(category as CategoryType)}
                >
                  {category}
                </TabsTrigger>
              ))}
            </TabsList>

            {/* ✅ Display Catalogue Items */}
            <TabsContent value={selectedCategory?.toLowerCase() || "academics"}>
              {loading ? (
                <p className="text-gray-400 text-center mt-4">Loading...</p>
              ) : (
<DragDropContext onDragEnd={handleDragEnd}>
<Droppable droppableId="catalogue" isDropDisabled={false}>
    {(provided, snapshot) => (
      <motion.div className={`mt-4 space-y-4 ${snapshot.isDraggingOver ? "bg-gray-800" : ""}`} {...provided.droppableProps} ref={provided.innerRef}>
        {catalogueData
          .filter((item) => item.parentId === null) // ✅ Show only top-level items first
          .map((item, index) => (
            <Draggable key={item.id} draggableId={item.id} index={index}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  className="bg-gray-700 p-4 rounded-lg shadow-md"
                >
                  {/* ✅ Expand/Collapse Button */}
                  <div className="flex justify-between items-center">
                    <div className="flex items-center">
                    {Array.isArray(item.children) && item.children.length > 0 && (
                      <button
  onClick={() => toggleExpand(item.id)}
  className="mr-2 text-yellow-400"
>
  {expandedItems[item.id] ? "▼" : "▶"} ({item.children?.length || 0}) 
</button>

                      )}
                      <span className="text-white">{item.name}</span>
                    </div>

                    <div className="flex gap-2">
                      <Button variant="ghost" className="text-gray-300 hover:text-blue-400" size="icon" onClick={() => startEdit(item)}>
                        <Edit2 size={16} />
                      </Button>
                      <Button variant="ghost" className="text-gray-300 hover:text-red-400" size="icon" onClick={() => handleDeleteEntry(item.id)}>
                        <Trash2 size={16} />
                      </Button>
                    </div>
                  </div>

                  {/* ✅ Render Child Items if Expanded */}
                  {expandedItems[item.id] && Array.isArray(item.children) && item.children.length > 0 && (
                    <Droppable droppableId={item.id}>
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="pl-6 mt-2 border-l border-gray-500">
{Array.isArray(item.children) && item.children.map((child, childIndex) => (
                            <Draggable key={child.id} draggableId={child.id} index={childIndex}>
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  className="bg-gray-800 p-3 rounded-lg shadow-md flex justify-between items-center"
                                >
                                  <span className="text-white">{child.name}</span>
                                  <div className="flex gap-2">
                                    <Button variant="ghost" className="text-gray-300 hover:text-blue-400" size="icon" onClick={() => startEdit(child)}>
                                      <Edit2 size={16} />
                                    </Button>
                                    <Button variant="ghost" className="text-gray-300 hover:text-red-400" size="icon" onClick={() => handleDeleteEntry(child.id)}>
                                      <Trash2 size={16} />
                                    </Button>
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  )}
                </div>
              )}
            </Draggable>
          ))}
        {provided.placeholder}
      </motion.div>
    )}
  </Droppable>
</DragDropContext>


              )}
            </TabsContent>
          </Tabs>
        </CardContent>
      </Card>
      <div className="flex justify-between mt-4">
        <Button
          variant="secondary"
          onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span className="text-gray-300">Page {currentPage}</span>
        <Button
          variant="secondary"
          onClick={() =>
            setCurrentPage((prev) =>
              prev < Math.ceil(catalogueData.length / entriesPerPage) ? prev + 1 : prev
            )
          }
          disabled={currentPage >= Math.ceil(catalogueData.length / entriesPerPage)}
        >
          Next
        </Button>
      </div>

      {/* ✅ Delete Confirmation Modal - Place it here */}
      {deleteConfirmation.isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-xl font-bold text-white mb-4">Confirm Deletion</h2>
            <p className="text-gray-400 mb-4">
              Are you sure you want to delete <strong>{catalogueData.find(entry => entry.id === deleteConfirmation.entryId)?.name}</strong>?
            </p>
            <Button
              variant="danger"
              onClick={() => {
                if (deleteConfirmation.entryId) {
                  deleteEntry(deleteConfirmation.entryId);
                }
                setDeleteConfirmation({ isOpen: false, entryId: null });
              }}
            >
              Delete
            </Button>
            <Button variant="secondary" className="ml-2" onClick={() => setDeleteConfirmation({ isOpen: false, entryId: null })}>
              Cancel
            </Button>
          </div>
        </div>
      )}

{selectedEntries.length > 0 && (
  <div className="mt-4 flex justify-end">
    <Button variant="danger" onClick={handleBulkDelete}>
      Delete Selected ({selectedEntries.length})
    </Button>
  </div>
)}

    </div>
  );

};

export default AdminCatalogueBuilder;
