import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // ✅ Ensure this is correctly imported
import Beta from "./pages/Beta/Beta"; // ✅ Import Beta Page
import WelcomeScreen from "./components/UI/WelcomeScreen"; // ✅ Import the Welcome Screen
import { Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/AuthContext";
import { AdminAuthProvider } from "./context/AdminAuthContext"; // ✅ Import Admin Auth Provider
import AdminLogin from "./pages/admin/AdminLogin"; // ✅ Import Admin Login
import ProtectedRoute from "./routes/ProtectedRoute";
import PublicRoute from "./routes/PublicRoute";
import AuthForm from "./components/Auth/AuthForm";
import NotFoundPage from "./pages/NotFoundPage";
import PlatformPage from "./pages/PlatformPage/PlatformPage";
import MarketplacePage from "./pages/marketplace/MarketplacePage";
import TeachPage from "./pages/TeachingPlatform/TeachPage";
import LearnPage from "./pages/LearnPlatform/LearnPage";
import EndeavourPage from "./pages/EndeavourPlatform/EndeavourPage";
import Profile from "./components/Profile/Profile";
import Navbar from "./pages/Navbar/Navbar";
import Home from "./pages/Home/Home";
import AboutUs from "./pages/AboutUs/AboutUs";
import VisionMission from "./pages/Vision/Vision";
import Testimonials from "./pages/TestimonialsSection/TestimonialsSection";
import Product from "./pages/Product/Product";
import Team from "./pages/Team/Team";
import Blog from "./pages/Blog/Blog";
import Post from "./pages/Blog/post";
import Independence from "./pages/Blog/Independence"; // ✅ Import Independence Article
import AI from "./pages/Blog/AI"; // ✅ Import AI Article
import Investors from "./pages/Investors/Investors";
import Contact from "./pages/Contact/Contact";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import AdminProtectedRoute from "./routes/AdminProtectedRoute"; // ✅ Import AdminProtectedRoute
import AdminDashboard from "./pages/admin/AdminDashboard"; // ✅ Import Admin Dashboard
import AdminUsers from "./pages/admin/AdminUsers"; // ✅ Import Admin Users
import { queryClient } from "./utils/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import AdminBlog from "./pages/admin/AdminBlogCreator";
import AdminSurveyBuilder from "./pages/admin/AdminSurveyBuilder";
import AdminSurveyDashboard from "./pages/admin/AdminSurveyDashboard";
import SurveyDetail from "./pages/Survey/SurveyDetail"; // ✅ Import Survey Detail Page
import AdminUserInterest from "./pages/admin/AdminUserInterests";
import AdminCatalogueBuilder from "./pages/admin/AdminCatalogueBuilder";


const LoadingScreen = () => (
  <div className="flex items-center justify-center min-h-screen bg-gray-100">
    <div className="animate-spin rounded-full h-16 w-16 border-b-4 border-blue-500"></div>
  </div>
);

const AppRoutes = () => {
  const { loading } = useAuth();
  const location = useLocation();
  const noNavbarRoutes = ["/auth", "/forgot-password", "/login", "/signup"].includes(location.pathname)|| location.pathname.startsWith("/admin");

  const navigate = useNavigate();
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);

  // useEffect(() => {
  //   const checkWelcomeStatus = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/user`);
  //       if (!response.ok) throw new Error("Failed to fetch user data");

        // const data: { hasSeenWelcomeScreen: boolean } = await response.json();

        // if (!data.hasSeenWelcomeScreen && !localStorage.getItem("hasSeenWelcomeScreen")) {
          // setShowWelcomeScreen(true);
        // }
      // } catch (error) {
        // console.error("Error checking welcome screen status:", error);
      // }
    // };

  //   checkWelcomeStatus();
  // }, []);

  if (loading) return <LoadingScreen />; // ✅ Show loading screen while checking authentication

  if (showWelcomeScreen) return <WelcomeScreen />; // ✅ Show Welcome Screen if not seen before

  return (
    <>
      {!noNavbarRoutes && <Navbar />}

      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/vision" element={<VisionMission />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/product" element={<Product />} />
        <Route path="/team" element={<Team />} />
        <Route path="/investors" element={<Investors />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/i-have-a-dream" element={<Post />} />
<Route path="/independence-is-in-the-air" element={<Independence />} />  {/* ✅ New Route */}
<Route path="/why-this-matters-in-the-age-of-ai" element={<AI />} />  {/* ✅ New Route */}
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<NotFoundPage />} />

        {/* ✅ Public Route (Only for Non-Authenticated Users) */}
        <Route element={<PublicRoute />}>
          <Route path="/auth" element={<AuthForm />} />
        </Route>

        {/* ✅ Protected Routes (Only for Authenticated Users) */}
        {/* <Route element={<ProtectedRoute />}> */}
        <Route path="/profile/:id" element={<Profile />} />
        <Route path="/platform" element={<PlatformPage />} />
          <Route path="/marketplace/*" element={<MarketplacePage />} />
          <Route path="/teach/*" element={<TeachPage />} />
          <Route path="/learn/*" element={<LearnPage />} />
          <Route path="/endeavour/*" element={<EndeavourPage />} />
          <Route path="/beta" element={<Beta />} />
<Route path="/survey/:id" element={<SurveyDetail />} />  {/* ✅ NEW ROUTE */}

        {/* </Route> */}

        <Route path="/admin/login" element={<AdminLogin />} />


        <Route element={<AdminProtectedRoute />}>
          <Route path="/admin/dashboard" element={<AdminDashboard />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/admin/blog" element={<AdminBlog />} />
          <Route path="/admin/survey" element={<AdminSurveyBuilder />} />
          <Route path="/admin/survey/dashboard" element={<AdminSurveyDashboard />} />
          <Route path="/admin/userInterests" element={<AdminUserInterest />} />
          <Route path="/admin/catalogue-builder" element={<AdminCatalogueBuilder />} />

        </Route>

      </Routes>
    </>
  );
};

function App() {
  return (
    <QueryClientProvider client={queryClient}>
    <AuthProvider>
      <AdminAuthProvider>
        <AppRoutes />
      </AdminAuthProvider>
    </AuthProvider>
  </QueryClientProvider>
  );
}

export default App;
