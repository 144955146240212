import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { fetchBetaSurveys } from "../../services/surveyService";
import { useNavigate } from "react-router-dom";

interface Survey {
  id: string;
  title: string;
  description: string;
  statusSubmitted: boolean;
}

const Beta: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [surveys, setSurveys] = useState<Survey[]>([]);
  const [loading, setLoading] = useState(true);
  // const titleRef = useRef<HTMLInputElement | null>(null); // Ref for title input focus

  useEffect(() => {
    const loadSurveys = async () => {
      try {
        // Fetch surveys (future API integration)
        const surveyData = await fetchBetaSurveys();
        console.log('beta',surveyData)
        setSurveys(surveyData);
      } catch (error) {
        console.error("❌ Error fetching Beta surveys:", error);
      } finally {
        setLoading(false);
      }
    };

    loadSurveys();
  }, []);
  // useEffect(() => {
  //   if (location.state && "surveyId" in location.state && "submitted" in location.state) {
  //     setSurveys((prevSurveys) =>
  //       prevSurveys.map((survey) =>
  //         survey.id === location.state.surveyId
  //           ? { ...survey, submitted: location.state.submitted }
  //           : survey
  //       )
  //     );
  //   }
  // }, [location.state]);

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#21717b] to-black px-6 py-20">
      <div className="bg-white/10 backdrop-blur-lg shadow-xl rounded-2xl p-8 w-full max-w-5xl border border-white/20">
        <h1 className="text-4xl font-bold text-white text-center mb-6">
          Welcome to the Beta User Hub!
        </h1>
        <p className="text-gray-300 text-lg text-center mb-6">
          Exclusive access to Surveys and Experimental Features.
        </p>

        {/* Surveys Section */}
{/* Surveys Section */}
<div className="mt-6">
  <h2 className="text-2xl font-semibold text-white mb-4">Beta Surveys</h2>

  {loading ? (
    <p className="text-gray-400 text-lg text-center">Loading surveys...</p>
  ) : surveys.length > 0 ? (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {surveys.map((survey) => (
        <div key={survey.id} 
          className="bg-white/10 p-6 rounded-xl border border-white/20 hover:bg-white/20 transition-all cursor-pointer shadow-lg flex flex-col items-start"
          // onClick={() => navigate(`/survey/${survey.id}`)}
        >
          <h3 className="text-xl text-white font-semibold">{survey.title}</h3>
          <p className="text-gray-400 text-sm mt-2 line-clamp-3">
            {survey.description}
          </p>
          
          {survey.statusSubmitted ? (
            <button 
              className="mt-4 bg-green-600 text-white text-sm font-medium py-2 px-4 rounded-lg cursor-not-allowed"
              disabled
            >
              ✔ Submitted
            </button>
          ) : (
            <button 
              className="mt-4 bg-blue-600 hover:bg-blue-700 text-white text-sm font-medium py-2 px-4 rounded-lg"
              onClick={() => navigate(`/survey/${survey.id}`)}
            >
              Take Survey →
            </button>
          )}

        </div>
      ))}
    </div>
  ) : (
    <p className="text-gray-400 text-lg text-center">No surveys available yet.</p>
  )}
</div>

      </div>
    </div>
  );
};

export default Beta;
