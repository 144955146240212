import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaLinkedin, FaTwitter, FaFacebookSquare } from "react-icons/fa";
import post from "../../images/post.jpg";
import independence from "../../images/independence.jpg";

const Independence = () => {
  useEffect(() => {
    AOS.init({ duration: 1500, once: true });
  }, []);

  return (
    <div className="bg-gray-950 text-white overflow-x-hidden">
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-center text-center bg-cover bg-fixed overflow-hidden"
        style={{
          backgroundImage: `url(${independence})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-70 animate-fadeIn"></div>
        <div className="relative z-10 px-6">
          <h1 className="text-6xl md:text-8xl font-extrabold tracking-wide animate-textReveal" data-aos="zoom-in">
            Independence is in the Air
          </h1>
        </div>
      </section>

      {/* Narrative Section */}
      <section className="container mx-auto px-6 py-20 max-w-4xl text-lg leading-relaxed">
        <p className="text-3xl font-semibold text-gray-300 animate-slideUp" data-aos="fade-up">
          Independence doesn’t come from speeches or celebrations. It comes from <span className="text-yellow-400">ownership.</span>
        </p>
        <p className="mt-6 animate-fadeInSlow" data-aos="fade-up" data-aos-delay="200">
          Ownership over what you know, what you do with it, and how you benefit from it.
        </p>
        <p className="text-xl text-gray-400 italic border-l-4 border-yellow-400 pl-4 mt-8 animate-slideInLeft" data-aos="fade-right">
          "For years, education has been someone else’s decision. What you should learn, who should teach you, how much that knowledge is worth—these choices were never yours to make. The institutions, the publishers, the platforms—they built the rules, and everyone played along." 
        </p>
        <p className="text-3xl font-semibold text-gray-300 animate-slideUp mt-10" data-aos="fade-up">
          <span className="text-yellow-400">That’s changing</span>
        </p>
        <p className="mt-6 animate-fadeInSlow" data-aos="fade-up" data-aos-delay="200">
        Education is no longer about classrooms and certifications. It’s about capability. The internet opened the floodgates, but until now, learning was still tied to old models—structured courses, degrees, systems designed to sustain themselves rather than the people inside them. 
        </p>
        <p className="text-xl text-gray-400 italic border-l-4 border-yellow-400 pl-4 mt-8 animate-slideInLeft" data-aos="fade-right">
        Totle is different. It doesn’t ask for permission. It doesn’t create barriers. It simply lets expertise flow.        </p>
      </section>

      {/* Key Shifts Section */}
      <section className="relative mt-12 py-16 bg-gradient-to-r from-gray-800 to-gray-900 text-white text-center" data-aos="fade-up">
        <h2 className="text-4xl font-extrabold animate-zoomIn">The Shift That’s Already Happening</h2>
        <h2 className="text-2xl font-extrabold animate-zoomIn">This isn’t a distant future. It’s happening right now.</h2>

        <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 text-lg text-gray-300">
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left">
            <p>People are already bypassing traditional education to learn from those who actually do the work</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left" data-aos-delay="200">
            <p>More companies care about skills over degrees because they can’t afford to hire based on paper</p>
          </div>
          <div className="p-3 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left" data-aos-delay="400">
            <p>Self-taught professionals, independent researchers, and niche experts are proving that credibility is built in action, not in institutions</p>
          </div>
        </div>
      </section>

      <section className="relative text-center py-24 bg-gradient-to-b from-gray-900 to-black text-white">
        <h2 className="text-6xl font-extrabold animate-textReveal" data-aos="fade-up">Totle isn’t some utopian promise</h2>
        <h3 className="text-3xl text-yellow-400 mt-6 animate-fadeIn" data-aos="fade-up" data-aos-delay="200">It’s a structured response to a shift that has already begun</h3>

        <p className="text-lg mt-6 max-w-3xl mx-auto animate-slideInLeft" data-aos="fade-up" data-aos-delay="400">
        If someone can explain a concept better than a professor, they should be the one teaching it.
If someone has mastered a skill, they should be able to share it—and get paid for it—without an approval process.
If learning is valuable, the people enabling it should be the ones benefiting from it.
        </p>
        <h3 className="text-3xl text-yellow-400 mt-6 animate-fadeIn" data-aos="fade-up" data-aos-delay="200">This isn’t radical. It’s practical.</h3>

      </section>

      {/* Real Value Section */}
      <section className="text-center py-24 bg-black text-white relative overflow-hidden">
        <h2 className="text-5xl font-extrabold animate-pulse" data-aos="zoom-in">Real Value, Real Ownership</h2>
        <p className="text-xl mt-6 max-w-2xl mx-auto animate-slideUp" data-aos="fade-up" data-aos-delay="200">
        For too long, education platforms have treated knowledge like content—something to be packaged, sold, and scaled. They take most of the earnings, while the actual experts—the ones creating value—get a fraction of it. <span className="text-yellow-400 font-bold">Totle flips this. 80% of the earnings go directly to those who teach. The system doesn’t extract value. It circulates it.</span> And this isn’t limited to teaching. If someone develops a new idea, a new method, a new approach, they should own the credit, the rewards, and the impact. Knowledge isn’t just something to be passed down. It’s something to be built upon.
        </p>
      </section>

      {/* Market Correction Section */}
      <section className="relative text-center py-24 bg-gradient-to-b from-gray-900 to-black text-white">
        <h2 className="text-6xl font-extrabold animate-textReveal" data-aos="fade-up">This Isn’t a Movement.</h2>
        <h3 className="text-3xl text-yellow-400 mt-6 animate-fadeIn" data-aos="fade-up" data-aos-delay="200">It’s a Market Correction.</h3>
        <p className="text-lg mt-6 max-w-3xl mx-auto animate-slideInLeft" data-aos="fade-up" data-aos-delay="400">
        People aren’t waiting for permission anymore. They are learning outside the system, they are teaching outside the system, and they are succeeding because the system was never designed to reward real expertise in the first place.
This isn’t about rejecting traditional education. It’s about recognizing its limits and filling the gaps with something better.
        </p>
        <div className="mt-6 max-w-3xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 text-lg text-gray-300">
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left">
            <p>A researcher should be able to fund their work by sharing insights, not just chasing grants</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left" data-aos-delay="200">
            <p>A skilled professional should be able to earn by teaching others, not just by working for someone else</p>
          </div>
          <div className="p-6 bg-gray-800 rounded-lg shadow-lg transform hover:scale-105 transition duration-500" data-aos="flip-left" data-aos-delay="400">
            <p>A student should be able to learn from the best, not just from the most accessible</p>
          </div>
        </div>
        <h3 className="text-3xl text-yellow-400 mt-6 animate-fadeIn" data-aos="fade-up" data-aos-delay="200">This isn’t idealism. It’s the next logical step.</h3>

      </section>



      {/* Call to Action */}
      <section className="text-center py-20 bg-gray-800 text-white">
        <h2 className="text-5xl font-extrabold animate-bounce" data-aos="fade-up">Independence Is Here. Step Into It.</h2>
        <p className="text-xl mt-6 animate-fadeIn p-4" data-aos="fade-up" data-aos-delay="200">
        This is not a pitch. It’s an observation.
The world of education and expertise is evolving. The only question is: Are you going to be part of it, or will you wait for others to lead?
        </p>
      </section>

      {/* Social Share Buttons */}
      <section className="flex justify-center space-x-6 text-3xl text-gray-500 dark:text-gray-300 m-24 animate-slideUp" data-aos="fade-up">
        <a href="https://www.linkedin.com/company/totle-edtech/" className="hover:text-blue-700 transition duration-300"><FaLinkedin /></a>
        <a href="https://x.com/Totle_edtech" className="hover:text-blue-500 transition duration-300"><FaTwitter /></a>
        <a href="https://www.facebook.com/profile.php?id=61572098313677" className="hover:text-blue-700 transition duration-300"><FaFacebookSquare /></a>
      </section>
    </div>
  );
};

export default Independence;
