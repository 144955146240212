import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAdminAuth } from "../context/AdminAuthContext";

/**
 * ✅ Protects Admin Routes
 * Redirects to `/admin/login` if not authenticated.
 */
const AdminProtectedRoute = () => {
  const { isAdminLoggedIn, loading } = useAdminAuth();
  const location = useLocation();

  if (loading) return <div>Loading...</div>; // Wait for auth check to complete

  if (!isAdminLoggedIn && location.pathname.startsWith("/admin")) {
    return <Navigate to="/admin/login" replace />;
  }
  return <Outlet />;
};

export default AdminProtectedRoute;
