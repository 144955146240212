import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useAdminAuth } from "../../context/AdminAuthContext";
import { NavLink } from "react-router-dom/dist";
import { motion } from "framer-motion";

const backendUrl = `${process.env.REACT_APP_API_URL}/admin`;
const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ color: [] }, { background: [] }],
    ["link", "image"],
    [{ align: [] }],
    ["clean"],
  ],
};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "list",
  "bullet",
  "color",
  "background",
  "link",
  "image",
  "align",
];

const BlogCreator: React.FC = () => {
  const [blog, setBlog] = useState({
    title: "",
    slug: "",
    description: "",
    content: "",
    image: "",
  });
  const [previewMode, setPreviewMode] = useState<"card" | "full">("card");
  const { logoutAdmin } = useAdminAuth();

  useEffect(() => {
    const savedBlog = localStorage.getItem("blogData");
    if (savedBlog) setBlog(JSON.parse(savedBlog));
  }, []);

  useEffect(() => {
    localStorage.setItem("blogData", JSON.stringify(blog));
  }, [blog]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setBlog({ ...blog, [e.target.name]: e.target.value });
  };

  const handleEditorChange = (value: string) => {
    setBlog({ ...blog, content: value });
  };

  return (
    <div className="min-h-screen bg-black text-white p-8 font-sans flex flex-col items-center relative overflow-hidden">
      <motion.h1 
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="text-5xl font-extrabold text-red-500 border-b border-yellow-500 pb-2 tracking-widest glow animate-pulse"
      >
        🚀 IRON BLOG CREATOR
      </motion.h1>
      
      <div className="flex justify-between w-full max-w-5xl mt-6">
        <NavLink
          to="/admin/dashboard"
          className="bg-yellow-400 text-black px-6 py-3 rounded-md font-bold hover:bg-yellow-300 transition transform hover:scale-105 shadow-md"
        >
          🔙 Dashboard
        </NavLink>
        <button
          onClick={logoutAdmin}
          className="bg-red-600 text-white px-6 py-3 rounded-md font-bold hover:bg-red-500 transition transform hover:scale-110 shadow-md shadow-red-500"
        >
          🚪 Logout
        </button>
      </div>

      <motion.div 
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full max-w-4xl bg-gray-900 p-8 rounded-lg shadow-2xl border border-red-600 mt-6"
      >
        <form className="space-y-6">
          <input
            type="text"
            name="title"
            value={blog.title}
            onChange={handleChange}
            placeholder="Blog Title"
            className="w-full bg-black text-white p-4 border border-red-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-red-500 shadow-lg"
          />
          <input
            type="text"
            name="slug"
            value={blog.slug}
            onChange={handleChange}
            placeholder="Blog Slug"
            className="w-full bg-black text-white p-4 border border-red-600 rounded-lg shadow-lg"
          />
          <textarea
            name="description"
            value={blog.description}
            onChange={handleChange}
            placeholder="Blog Description"
            className="w-full bg-black text-white p-4 border border-red-600 rounded-lg shadow-lg"
          ></textarea>
          <ReactQuill
            theme="snow"
            value={blog.content}
            onChange={handleEditorChange}
            modules={modules}
            formats={formats}
            className="bg-black text-white border-red-600 rounded-lg shadow-lg"
          />
          <button 
            type="submit" 
            className="bg-red-600 hover:bg-red-800 text-white px-6 py-3 rounded-lg font-bold uppercase tracking-wider shadow-md transform hover:scale-110 transition"
          >
            🚀 PUBLISH NOW
          </button>
        </form>
      </motion.div>
    </div>
  );
};

export default BlogCreator;
