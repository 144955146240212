import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const PublicRoute: React.FC = () => {
  const { isLoggedIn } = useAuth();

  return isLoggedIn ? <Navigate to="/platform" replace /> : <Outlet />;
};

export default PublicRoute;
