import { useAdminAuth } from "../../context/AdminAuthContext";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const AdminDashboard = () => {
  const { admin, logoutAdmin } = useAdminAuth();

  return (
    <div className="min-h-screen bg-black text-yellow-400 p-6 flex flex-col items-center relative overflow-hidden">
      {/* Glowing Background Grid */}
      <div className="absolute inset-0 opacity-20 bg-grid bg-cover"></div>

      <motion.h1 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.8 }}
        className="text-5xl font-extrabold text-red-500 border-b border-yellow-500 pb-2 tracking-widest glow animate-pulse"
      >
        🦾 ADMIN CONTROL HUB
      </motion.h1>

      <motion.button
        onClick={logoutAdmin}
        className="absolute top-4 right-10 bg-red-900 text-white px-6 py-3 rounded-md font-bold hover:bg-red-500 transition transform hover:scale-125 shadow-md shadow-red-500"
      >
        🚪 SYSTEM LOGOUT
      </motion.button>

      <motion.p 
        initial={{ opacity: 0 }} 
        animate={{ opacity: 1 }} 
        transition={{ delay: 0.3 }}
        className="mt-6 text-2xl font-semibold text-cyan-400 tracking-widest shadow-sm"
      >
        Welcome, {admin?.name} 🦾
      </motion.p>

      {/* Advanced Analytics Section */}
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 w-full max-w-6xl">
        {[
          { title: "📊 Total Users", value: "1,230" },
          { title: "📅 Active Sessions", value: "47" },
          { title: "💰 Revenue", value: "$12,500" },
          { title: "⚡ System Health", value: "✅ Operational" },
        ].map((item, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.2 }}
            className="bg-gradient-to-br from-red-900 to-black p-8 rounded-lg shadow-2xl text-center border border-red-500 hover:shadow-red-500 hover:scale-110 transition transform backdrop-blur-md"
          >
            <h2 className="text-2xl font-semibold text-yellow-400">{item.title}</h2>
            <p className="text-4xl mt-2 font-bold text-white tracking-wide animate-glow">{item.value}</p>
          </motion.div>
        ))}
      </div>

      {/* Holographic Management Links */}
      <div className="mt-10 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 w-full max-w-6xl">
        {[
          { path: "/admin/users", label: "👥 Manage Users" },
          { path: "/admin/blog", label: "🖋 Manage Blog" },
          { path: "/admin/survey", label: "📃 Manage Survey" },
          { path: "/admin/survey/dashboard", label: "📄 Survey Dashboard" },
          { path: "/admin/userInterests", label: "📄 User Interests" },
          { path: "/admin/catalogue-builder", label: "⚙️ Catalogue Builder" },
        ].map((link, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: index * 0.1 }}
          >
            <Link
              to={link.path}
              className="block p-6 bg-yellow-500 text-black font-bold text-center rounded-lg shadow-lg hover:bg-yellow-400 hover:shadow-yellow-500 transition transform hover:scale-110 border border-yellow-600"
            >
              {link.label}
            </Link>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default AdminDashboard;
