import React from "react";
import { Routes, Route } from "react-router-dom";
import EndeavourPlatform from "../../components/EndeavourPlatform/EndeavourPlatform";
import EndeavourDashboard from "../../components/EndeavourPlatform/Dashboard/Dashboard";


const EndeavourPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<EndeavourPlatform />}>
        <Route path="/" element={<EndeavourDashboard />} />

      </Route>
    </Routes>
  );
};

export default EndeavourPage;
