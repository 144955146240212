import React, { useState } from "react";
import { useNavigate } from "react-router/dist";

interface ForgotPasswordFormProps {
  onClose: () => void; // Prop to close modal in LoginForm.tsx
}


const ForgotPasswordForm: React.FC<ForgotPasswordFormProps> = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [step, setStep] = useState(1);
  const [errors, setErrors] = useState<{ email?: string; otp?: string; password?: string; form?: string }>({});
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const backendUrl = process.env.REACT_APP_API_URL; // Backend URL from env file

  // Validate Inputs
  const validateForm = () => {
    const newErrors: { email?: string; otp?: string; password?: string } = {};
    if (step === 1 && !email) newErrors.email = "Email is required.";
    if (step === 2 && !otp) newErrors.otp = "OTP is required.";
    if (step === 3) {
      if (!newPassword) newErrors.password = "New password is required.";
      if (newPassword !== confirmPassword) newErrors.password = "Passwords do not match.";
    }
    return newErrors;
  };
  const navigate = useNavigate();

  // Handle Input Changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "otp") setOtp(value);
    if (name === "newPassword") setNewPassword(value);
    if (name === "confirmPassword") setConfirmPassword(value);
    setErrors({});
    setMessage("");
  };

  // Handle Form Submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({});
    setMessage("");
    
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    
    setLoading(true);
    try {
      let response, data;

      if (step === 1) {
        // Step 1: Send email to request OTP
        response = await fetch(`${backendUrl}/auth/resetUser`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email }),
        });
      } else if (step === 2) {
        // Step 2: Verify OTP
        response = await fetch(`${backendUrl}/auth/verifyResetOtp`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp }),
        });


      } else if (step === 3) {
        // Step 3: Reset password
        response = await fetch(`${backendUrl}/auth/resetPassword`, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ email, otp, newPassword }),
        });
      }

      data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      if (step === 1) {
        // alert(data.message);
        setMessage(data.message);
        setStep(2);
      } else if (step === 2) {
        setMessage("OTP verified. You can now reset your password.");
        setStep(3);
      } else if (step === 3) {
        onClose();
        alert("Your password 🔑 has been successfully reset. 👍");
        // setStep(1);
        setEmail("");
        setOtp("");
        setNewPassword("");
        setConfirmPassword("");
      }
    } catch (error: unknown) {
        if (error instanceof Error) {
          setErrors({ form: error.message || "An error occurred. Please try again." });
        } else {
          setErrors({ form: "An unexpected error occurred." });
        }
      }
    setLoading(false);
  };

  return (
    <>
      {/* ✅ Full-Screen Loader */}
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
          <div className="flex flex-col gap-6 w-full items-center justify-center">
            <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
              <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
            </div>
          </div>
        </div>
      )}
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-gray-800 text-center">Forgot Password</h2>

        {message && <p className="text-green-500 text-sm mt-2 text-center">{message}</p>}
        {errors.form && <p className="text-red-500 text-sm mt-2 text-center">{errors.form}</p>}

        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          {step === 1 && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={email}
                onChange={handleChange}
                placeholder="Enter your registered email"
              />
              {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
            </div>
          )}

          {step === 2 && (
            <div>
              <label className="block text-sm font-medium text-gray-700">Enter OTP</label>
              <input
                type="text"
                name="otp"
                className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                value={otp}
                onChange={handleChange}
                placeholder="Enter OTP sent to your email"
              />
              <div className="text-sm text-center mt-2">

                <span className="mt-2 ">Entered wrong email? </span>
                <button 
                  type="button"
                  onClick={() => {
                    setStep(1);
                  }
                  } // ✅ Goes back to Step 1
                  className="mt-2 text-sm text-blue-600 hover:underline transition duration-200"
                  >Click here
                </button>
              </div>
              {errors.otp && <p className="text-red-500 text-sm">{errors.otp}</p>}
            </div>
          )}

          {step === 3 && (
            <>
              <div>
                <label className="block text-sm font-medium text-gray-700">New Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="newPassword"
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={newPassword}
                  onChange={handleChange}
                  placeholder="Enter new password"
                />
                <span
                  className="absolute top-6 right-1 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {/* {showPassword ? "👁️" : "😵"} */}
                </span>
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                  value={confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm new password"
                />
                <span
                  className="absolute top-6 right-1 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {/* {showConfirmPassword ? "👁️" : "😵"} */}
                </span>
                {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
              </div>
            </>
          )}

          <button
            type="submit"
            className={`w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Processing..." : step === 3 ? "Reset Password" : "Next"}
          </button>
        </form>
      </div>
    </>
  );
};

export default ForgotPasswordForm;
