// src/services/adminUserService.ts

import axios from "axios";

const API_BASE_URL = process.env.REACT_APP_API_URL || "https://your-api-url.com"; // Replace with actual API URL

export const fetchAdminUsers = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/admin/users`);
    return response.data;
  } catch (error) {
    console.error("Error fetching admin users:", error);
    throw error;
  }
};

export const updateAdminUserStatus = async (id: string, status: string) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/admin/users/${id}/status`, { status });
    return response.data;
  } catch (error) {
    console.error("Error updating admin user status:", error);
    throw error;
  }
};

export const deleteAdminUser = async (id: string) => {
  try {
    await axios.delete(`${API_BASE_URL}/admin/users/${id}`);
  } catch (error) {
    console.error("Error deleting admin user:", error);
    throw error;
  }
};

export const fetchUserInterests = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/admin/getSuggestions`);
    return response.data.suggestions;
  } catch (error) {
    console.error("Error fetching user interests:", error);
    throw error;
  }
};