import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import AOS from 'aos';
import 'aos/dist/aos.css';
import axios from "axios";
import Footer from '../Footer/Footer';

let backendUrl = `${ process.env.REACT_APP_API_URL}/auth/user-count`; // Replace with your actual backend URL

const Home = () => {
  const [userCount, setUserCount] = useState(0);
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get(`${backendUrl}`); // Replace with your actual backend URL
        setUserCount(response.data.count);
      } catch (error) {
        console.error("Error fetching user count:", error);
      }
    };

    fetchUserCount();
  }, []);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    // navigate('/contact');
    window.scrollTo(0, 0);
  };

  // Beta spots progress tracking
  const [betaSpots] = useState(1000);
  // const [filledSpots, setFilledSpots] = useState(850);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setUserCount((prev) => (prev < betaSpots ? prev + Math.floor(Math.random() * 3) : betaSpots));
  //   }, 3000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="overflow-x-hidden">
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-end bg-cover bg-center"
        style={{ backgroundImage: `url('/images/platform-preview.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center top' }}>
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-center px-6 sm:px-10 lg:px-16 max-w-3xl" style={{ marginTop: '10%' }}>
          <h1 className="text-3xl lg:text-[2.8rem] font-extrabold text-white" data-aos="fade-up">
            TOTLE Beta is Live 
          </h1>
          <h1 className="text-3xl pt-4 lg:text-[2.4rem] font-extrabold text-white" data-aos="fade-up">
             Limited Spots Available
          </h1>
          <p className="text-lg lg:text-2xl text-gray-200 mt-4" data-aos="fade-up" data-aos-delay="200">
            Experience the Future of Education, One Topic at a Time
          </p>
          <div className="bg-white text-center p-4 rounded-lg mt-6 shadow-lg">
            <h3 className="text-2xl font-bold text-gray-800">Beta Enrollment Progress</h3>
            <p className="text-lg text-gray-600">🚀 {userCount} of {betaSpots} spots filled!</p>
            <div className="w-full bg-gray-200 rounded-full h-4 mt-2">
              <div className="bg-tangering h-4 rounded-full transition-all"
                style={{ width: `${(userCount / betaSpots) * 100}%` }}></div>
            </div>
          </div>
          <div className="mt-6">
            <NavLink to="/auth" state={{ fromSignup: true }} 
              className="inline-block font-bold text-center hover:bg-white hover:text-tangering text-xl py-3 px-14 rounded-lg bg-tangering text-white transition-all duration-300">
              Test. Explore. Influence- join our Beta Version now!
            </NavLink>
          </div>
        </div>
      </section>

            {/* Call to Action */}
            <section className="py-16 bg-white text-center shadow-lg rounded-lg mx-auto max-w-5xl">
  <div className="container mx-auto px-6 sm:px-10 lg:px-16">
    
    {/* Header Section */}
    <h2 className="text-5xl font-extrabold text-[#016f66] mb-4 tracking-wide" data-aos="fade-up">
      🚀 Join the Beta as an Educator
    </h2>

    <p className="text-lg text-gray-700 mb-8 max-w-3xl mx-auto leading-relaxed" data-aos="fade-up" data-aos-delay="200">
      We’re gathering pioneers, dreamers, and risk-takers. Be part of <strong>TOTLE’s founding community </strong>
      and shape the future of <strong>one-on-one learning</strong>.
    </p>

    {/* CTA Button */}
    <div className="mt-8" data-aos="fade-up" data-aos-delay="400">
      <NavLink 
        to="/auth" 
        state={{ fromSignup: true }} 
        className="bg-[#f46b24] hover:bg-[#016f66] text-white font-bold text-xl py-4 px-12 rounded-full shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105"
      >
        Join Beta Now
      </NavLink>
    </div>

    {/* Optional Supporting Text */}
    <p className="text-gray-600 mt-6 text-sm font-medium tracking-wide pt-3" data-aos="fade-up" data-aos-delay="600">
      ⏳ Limited Beta Spots Available – Be Among the First to Join!
    </p>

    {/* Decorative Divider */}
    <div className="mt-5 flex justify-center">
      <div className="w-20 h-1 bg-[#f46b24] rounded-full"></div>
    </div>

  </div>
</section>

      {/* Why TOTLE Beta */}
      <section className="py-20 relative text-white"
  style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('/images/why-choose-background.jpg')`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
  <div className="container mx-auto px-6 sm:px-10 lg:px-16 text-center relative z-10 max-w-6xl">
    
    {/* Heading & Subtext */}
    <h2 className="text-5xl font-extrabold mb-6" data-aos="fade-up">
      Why Join <span className="text-tangering">TOTLE Beta?</span>
    </h2>
    <p className="text-lg text-gray-300 max-w-2xl mx-auto mb-12" data-aos="fade-up" data-aos-delay="200">
      Be part of an exclusive group shaping the future of education. As a Beta tester, you get priority access, exclusive perks, and a chance to influence TOTLE’s growth.
    </p>

    {/* Perks Grid */}
    <div className="px-4 sm:px-8 md:px-12 lg:px-20">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 mt-10">

        {/* Perk 1 */}
        <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="300">
          <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
            🚀
          </div>
          <h3 className="text-2xl font-semibold">Early Access</h3>
          <p className="text-gray-300 mt-2">Get ahead of the world! Be among the first to experience TOTLE before public launch.</p>
        </div>

        {/* Perk 2 */}
        <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="400">
          <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
            🎖️
          </div>
          <h3 className="text-2xl font-semibold">Beta Tester Badge</h3>
          <p className="text-gray-300 mt-2">Earn a unique badge on your profile, proving you're a founding member of TOTLE.</p>
        </div>

        {/* Perk 3 */}
        <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="500">
          <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
            📈
          </div>
          <h3 className="text-2xl font-semibold">Priority Learners</h3>
          <p className="text-gray-300 mt-2">Get more visibility and priority matches with learners on the platform.</p>
        </div>

          {/* Perk 4 */}
          <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="600">
            <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
              💡
            </div>
            <h3 className="text-2xl font-semibold">Influence Development</h3>
            <p className="text-gray-300 mt-2">Give feedback directly to our team & shape how TOTLE evolves.</p>
          </div>

          {/* Perk 5 */}
          {/* <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="700">
            <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
            🎁
            </div>
            <h3 className="text-2xl font-semibold">Referral Perks</h3>
            <p className="text-gray-300 mt-2">Invite friends & educators, earn exclusive rewards for growing the community.</p>
            </div> */}

          {/* Perk 6 */}
            <div className="flex flex-col items-center p-6 rounded-lg bg-white bg-opacity-10 shadow-lg hover:bg-opacity-20 transition-all duration-300" data-aos="fade-up" data-aos-delay="800">
              <div className="w-16 h-16 flex items-center justify-center rounded-full bg-tangering text-white text-3xl font-bold mb-4">
                🔥
              </div>
              <h3 className="text-2xl font-semibold">Exclusive Features</h3>
              <p className="text-gray-300 mt-2">Get access to experimental features before they go live to the public.</p>
            </div>

      </div>
    </div>

    {/* Call to Action */}
    <div className="mt-12">
      <NavLink to="/auth" state={{ fromSignup: true }} 
        className="bg-tangering hover:bg-white hover:text-tangering text-white font-bold text-xl py-4 px-12 rounded-lg hover:shadow-lg transition duration-300">
        Join Beta Now
      </NavLink>
    </div>

  </div>
</section>
      {/* Footer */}
       <Footer handleLinkClick={handleLinkClick}/>
    </div>
  );
};

export default Home;
