// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NotFoundPage_notFound__ijMYN {
    text-align: center;
    padding: 50px;
    font-family: Arial, sans-serif;
  }
  
  .NotFoundPage_title__q392v {
    font-size: 2.5rem;
    color: #ff6f61; /* Warm Red */
  }
  
  .NotFoundPage_description__f6r-Z {
    color: #555;
    margin: 20px 0;
  }
  
  .NotFoundPage_link__kZuZL {
    font-size: 1.2rem;
    color: #007acc;
    text-decoration: none;
  }
  
  .NotFoundPage_link__kZuZL:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/NotFoundPage.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,iBAAiB;IACjB,cAAc,EAAE,aAAa;EAC/B;;EAEA;IACE,WAAW;IACX,cAAc;EAChB;;EAEA;IACE,iBAAiB;IACjB,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".notFound {\n    text-align: center;\n    padding: 50px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .title {\n    font-size: 2.5rem;\n    color: #ff6f61; /* Warm Red */\n  }\n  \n  .description {\n    color: #555;\n    margin: 20px 0;\n  }\n  \n  .link {\n    font-size: 1.2rem;\n    color: #007acc;\n    text-decoration: none;\n  }\n  \n  .link:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"notFound": `NotFoundPage_notFound__ijMYN`,
	"title": `NotFoundPage_title__q392v`,
	"description": `NotFoundPage_description__f6r-Z`,
	"link": `NotFoundPage_link__kZuZL`
};
export default ___CSS_LOADER_EXPORT___;
