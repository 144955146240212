import React from "react";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchAdminUsers, updateAdminUserStatus, deleteAdminUser } from "../../services/adminUserService";
import { queryClient } from "../../utils/queryClient";
import { NavLink } from "react-router-dom/dist";
import { useAdminAuth } from "../../context/AdminAuthContext";
import { motion } from "framer-motion";

const AdminUsers: React.FC = () => {
  const { data: users, isLoading } = useQuery({
    queryKey: ["adminUsers"],
    queryFn: fetchAdminUsers,
  });

  const updateStatusMutation = useMutation({
    mutationFn: ({ id, status }: { id: string; status: string }) => updateAdminUserStatus(id, status),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["adminUsers"] }),
  });

  const deleteMutation = useMutation({
    mutationFn: (id: string) => deleteAdminUser(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["adminUsers"] }),
  });

  const { logoutAdmin } = useAdminAuth();

  return (
    <div className="min-h-screen bg-black text-white p-8 flex flex-col items-center relative overflow-hidden">
      <motion.h2 
        initial={{ opacity: 0, y: -20 }} 
        animate={{ opacity: 1, y: 0 }} 
        transition={{ duration: 0.8 }}
        className="text-4xl font-bold text-red-500 mb-6 border-b border-yellow-500 pb-2 tracking-wide glow"
      >
        🛠 Admin User Management
      </motion.h2>
      
      <div className="flex justify-between w-full max-w-6xl mb-6">
        <NavLink
          to="/admin/dashboard"
          className="bg-yellow-400 text-black px-6 py-3 rounded-md font-semibold hover:bg-yellow-300 transition transform hover:scale-105 shadow-md"
        >
          🔙 Dashboard
        </NavLink>
        <button
          onClick={logoutAdmin}
          className="bg-red-600 text-white px-6 py-3 rounded-md font-semibold hover:bg-red-500 transition transform hover:scale-110 shadow-md shadow-red-500"
        >
          🚪 Logout
        </button>
      </div>
      
      {isLoading ? (
        <p className="text-yellow-400 text-xl animate-pulse">Loading users...</p>
      ) : (
        <div className="mt-6 overflow-x-auto w-full max-w-6xl">
          <table className="w-full border border-red-600 shadow-xl rounded-lg overflow-hidden bg-black text-white">
            <thead className="bg-red-800 text-white text-lg">
              <tr className="text-left">
                <th className="p-4">Name</th>
                <th className="p-4">Email</th>
                <th className="p-4">Verified</th>
                <th className="p-4">Status</th>
                <th className="p-4">Preferred Language</th>
                <th className="p-4">Location</th>
                <th className="p-4">Active</th>
                <th className="p-4">Actions</th>
              </tr>
            </thead>
            <tbody>
              {users?.map((user, index) => (
                <motion.tr 
                  key={user.id} 
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.05 }}
                  className="border-b border-red-600 hover:bg-red-900 transition"
                >
                  <td className="p-4">{user.firstName} {user.lastName}</td>
                  <td className="p-4">{user.email}</td>
                  <td className="p-4">{user.isVerified ? "✅ Yes" : "❌ No"}</td>
                  <td className="p-4">{user.status || "N/A"}</td>
                  <td className="p-4">{user.preferredLanguage?.language_name || "N/A"}</td>
                  <td className="p-4">{user.location || "N/A"}</td>
                  <td className="p-4">
                    {user.isLoggedIn ? (
                      <span className="text-green-400 font-semibold animate-pulse">🟢 Online</span>
                    ) : (
                      <span className="text-gray-400 font-semibold">⚪ Offline</span>
                    )}
                  </td>
                  <td className="p-4">
                    <button className="bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-2 rounded-md mr-2 font-bold transition transform hover:scale-105">
                      Edit
                    </button>
                    <button className="bg-red-700 hover:bg-red-800 text-white px-5 py-2 rounded-md font-bold transition transform hover:scale-110">
                      Delete
                    </button>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AdminUsers;