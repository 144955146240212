import React, { useEffect, useState } from "react";
import SignUpForm from "./SignupForm";
import LoginForm from "./LoginForm";
// import axios from "axios";
import {  NavLink, useLocation, useNavigate } from "react-router-dom/dist";
import { useUser } from "../../context/UserContext";
import { FaArrowLeft } from "react-icons/fa";


const AuthForm: React.FC = () => {
  const location = useLocation();
  // const [isSignUp, setIsSignUp] = useState(location.pathname.includes('signup'));
  const [isSignUp, setIsSignUp] = useState(location.state?.fromSignup || false);

  const [otpVerified, setOtpVerified] = useState(false);
  const navigate = useNavigate();
  const {fetchLanguages} = useUser();
  // let bacendUrl = process.env.REACT_APP_API_URL;
  // let backendUrl = "https://totle-webisite-backend.onrender.com";

  useEffect(()=>{
    if(otpVerified){
      setIsSignUp(false);
    }
  },[otpVerified])
  useEffect(() => {
    if (location.state?.fromSignup) {
      setIsSignUp(true);
      navigate(location.pathname, { replace: true, state: {} }); // Clear state after setting
    }
  }, [location, navigate]);
  
  const handleSignupClick = async () => {
    try {
      await fetchLanguages();
      setIsSignUp(true);
    } catch (error) {
      console.error("Error fetching languages:", error);
      navigate('/auth'); // Navigate to signup even if there's an error
    }
  };

  return (
    <div className="flex h-screen">
      {/* Left Side: Branding Section */}
      <div
        className="hidden lg:flex lg:w-1/2 fixed h-full bg-cover bg-center"
        style={{
          backgroundImage: "url('/images/signup.jpg')", // Replace with your image path
        }}
      >
        <div className="absolute inset-0 bg-gradient-to-b from-blue-500 to-blue-800 opacity-70"></div>
        <div className="relative z-10 flex flex-col items-center justify-center text-center text-white p-8">
          <h1 className="text-4xl font-bold mb-4">Welcome to <NavLink to='/' className='text-white hover:text-tangering'>TOTLE</NavLink></h1>
          <p className="text-lg">
            Discover the easiest way to learn, connect, and grow. Join our
            platform and start your journey today!
          </p>
        </div>
      </div>

      {/* Right Side: Form Section */}
      <div className="flex-grow relative flex flex-col items-center justify-center bg-gray-50 lg:ml-[50%] overflow-y-auto">
        {/* Toggle Buttons */}
        {/* Back to Home Link */}
        <div className="absolute top-4 left-2 z-50">
          <NavLink
            to="/"
            className="flex items-center gap-2 text-sm font-medium text-gray-700 hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-400 hover:bg-clip-text hover:text-gray-500 transition-all duration-300 ease-in-out"
          >
            <FaArrowLeft className="text-base" />
            Back to Home
          </NavLink>
        </div>

        <div className="w-full bg-gray-50 z-10 flex justify-center space-x-4 py-6 sticky top-0">
          <button
            onClick={() => {
              setIsSignUp(true)
              handleSignupClick()
            }}
            className={`px-6 py-2 rounded-full font-medium text-sm transition duration-300 ease-in-out ${
              isSignUp
                ? "bg-blue-600 text-white shadow-lg scale-105"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Sign Up
          </button>
          <button
            onClick={() => setIsSignUp(false)}
            className={`px-6 py-2 rounded-full font-medium text-sm transition duration-300 ease-in-out ${
              !isSignUp
                ? "bg-blue-600 text-white shadow-lg scale-105"
                : "bg-gray-200 text-gray-700 hover:bg-gray-300"
            }`}
          >
            Login
          </button>
        </div>

        {/* Form Container */}
        <div className="w-full max-w-md mt-8">
          {isSignUp ? <SignUpForm  onOtpVerified={() => setOtpVerified(true)}/> : <LoginForm />}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;
