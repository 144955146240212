import React, { useEffect } from 'react';
import AOS from 'aos'; 
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import 'aos/dist/aos.css';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

const AboutUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate('/auth');
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Hero Section */}
      <section 
        className="hero-bg bg-cover bg-center relative min-h-screen flex items-center justify-center"
        style={{ backgroundImage: `url('/images/about-us.jpg')` }}>
        <div className="overlay bg-black bg-opacity-50 absolute inset-0"></div>
        <div className="text-center relative z-10" data-aos="fade-up">
          <h1 className="text-6xl font-extrabold text-white">About TOTLE</h1>
          <p className="text-xl text-gray-300 mt-4">Reimagining Education. One Topic, One Learner at a Time.</p>
        </div>
      </section>

      {/* Who We Are */}
      <section className="py-20 bg-gray-100 text-center">
        <div className="container mx-auto px-6 sm:px-10 lg:px-16" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Who Are We?</h2>
          <p className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
            TOTLE isn’t just another edtech startup—it’s a revolution.  
            We believe everyone knows something worth sharing, and every learner needs a guide.  
            We’re creating a world where education is flexible, accessible, and powered by people, not institutions.  
          </p>
        </div>
      </section>

      {/* Founding Story */}
      <section className="py-20 bg-white text-center">
  <div className="container mx-auto px-6 sm:px-10 lg:px-16" data-aos="fade-up">
    <h2 className="text-4xl font-bold mb-6 text-tangering">The Moment TOTLE Was Born</h2>
    <p className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
      TOTLE didn’t start as a business—it started as a question. 
      A question that haunted us, as we watched talented people struggle, education remain outdated, and learning become a privilege instead of a right.
      <br /><br />
      What if knowledge had no barriers?  
      - No overpriced courses.  
      - No one-size-fits-all curriculum.  
      - No gatekeeping.  
      <br />
      What if learning wasn’t locked in institutions, but powered by people?  
      <br /><br />
      In that moment, TOTLE was born. Not as a platform, but as a revolution—where anyone with knowledge can teach, anyone eager can learn, and education becomes limitless, borderless, and human again.  
    </p>
  </div>
</section>


      {/* Vision & Mission */}
      <section className="py-20 bg-gray-100 text-center">
        <div className="container mx-auto px-6 sm:px-10 lg:px-16" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Our Mission & Vision</h2>
          <p className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed">
            Our mission is simple: Break down the walls of traditional education and make learning truly borderless.  
            <br />
            We believe in a world where:  
            <br />✅ Anyone can teach (Empower every voice, from novice to master).  
      <br />✅ Anyone can learn (Unleash curiosity, from basics to breakthroughs).  
      <br />✅ Every skill is valuable (Transform lives, from math to music, AI to agriculture).  
          </p>
        </div>
      </section>

      {/* Core Values */}
      <section className="py-20 relative">
        <div className="container mx-auto px-6 sm:px-10 lg:px-16 text-center" data-aos="fade-up">
          <h2 className="text-4xl font-bold mb-6 text-tangering">Our Core Values</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 px-3">
            {[
              { title: 'Inclusivity', desc: 'Education should be for everyone—no gatekeeping, no barriers.', bg: 'bg-gray-100', text: 'text-tangering' },
              { title: 'Empowerment', desc: 'Turn learners into educators and knowledge into opportunity.', bg: 'bg-tangering', text: 'text-white' },
              { title: 'Innovation', desc: 'Tech should enhance learning, not replace human connection.', bg: 'bg-gray-100', text: 'text-tangering' },
              { title: 'Integrity', desc: 'Trust, transparency, and purpose drive everything we do.', bg: 'bg-tangering', text: 'text-white' }
            ].map((value, index) => (
              <div key={index} className={`p-6 ${value.bg} rounded-lg shadow-lg hover:shadow-xl transition duration-300`}>
                <h3 className={`text-xl font-semibold mb-2 ${value.text}`}>{value.title}</h3>
                <p className={`text-${value.text === 'text-white' ? 'white' : 'gray-600'}`}>
                  {value.desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-white text-center shadow-lg rounded-lg mx-auto max-w-5xl">
        <div className="container mx-auto px-6 sm:px-10 lg:px-16">
          <h2 className="text-5xl font-extrabold text-[#016f66] mb-6" data-aos="fade-up">
            🚀 Join the Beta as an Educator
          </h2>
          <p className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed" data-aos="fade-up" data-aos-delay="200">
            We’re gathering pioneers, dreamers, and risk-takers. Be part of TOTLE’s founding community and shape the future of one-on-one learning.
          </p>
          <div className="mt-8" data-aos="fade-up" data-aos-delay="400">
            <NavLink 
              to="/auth" 
              onClick={handleLinkClick} state={{ fromSignup: true }} 
              className="bg-[#f46b24] hover:bg-[#016f66] text-white font-bold text-xl py-4 px-12 rounded-full shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105"
            >
              Join Beta Now
            </NavLink>
          </div>
          <p className="text-gray-600 mt-6 text-sm font-medium tracking-wide pt-3" data-aos="fade-up" data-aos-delay="600">
            ⏳ Limited Beta Spots Available – Be Among the First to Join!
          </p>
        </div>
      </section>


      {/* Footer */}
      <Footer handleLinkClick={handleLinkClick}/>

    </div>
  );
};

export default AboutUs;
