import React from "react";
import { NavLink } from "react-router-dom";
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';

type FooterProps = {
  handleLinkClick?: () => void; // ✅ Optional prop
};

const Footer: React.FC<FooterProps> = ({ handleLinkClick }) => {
  return (
    <footer className="bg-gray-100 text-gray-700 py-8 px-6 sm:px-8 mt-14">
      <div className="flex flex-col md:flex-row justify-between items-center gap-8 text-center md:text-left">
        
        {/* Left Section */}
        <div>
          <p className="mb-2">
            &copy; {new Date().getFullYear()}{" "}
            <NavLink to="/admin/login" className="cursor-default">
              TOTLE
            </NavLink>
            . All rights reserved.
          </p>
          <div className="flex justify-center md:justify-start space-x-6">
            <NavLink
              to="/privacy-policy"
              onClick={handleLinkClick}
              className="hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 hover:bg-clip-text hover:text-transparent transition"
            >
              Privacy Policy
            </NavLink>
            <NavLink
              to="/contact"
              onClick={handleLinkClick}
              className="hover:bg-gradient-to-r hover:from-gray-800 hover:to-gray-600 hover:bg-clip-text hover:text-transparent transition"
            >
              Contact Us
            </NavLink>
          </div>
        </div>

        {/* Right Section */}
        <div>
          <h4 className="mb-2">Follow us on</h4>
          <ul className="flex gap-4 items-center justify-center md:justify-start list-none">
            <li>
              <a
                href="https://www.linkedin.com/company/totle-edtech/"
                target="_blank"
                rel="noreferrer"
                className="text-[#0077B5]"
              >
                <FaLinkedin />
              </a>
            </li>
            <li>
              <a
                href="https://x.com/Totle_edtech"
                target="_blank"
                rel="noreferrer"
                className="text-[#1DA1F2]"
              >
                <FaTwitter />
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/totle_edtech/?hl=en"
                target="_blank"
                className="text-[#E4405F]"
                rel="noreferrer"
              >
                <FaInstagram />
              </a>
            </li>
            <li>
              <a
                href="https://www.facebook.com/profile.php?id=61572098313677"
                target="_blank"
                className="text-blue-500"
                rel="noreferrer"
              >
                <FaFacebookSquare />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </footer>

  );
};

export default Footer;
