import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useAuth } from "../../context/AuthContext";
import { fetchUserProfile } from "../../services/userService";

const Navbar: React.FC = () => {
  const { isLoggedIn, logout, user } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClick = () => {
    window.scrollTo(0, 0);
    setIsOpen(false); // ✅ Close menu when link is clicked
  };

  // const handleProfileClick = async () => {
  //   try {
  //     const userData = await fetchUserProfile();
  //     if (userData) {
  //       console.log("✅ User Data from Backend:", userData);
  //     } else {
  //       console.error("❌ Failed to fetch user profile.");
  //     }
  //     navigate("/profile");
  //   } catch (error) {
  //     console.error("❌ Error fetching user profile:", error);
  //   }
  // };

  const linkClass = (isActive: boolean) =>
    `transition-all duration-300 hover:text-tangering ${
      isActive ? "text-tangering" : isScrolled ? "text-gray-700" : "text-gray-400"
    }`;

  return (
    <nav className={`fixed w-full z-20 top-0 transition-all duration-300 ${isScrolled ? "bg-white shadow-lg" : "bg-transparent"}`}>
      <div className="container mx-auto px-6 py-4 flex justify-between items-center">
        {/* LOGO */}
        <NavLink to="/" className="text-3xl font-bold text-tangering" onClick={handleLinkClick}>
          TOTLE<sup className={`align-super text-xs ${isScrolled ? 'text-gray-600 lg:text-gray-600' : 'text-gray-400'} hover:text-black`}>Beta</sup>
        </NavLink>

        {/* DESKTOP MENU */}
        <div className="hidden md:flex items-center space-x-8">
          {isLoggedIn ? (
            <>
<NavLink to="/marketplace" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
  Marketplace
</NavLink>
<NavLink to="/teach" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
  Teach
</NavLink>
<NavLink to="/learn" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
  Learn
</NavLink>
<NavLink to="/endeavour" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
  Endeavour
</NavLink>

{/* Beta Section with Attention-Grabbing Tag */}
<NavLink to="/beta" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive) + " relative"}>
  Beta
  <span className="absolute top-[-8px] right-[-27px] bg-red-500 text-white text-[10px] px-2 py-0.5 rounded-full shadow-md">
    NEW
  </span>
</NavLink>

              {user && (
  <NavLink 
    to={`/profile/${user.id}`} 
    onClick={handleLinkClick} 
    className={({ isActive }) => linkClass(isActive)}
  >
    Profile
  </NavLink>
)}




            </>
          ) : (
            <>
              <NavLink to="/" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                Home
              </NavLink>
              <NavLink to="/about" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                About Us
              </NavLink>
              <NavLink to="/product" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                Product
              </NavLink>
              <NavLink to="/investors" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                Investors
              </NavLink>
              <NavLink to="/blog" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                Blogs
              </NavLink>
              <NavLink to="/contact" onClick={handleLinkClick} className={({ isActive }) => linkClass(isActive)}>
                Contact Us
              </NavLink>
              <NavLink to="/auth" state={{ fromSignup: true }} onClick={handleLinkClick} className="bg-tangering text-white py-2 px-6 rounded-lg hover:bg-orange-600 transition-all">
                Sign Up
              </NavLink>
            </>
          )}
        </div>

        <div className="md:hidden z-30" onClick={() => setIsOpen(!isOpen)}>
        {!isOpen && <FaBars className={`${isScrolled ? 'text-gray-600' : 'text-gray-400'} w-6 h-6 cursor-pointer`} />}
        </div>
      </div>

      <div
        className={`fixed top-0 left-0 w-full h-full bg-gray-900 bg-opacity-100 transform ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out md:hidden flex flex-col items-center justify-center space-y-6`}
      >
        <button onClick={() => setIsOpen(false)} className="absolute top-6 right-6 text-white">
          <FaTimes className="w-8 h-8" />
        </button>

        {isLoggedIn ? (
          <>
   <NavLink to="/marketplace" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
  Marketplace
</NavLink>
<NavLink to="/teach" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
  Teach
</NavLink>
<NavLink to="/learn" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
  Learn
</NavLink>
<NavLink to="/endeavour" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
  Endeavour
</NavLink>

{/* Beta Section with Attention-Grabbing Tag */}
<NavLink to="/beta" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering relative">
  Beta
  <span className="absolute top-[-8px] right-[-30px] bg-red-500 text-white text-xs px-2 py-0.5 rounded-full">
    NEW
  </span>
</NavLink>

{user && (
  <NavLink 
    to={`/profile/${user.id}`} 
    onClick={handleLinkClick} 
    className="text-2xl text-white hover:text-tangering"
  >
    Profile
  </NavLink>
)}


          </>
        ) : (
          <>
            <NavLink to="/" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              Home
            </NavLink>
            <NavLink to="/about" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              About Us
            </NavLink>
            <NavLink to="/product" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              Product
            </NavLink>
            <NavLink to="/investors" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              Investors
            </NavLink>
            <NavLink to="/blog" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              Blogs
            </NavLink>
            <NavLink to="/contact" onClick={handleLinkClick} className="text-2xl text-white hover:text-tangering">
              Contact Us
            </NavLink>
            <NavLink to="/auth" state={{ fromSignup: true }} onClick={handleLinkClick} className="bg-tangering text-white text-xl py-3 px-8 rounded-lg hover:bg-orange-600 transition-all">
              Sign Up
            </NavLink>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
