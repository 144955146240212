import React, { useState } from "react";
import { motion } from "framer-motion"; // ✅ Smooth animations

const EndeavourDashboard: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    setResponseMessage("");

    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        setResponseMessage("❌ You must be logged in to subscribe.");
        setLoading(false);
        return;
      }

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/getUpdates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ endeavour: true }),
      });

      const data = await res.json();

      if (data.error) {
        setResponseMessage(` ${data.message}`);
      } else {
        setTimeout(() => {
          setResponseMessage(` ${data.message}`);
        }, 1600);
        setTimeout(() => {
          setResponseMessage("");
        }, 2700);
      }
    } catch (error) {
      setResponseMessage("❌ Failed to connect to the server.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };


  return (
    <>
    {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="flex flex-col gap-6 w-full items-center justify-center">
          <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
            <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
          </div>
        </div>
      </div>
    )}
    <div className="min-h-screen flex items-center justify-center bg-[#FFF] text-white px-6 pt-20">
      <motion.div
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="bg-[#FF8C00]/60 backdrop-blur-lg shadow-2xl rounded-2xl p-8 w-full max-w-3xl border border-white/30 text-center relative overflow-hidden"
      >
        {/* Animated Lock & Progress */}
        <div className="relative mx-auto w-32 h-32 mb-6">
          <motion.div
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
            className="absolute inset-0 bg-white opacity-20 rounded-full"
          ></motion.div>
          <div className="absolute inset-0 flex items-center justify-center">
            <motion.div
              initial={{ rotate: 0 }}
              animate={{ rotate: [0, 15, -15, 0] }}
              transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
              className="w-24 h-24 border-4 border-white/50 rounded-full flex items-center justify-center"
            >
              🔒 {/* Lock Icon */}
            </motion.div>
          </div>
        </div>

        {/* Title & Subtitle */}
        <h1 className="text-4xl font-extrabold tracking-wide text-white drop-shadow-lg">
          Endeavour Platform
        </h1>
        <p className="text-lg text-white-1500 mt-4">
          Unlocking Knowledge. Empowering Achievements. <br /> 🚀 Coming Soon!
        </p>

        {/* Progress Bar */}
        <div className="w-full bg-white/30 h-3 rounded-full mt-6 overflow-hidden">
          <motion.div
            initial={{ width: "0%" }}
            animate={{ width: "10%" }}
            transition={{ duration: 2, ease: "easeOut" }}
            className="bg-white h-full animate-pulse"
          ></motion.div>
        </div>
        <p className="text-white/2000 text-sm mt-2 font-semibold">Platform Readiness: 10%</p>

        {/* Stay Updated CTA */}
        <div className="mt-6">
          <p className="text-white/1500 text-sm font-semibold">Be the first to explore!</p>
          <button
            // onClick={() => alert("🚀 Stay tuned for updates!")}
            onClick={handleSubmit}
            disabled={loading}
            className="mt-3 px-6 py-2 bg-white text-[#FF4500] font-bold rounded-full shadow-lg transition-all duration-300 hover:bg-gray-200"
          >
            {loading ? "Submitting..." : "Stay Updated"}
          </button>
          {responseMessage && (
            <p className="text-sm mt-3 font-medium text-white">{responseMessage}</p>
          )}
        </div>
      </motion.div>
    </div>
    </>
  );
};

export default EndeavourDashboard;
