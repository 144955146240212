import React from "react";
import post from "../../images/post.jpg";
import independence from "../../images/independence.jpg";
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

import why from "../../images/why.jpg"
interface BlogPost {
  image: string;
  title: string;
  description: string;
  path: string; // New field for navigation
}

const Blog = () => {
  const navigate = useNavigate();

  const stories: BlogPost[] = [
    {
      image: post,
      title: "I Have a Dream",
      description: "A revolution in education, in opportunity, in human potential.",
      path: "/i-have-a-dream",
    },
    {
      image: independence,
      title: "Independence is in the Air",
      description: "True independence comes from ownership—over knowledge, opportunity, and success.",
      path: "/independence-is-in-the-air",
    },
    {
      image: why,
      title: "Why Does This Matter in the Age of AI?",
      description: "AI is changing everything, but human expertise is more valuable than ever.",
      path: "/why-this-matters-in-the-age-of-ai",
    }
  ];

  const handleNavigate = (path: string) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const handleLinkClick = () => {
    navigate('/contact');
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex flex-col min-h-screen pt-20">
      {/* Blog List */}
      <section className="max-w-6xl mx-auto py-20 px-4 flex-grow">
        <h2 className="text-2xl font-semibold mb-6">Latest Stories</h2>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
          {stories.map((story, index) => (
            <div
              key={index}
              onClick={() => handleNavigate(story.path)}
              className="bg-white shadow-md rounded-lg overflow-hidden cursor-pointer transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
            >
              <div
                className="w-full h-40 bg-cover bg-center"
                style={{ backgroundImage: `url(${story.image})` }}
              ></div>

              <div className="p-4">
                <h3 className="text-lg font-bold">{story.title}</h3>
                <p className="text-gray-600 text-sm mt-1">{story.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
      <Footer handleLinkClick={handleLinkClick}/>
             
    </div>
  );
};

export default Blog;
