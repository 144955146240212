import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: "primary" | "secondary" | "danger" | "ghost"; // ✅ Added "ghost"
  size?: "icon" | "default";
}

export const Button: React.FC<ButtonProps> = ({ variant = "primary", size = "default", className, children, ...props }) => {
  const baseStyles = "px-4 py-2 rounded-lg font-medium transition-all";
  const sizeStyles = size === "icon" ? "p-2" : "px-4 py-2";
  const variantStyles = {
    primary: "bg-blue-600 hover:bg-blue-700 text-white",
    secondary: "bg-gray-700 hover:bg-gray-800 text-white",
    danger: "bg-red-600 hover:bg-red-700 text-white",
    ghost: "bg-transparent hover:bg-gray-800 text-gray-300", // ✅ Added ghost style
  };

  return (
    <button className={`${baseStyles} ${sizeStyles} ${variantStyles[variant]} ${className}`} {...props}>
      {children}
    </button>
  );
};
