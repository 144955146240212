import type { User } from "../context/AuthContext"; // ✅ Import User type
import axios from 'axios';
const backendUrl = process.env.REACT_APP_API_URL;;

// ✅ Updated function name & added error handling
export const fetchUserProfile = async () => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await fetch(`${backendUrl}/auth/user/`, {
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    });

    if (!response.ok) {
      console.error("❌ Error fetching user profile:", response.statusText);
      return null; // Return null if fetching fails
    }
    let data = await response.json()
    // console.log('user data',data)
    return data.user // ✅ Ensure we only return the `user` object
    } catch (error) {
      console.error("❌ API Error fetching user profile:", error);
      return null;
  }
};

// ✅ Improved error handling for update profile API
export const updateUserProfile = async (token: string, updatedData: User): Promise<User | null> => {
  try {
    if (!token || !token.startsWith("ey")) { 
      throw new Error("Invalid or missing authentication token.");
    }

    // ✅ Convert arrays into valid JSON format
    const formattedData = {
      ...updatedData,
      // skills: updatedData.skills ? updatedData.skills : [],
      knownLanguages: updatedData.known_language_ids ? updatedData.known_language_ids.map(String) : [],
      preferredLanguage: updatedData.preferred_language_id ? updatedData.preferred_language_id.toString() : "0",
      // qualification: updatedData.educational_qualifications ?? [],
    };

    // ✅ Send JSON instead of FormData
    const response = await fetch(`${backendUrl}/auth/updateUser`, {
      method: "PUT",
      headers: {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json", // ✅ Expect JSON, not FormData
      },
      body: JSON.stringify(formattedData),
    });

    if (!response.ok) {
      throw new Error(`Failed to update profile. Status: ${response.status}`);
    }

    const data = await response.json();
    return data.user;
  } catch (error) {
    console.error("❌ API Error updating user profile:", error);
    return null;
  }
};


export const getLanguages = async (): Promise<{ value: number; label: string }[]> => {
  try {
    const response = await axios.get(`${backendUrl}/languages`);

    // ✅ Return array of objects with { value: language_id, label: language_name }
    const languageOptions = response.data.map((language: { language_id: number; language_name: string }) => ({
      value: language.language_id, // ID as value
      label: language.language_name, // Name as label
    }));

    return languageOptions;
  } catch (error) {
    console.error("❌ Error fetching languages:", error);
    return [];
  }
};

