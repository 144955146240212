import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import { auth, googleProvider, signInWithPopup } from "../../config/firebase";
import { useAuth } from "../../context/AuthContext";
import ForgotPasswordForm from "./ForgotPasswordForm";
import WelcomeScreen from "../../components/UI/WelcomeScreen";


const LoginForm: React.FC = () => {
  const { login } = useAuth(); // ✅ Get login function from AuthContext
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false); // ✅ Show loading state
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(false);

  const backendUrl = process.env.REACT_APP_API_URL; // Replace with actual API URL

  // ✅ Form Validation
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.password) newErrors.password = "Password is required.";
    return newErrors;
  };

  // ✅ Handle Input Change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // ✅ Handle Form Submission (Email & Password Login)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setErrors({}); // Clear previous errors
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/auth/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      // console.log(data)

      if (data.error) {
        setErrors({ form: data.message || "Login failed. Please try again." });
      } else {
        login(data.token, data.user); // ✅ Store authentication state globally
        const welcomeResponse = await fetch(`${backendUrl}/auth/user`, {
          headers: {
            "Authorization": `Bearer ${data.token}`, // ✅ Send token
            "Content-Type": "application/json",
          },
        });
  
        const welcomeData = await welcomeResponse.json();
        // console.log('welcome', welcomeData.hasSeenWelcomeScreen)
        if (welcomeData.hasSeenWelcomeScreen) {
          localStorage.setItem("hasSeenWelcomeMessage","true")
          navigate("/platform"); // ✅ Redirect if welcome screen is already seen
        } else {
          localStorage.setItem("hasSeenWelcomeMessage", "false")
          // console.log('token', localStorage.getItem("hasSeenWelcomeMessage"))
          setShowWelcomeScreen(true); // ✅ Show welcome screen
        }
      }
    } catch (error) {
      console.error("Error during login:", error);
      setErrors({ form: "An error occurred. Please try again later." });
    }
    setLoading(false);
  };

  // useEffect(() => {
  //   const checkWelcomeStatus = async () => {
  //     try {
  //       const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/welcomeScreen`);
  //       if (!response.ok) throw new Error("Failed to fetch user data");

  //       const data: { hasSeenWelcomeScreen: boolean } = await response.json();

  //       if (!data.hasSeenWelcomeScreen && !localStorage.getItem("hasSeenWelcomeScreen")) {
  //         setShowWelcomeScreen(true);
  //       }
  //     } catch (error) {
  //       console.error("Error checking welcome screen status:", error);
  //     }
  //   };

  //   checkWelcomeStatus();
  // }, []);
  if (showWelcomeScreen) return <WelcomeScreen />;

  // ✅ Handle Google SSO Login
  // const handleGoogleLogin = async () => {
  //   try {
  //     const result = await signInWithPopup(auth, googleProvider);
  //     const token = await result.user.getIdToken();

  //     const response = await fetch(`${backendUrl}/auth/verify-token`, {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ token }),
  //     });

  //     const data = await response.json();

  //     if (data.error) {
  //       setErrors({ form: "Google Authentication failed. Please try again." });
  //     } else {
  //       login(data.backendToken, data.user); // ✅ Store JWT Token globally
  //       navigate("/platform"); // ✅ Redirect user
  //     }
  //   } catch (error) {
  //     console.error("Google Authentication Error:", error);
  //     setErrors({ form: "An unexpected error occurred. Please try again." });
  //   }
  // };
  // if (loading) {
  //   return (
  //     <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
  //       <div className="flex flex-col gap-6 w-full items-center justify-center">
  //         <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
  //           <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <>
    {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="flex flex-col gap-6 w-full items-center justify-center">
          <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
            <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
          </div>
        </div>
      </div>
    )}
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl font-bold text-gray-800 text-center">Login</h2>

        {/* Error Message */}
        {errors.form && <p className="text-red-500 text-sm mt-2">{errors.form}</p>}

        <form onSubmit={handleSubmit} className="mt-4 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Email</label>
            <input
              type="email"
              name="email"
              className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
          </div>

          <div className="mb-4 relative">
              <label className="block text-sm font-medium text-gray-700">Password</label>
              <div className="relative">
              <input
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 px-2 py-1"
              />
              <div
                className="absolute inset-y-0 right-3 flex items-center cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "👁" : "🙈"}
              </div>
              </div>
            </div>

          <button
            type="submit"
            className={`w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:ring-2 focus:ring-blue-500 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? "Logging in..." : "Login"}
          </button>
        </form>

        {/* Google SSO Login */}
        {/* <div className="mt-6 text-center">
          <p className="text-gray-500">Or login with</p>
          <div className="flex justify-center space-x-4 mt-4">
            <button
              onClick={handleGoogleLogin}
              className="bg-gray-200 p-2 rounded-md hover:bg-gray-300 w-full"
            >
              Google
            </button>
          </div>
        </div> */}
        {/* Forgot Password */}
        <div className="mt-4 text-center">
        <button
          type="button"
          onClick={() => setForgotPasswordOpen(true)}
          className="text-blue-600 hover:underline"
        >
          Forgot Password?
        </button>
        </div>
        {isForgotPasswordOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 backdrop-blur-sm z-20">
            <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full relative">
            <label className="absolute top-2 right-2 inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                onChange={() => setForgotPasswordOpen(false)}
                checked={false} // Always reset on click
              />
              <div className="peer ring-0 bg-rose-400 rounded-full outline-none duration-300 after:duration-500 w-6 h-6 shadow-md peer-checked:bg-emerald-500 peer-focus:outline-none 
                              after:content-['✖️'] after:rounded-full after:absolute after:outline-none after:h-4 after:w-4 after:bg-gray-50 after:top-1 after:left-1 
                              after:flex after:justify-center after:items-center peer-hover:after:scale-75 peer-checked:after:content-['✔️'] after:-rotate-180 peer-checked:after:rotate-0">
              </div>
            </label>
              <ForgotPasswordForm onClose={() => setForgotPasswordOpen(false)}/>
            </div>
          </div>
        )}

      </div>
    </>

  );
};

export default LoginForm;
