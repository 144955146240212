import React from "react";
import { Outlet } from "react-router-dom";

const EndeavourPlatform: React.FC = () => {
  return (
    <div>
  
      {/* Sidebar and Main Content Layout */}
      <div>
        <div>
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default EndeavourPlatform;
