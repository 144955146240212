import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { FaUsers, FaGlobe, FaBrain } from "react-icons/fa";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { NavLink, useNavigate } from "react-router-dom";
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare } from 'react-icons/fa';
import Footer from "../Footer/Footer";

const Investors = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const navigate = useNavigate();
  const handleLinkClick = () => {
    navigate("/auth");
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-gray-100 pt-20 text-center">
      {/* Main Header */}
      <h2 className="text-5xl font-extrabold pt-10 mb-8 text-gray-900" data-aos="fade-up">
        Invest in the Future of Learning. Invest in TOTLE.
      </h2>
      <p className="text-lg text-gray-700 max-w-3xl mx-auto mb-10" data-aos="fade-up" data-aos-delay="200">
        For the first time in history, education is no longer bound by institutions.  
        Everyone knows something. Everyone can teach.  
        TOTLE is the first platform to unlock knowledge as a shared global asset.
      </p>

      {/* Market Opportunity Section */}
      <section className="py-16 bg-cover bg-center text-white"
         style={{ backgroundImage: `linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6)), url('/images/investor.jpg')` }}>
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-6 text-white" data-aos="fade-up">
            The $200 Billion EdTech Revolution Is Here.
          </h3>
          <p className="text-lg mb-8 text-white" data-aos="fade-up" data-aos-delay="200">
            Education is the largest untapped market in human history.  
            By 2028, the EdTech industry is set to surpass $200 billion—yet it still follows old models that leave millions behind.  
            TOTLE isn’t another EdTech company. It’s a global movement to reshape education forever.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div data-aos="fade-up" data-aos-delay="300">
              <h4 className="text-2xl font-bold mb-4">Scalable AI Infrastructure</h4>
              <p>
                TOTLE’s AI-driven system is designed to handle millions of learners worldwide—providing personalized learning at scale.
              </p>
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
              <h4 className="text-2xl font-bold mb-4">No More Paywalls</h4>
              <p>
                We have destroyed the subscription model. Learners only pay-per-topic—ensuring affordability and wider global accessibility.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* The Investment Appeal */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-6">
          <h3 className="text-3xl font-semibold mb-6 text-tangering" data-aos="fade-up">
            Why Invest in TOTLE?
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {/* Revenue Growth */}
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105" data-aos="fade-up" data-aos-delay="300">
              <h4 className="text-xl font-bold text-tangering mb-4">Massive Market Potential</h4>
              <p>
                TOTLE’s business model disrupts outdated education systems, targeting millions of underserved learners and educators.
              </p>
            </div>

            {/* Expansion Plans */}
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105" data-aos="fade-up" data-aos-delay="400">
              <h4 className="text-xl font-bold text-blue-500 mb-4">Global Expansion</h4>
              <p>
                We are starting in India where over 400 million learners are waiting for an accessible solution.
              </p>
            </div>

            {/* Profitability */}
            <div className="p-6 bg-white rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 transform hover:scale-105" data-aos="fade-up" data-aos-delay="500">
              <h4 className="text-xl font-bold text-green-500 mb-4">Sustainable Revenue Model</h4>
              <p>
                Our pay-per-topic pricing, AI automation, and low operational costs set us on track for profitability within 24 months.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Impact Metrics */}
      <section className="pb-14 bg-white">
        <h2 className="text-4xl font-semibold text-gray-800 mb-12" data-aos="fade-up" data-aos-delay="400">
          The Change We Are Creating
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="500">
            <FaUsers className="text-4xl text-tangering mb-4 transition-transform duration-300 group-hover:scale-110" />
            <p className="text-gray-600 mt-4">1 Billion+ Learners</p>
            <p className="text-gray-500 mt-2">A new way of learning for the world.</p>
          </div>

          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="600">
            <FaGlobe className="text-4xl text-blue-500 mb-4 transition-transform duration-300 group-hover:scale-110" />
            <p className="text-gray-600 mt-4">Expanding Beyond Borders</p>
            <p className="text-gray-500 mt-2">Starting with India, expanding worldwide.</p>
          </div>

          <div className="flex flex-col items-center group" data-aos="fade-up" data-aos-delay="700">
            <FaBrain className="text-4xl text-green-500 mb-4 transition-transform duration-300 group-hover:scale-110" />
            <p className="text-gray-600 mt-4">AI-Driven Personalization</p>
            <p className="text-gray-500 mt-2">Learning that adapts to the user.</p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-16 bg-[#016f66] text-white text-center">
        <h2 className="text-4xl font-bold mb-6">Be Part of the Revolution</h2>
        <p className="text-lg mb-8">
          Whether you're an investor, an educator, or someone who believes in the power of knowledge—this is your chance to be part of history.
        </p>
        <NavLink to="/contact" onClick={handleLinkClick} className="bg-white text-[#016f66] font-bold text-xl py-4 px-10 rounded-lg shadow-lg hover:shadow-xl transition duration-300">
          Get Involved
        </NavLink>
      </section>

      {/* Footer */}
      <Footer handleLinkClick={handleLinkClick}/>

    </section>
  );
};

export default Investors;
