import React, { useState } from "react";
import { motion } from "framer-motion";

const LearnPage: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const handleSubmit = async () => {
    setLoading(true);
    setResponseMessage("");

    try {
      const token = localStorage.getItem("userToken");

      if (!token) {
        setResponseMessage("❌ You must be logged in to subscribe.");
        setLoading(false);
        return;
      }

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/getUpdates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ learn: true }),
      });

      const data = await res.json();

      if (data.error) {
        setResponseMessage(`${data.message}`);
      } else {
        setTimeout(() => {
          setResponseMessage(` ${data.message}`);
        }, 1600);
        setTimeout(() => {
          setResponseMessage("");
        }, 2700);
      }
    } catch (error) {
      setResponseMessage("❌ Failed to connect to the server.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };
  return (
    <>
    {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="flex flex-col gap-6 w-full items-center justify-center">
          <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
            <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
          </div>
        </div>
      </div>
    )}
    
      <div className="min-h-screen flex items-center justify-center bg-[#FFF] text-white px-6 pt-20">
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.6, ease: "easeOut" }}
          className="bg-[#005F5B] backdrop-blur-lg shadow-2xl rounded-2xl p-8 w-full max-w-3xl border border-white/30 text-center"
        >
          {/* Title */}
          <h1 className="text-5xl font-extrabold tracking-wide text-white drop-shadow-lg">
            📚 Learn Platform - The Future of Education
          </h1>
          <p className="text-lg text-white-200 mt-4">
            Personalized Learning Paths. AI-Powered Sessions. Global Certifications. <br /> 🚀 Coming Soon!
          </p>

          {/* Progress Indicator */}
          <div className="w-full bg-white/30 h-3 rounded-full mt-6 overflow-hidden">
            <motion.div
              initial={{ width: "0%" }}
              animate={{ width: "50%" }}
              transition={{ duration: 2, ease: "easeOut" }}
              className="bg-white h-full animate-pulse"
            ></motion.div>
          </div>
          <p className="text-white/200 text-sm mt-2">Platform Readiness: 50%</p>

          {/* AI Learning Pathway Teaser */}
          <div className="mt-10">
            <h2 className="text-2xl font-bold text-white">🌍 Your AI Learning Journey</h2>
            <p className="text-white/200 text-sm mt-2">Smart Learning Paths for Skill Growth</p>
            <div className="flex justify-center gap-3 mt-4">
              <div className="p-4 border border-white/40 rounded-lg bg-white/10">
                <p className="text-sm font-semibold">🛠️ Explorer</p>
                <p className="text-xs text-white/300">Foundation Skills</p>
              </div>
              <div className="p-4 border border-white/40 rounded-lg bg-white/10">
                <p className="text-sm font-semibold">📚 Scholar</p>
                <p className="text-xs text-white/300">Deep Learning</p>
              </div>
              <div className="p-4 border border-white/40 rounded-lg bg-white/10">
                <p className="text-sm font-semibold">🎓 Achiever</p>
                <p className="text-xs text-white/300">Skill Mastery</p>
              </div>
              <div className="p-4 border border-white/40 rounded-lg bg-white/10">
                <p className="text-sm font-semibold">🌟 Certified</p>
                <p className="text-xs text-white/300">Global Recognition</p>
              </div>
            </div>
          </div>

          {/* CTA */}
          <div className="mt-10">
            <p className="text-white/200 text-sm font-semibold">Be the first to revolutionize learning!</p>
            <button
              onClick={handleSubmit}
              disabled={loading}
              className="mt-3 px-6 py-2 bg-white text-[#005F5B] font-bold rounded-full shadow-lg hover:bg-gray-200 transition-all"
            >
              {loading ? "Submitting..." : "Stay Updated"}
            </button>
            {responseMessage && (
              <p className="text-sm mt-3 font-medium text-white">{responseMessage}</p>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default LearnPage;
