import axios from "axios";

const backendUrl = process.env.REACT_APP_API_URL; // ✅ Load backend URL from env variables

interface SurveyResult {
    question: string;
    type: "text" | "multiple-choice" | "single-choice" | "rating-scale";
    responses: Record<string, number> | string[];
    sentimentAnalysis?: { positive: number; neutral: number; negative: number };
  }
  
/**
 * Fetch surveys available for Beta users.
 * Returns an array of surveys with id, title, and description.
 */
export const fetchBetaSurveys = async (): Promise<{ id: string; title: string; description: string; statusSubmitted: boolean }[]> => {
  try {
    const token = localStorage.getItem("userToken");
    const response = await axios.get(`${backendUrl}/admin/surveys`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log('response',response.data)
    return response.data.map((survey: any) => ({
      id: String(survey.id), // ✅ Convert ID to string
      title: survey.title,
      statusSubmitted: (survey.submitted==="submitted")||false
    }));
  } catch (error) {
    console.error("❌ Error fetching Beta surveys:", error);
    return []; // ✅ Return an empty array if an error occurs
  }
};

/**
 * Fetch a single survey by ID.
 * Returns survey details including title, description, and questions.
 */
export const fetchSurveyById = async (surveyId: string | undefined): Promise<{ title: string; description: string; questions: any[]; statusSubmitted: boolean } | null> => {
  try {
    if (!surveyId) throw new Error("Survey ID is required.");
    const token = localStorage.getItem("userToken"); // ✅ Get token from localStorage
    console.log('token', token)
    if (!token) throw new Error("User is not authenticated.");
    
    const response = await axios.get(`${backendUrl}/admin/surveys/questions/${surveyId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    console.log('sub',response.data)
    return {
      title: response.data.title,
      description: response.data.description,
      questions: Array.isArray(response.data.questions) ? response.data.questions : [], // ✅ Ensure it's an array
      statusSubmitted: response.data.statusSubmitted||false
    };
  } catch (error) {
    console.error(`❌ Error fetching survey with ID ${surveyId}:`, error);
    return null;
  }
};


/**
 * Submit survey responses to the backend.
 */
export const submitSurveyResponses = async (
  surveyId: string | undefined,
  responses: { [key: string]: string | string[] }
): Promise<{ submitted: boolean } | null> => {
  try {
    if (!surveyId) throw new Error("Survey ID is required.");
    if (!Object.keys(responses).length) throw new Error("Responses cannot be empty.");

    const token = localStorage.getItem("userToken");
    if (!token) throw new Error("User is not authenticated.");

    const response = await axios.post(
      `${backendUrl}/admin/surveys/${surveyId}/responses`,
      { responses },
      { headers: { Authorization: `Bearer ${token}` } }
    );

    if (response.status === 201) {
      return { submitted: true }; // ✅ Return the expected format
    } else {
      return null; // ❌ Handle unexpected status
    }// ✅ Return true if successful
  } catch (error) {
    console.error("❌ Error submitting survey responses:", error);
    return null;
  }
};

/* ==================== ✅ ADMIN SURVEY MANAGEMENT ✅ ==================== */

/**
 * Create a new survey (Admin only).
 */
export const createSurvey = async (surveyData: { title: string; questions: { text: string; type: string; options?: string[] }[] }): Promise<boolean> => {
  try {
    const token = localStorage.getItem("adminToken");
    if (!token) throw new Error("Admin is not authenticated.");

    const response = await axios.post(
      `${backendUrl}/admin/surveys`,
      surveyData,
      { headers: { Authorization: `Bearer ${token}` } }
    );

    return response.status === 201; // ✅ Return true if successful
  } catch (error) {
    console.error("❌ Error creating survey:", error);
    return false;
  }
};

/**
 * Fetch all surveys (Admin only).
 */
export const fetchAdminSurveys = async (): Promise<{ id: string; title: string }[]> => {
  try {
    const token = localStorage.getItem("adminToken");
    if (!token) throw new Error("Admin is not authenticated.");

    const response = await axios.get(`${backendUrl}/admin/surveys`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data;
  } catch (error) {
    console.error("❌ Error fetching admin surveys:", error);
    return [];
  }
};

export const fetchSurveyResults = async (surveyId: string): Promise<SurveyResult[]> => {
    try {
        const token = localStorage.getItem("adminToken");
        if (!token) throw new Error("Admin is not authenticated.");
  
        const response = await axios.get(`${backendUrl}/admin/surveys/${surveyId}/results`, {
            headers: { Authorization: `Bearer ${token}` },
        });

        return response.data.map((result: any) => ({
            question: result.question,
            type: result.type,
            responses: result.responses || {},
            wordCloud: result.type === "text" ? result.wordCloud || {} : undefined, // ✅ Only set for text-based responses
            sentimentAnalysis: result.type === "text" 
                ? result.sentimentAnalysis || { positive: 0, neutral: 0, negative: 0 } 
                : undefined, // ✅ Only set for text-based responses
        }));
    } catch (error) {
        console.error(`❌ Error fetching results for survey ${surveyId}:`, error);
        return [];
    }
};


/**
 * Delete a survey (Admin only).
 */
export const deleteSurvey = async (surveyId: string): Promise<boolean> => {
  try {
    const token = localStorage.getItem("adminToken");
    if (!token) throw new Error("Admin is not authenticated.");

    await axios.delete(`${backendUrl}/admin/surveys/${surveyId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return true; // ✅ Successfully deleted
  } catch (error) {
    console.error(`❌ Error deleting survey ${surveyId}:`, error);
    return false;
  }
};

/**
 * Update an existing survey (Admin only).
 */
export const updateSurvey = async (
    surveyId: string,
    surveyData: { title: string; questions: { text: string; type: string; options?: string[] }[] }
  ): Promise<boolean> => {
    try {
      const token = localStorage.getItem("adminToken");
      if (!token) throw new Error("Admin is not authenticated.");
  
      const response = await axios.put(
        `${backendUrl}/admin/surveys/${surveyId}`,
        surveyData,
        { headers: { Authorization: `Bearer ${token}` } }
      );
  
      return response.status === 200; // ✅ Return true if successful
    } catch (error) {
      console.error("❌ Error updating survey:", error);
      return false;
    }
  };
  