// src/pages/admin/AdminLogin.tsx

import { useAdminAuth } from "../../context/AdminAuthContext";
import { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { loginAdmin } = useAdminAuth();
  const [showPassword, setShowPassword] = useState(false);

  const backend_url= process.env.REACT_APP_API_URL

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setLoading(true);

    try {
      const response = await fetch(`${backend_url}/admin/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Login failed");
      }

      // Store token in localStorage
      loginAdmin(data.token, data.admin);
    } catch (err: any) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  

  return (
    <div className="flex items-center justify-center min-h-screen bg-black">
      <div className=" absolute top-5 left-14">
          <NavLink to="/">
            <h2 className="text-3xl font-bold text-tangering">TOTLE</h2>
          </NavLink>
      </div>
      <div className="w-full max-w-md bg-gray-900 p-8 border border-red-600 rounded-lg shadow-lg">

        <h2 className="text-2xl font-bold text-red-500 text-center">🦾 Admin Access</h2>
        <form onSubmit={handleLogin} className="mt-6 space-y-4">
          <input
            type="email"
            placeholder="Tony@starkindustries.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 bg-black text-yellow-400 border-b border-red-600 focus:outline-none"
          />
          <div className="relative">
          <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter Jarvis Protocol"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 bg-black text-yellow-400 border-b border-red-600 focus:outline-none"
            />
            <button
                type="button"
                className="absolute right-3 top-2 text-xl text-yellow-400 focus:outline-none"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? "😲" : "😣"}
            </button>
          </div>

          {error && <p className="text-red-400 text-sm">{error}</p>}
          <button
            type="submit"
            disabled={loading}
            className="w-full py-2 bg-red-600 text-white font-bold rounded-md transition duration-300 hover:bg-red-500"
          >
            {loading ? "Authenticating..." : "Enter Stark Systems"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
