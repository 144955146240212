import React, { useState } from "react";
import { motion } from "framer-motion";

const TeachPage: React.FC = () => {

  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleSubmit = async () => {
    setLoading(true);
    setResponseMessage("");

    try {
      const token = localStorage.getItem("userToken");
      console.log('token', token);

      if (!token) {
        setResponseMessage("❌ You must be logged in to subscribe.");
        setLoading(false);
        return;
      }

      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/getUpdates`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          teach: true,
        }),
      });

      const data = await res.json();
      if (data.error) {
        setResponseMessage(`${data.message}`);
      } else {
        setTimeout(() => {
          setResponseMessage(` ${data.message}`);
        }, 1600);
        setTimeout(() => {
          setResponseMessage("");
        }, 2700);
      }
    } catch (error) {
      setResponseMessage("❌ Failed to connect to the server.");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  return (
    <>
    {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="flex flex-col gap-6 w-full items-center justify-center">
          <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
            <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
          </div>
        </div>
      </div>
    )}
    <div className="min-h-screen flex items-center justify-center bg-white text-gray-900 px-6 pt-20">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.6, ease: "easeOut" }}
        className="bg-white shadow-xl rounded-lg p-10 text-center max-w-4xl border border-gray-200"
      >
        {/* Header */}
        <h1 className="text-5xl font-extrabold text-[#21717b]">
          🎓 Teach Platform - The Future of Teaching
        </h1>
        <p className="text-lg text-gray-600 mt-4">
          Redefining Education. Empowering Educators. <br /> 🚀 Coming Soon!
        </p>

        {/* Progress Bar */}
        <div className="w-full bg-gray-200 h-3 rounded-full mt-6 overflow-hidden">
          <motion.div
            initial={{ width: "0%" }}
            animate={{ width: "78%" }}
            transition={{ duration: 2, ease: "easeOut" }}
            className="bg-[#21717b] h-full animate-pulse"
          ></motion.div>
        </div>
        <p className="text-gray-600 text-sm mt-2">Platform Readiness: 78%</p>

        {/* AI Career Progression Teaser */}
        <div className="mt-10">
          <h2 className="text-2xl font-bold text-gray-800">🌟 Your Career Progression</h2>
          <p className="text-gray-600 text-sm mt-2">AI-powered pathways to becoming a Legend</p>
          <div className="flex justify-center gap-3 mt-4">
            <div className="p-4 border border-gray-300 rounded-lg bg-gray-100">
              <p className="text-sm font-semibold">👨‍🏫 Bridger</p>
              <p className="text-xs text-gray-500">Start your journey</p>
            </div>
            <div className="p-4 border border-gray-300 rounded-lg bg-gray-100">
              <p className="text-sm font-semibold">📖 Expert</p>
              <p className="text-xs text-gray-500">Build credibility</p>
            </div>
            <div className="p-4 border border-gray-300 rounded-lg bg-gray-100">
              <p className="text-sm font-semibold">🏆 Master</p>
              <p className="text-xs text-gray-500">Train other teachers</p>
            </div>
            <div className="p-4 border border-gray-300 rounded-lg bg-gray-100">
              <p className="text-sm font-semibold">🌍 Legend</p>
              <p className="text-xs text-gray-500">Monetize globally</p>
            </div>
          </div>
        </div>

        {/* CTA */}
        <div className="mt-10">
          <p className="text-gray-600 text-sm font-semibold">Be the first to experience the future of teaching!</p>
          <button
            onClick={handleSubmit}
            disabled={loading}
            className="mt-3 px-6 py-2 bg-[#21717b] text-white font-bold rounded-full shadow-lg hover:bg-[#185b60] transition-all"
          >
            {loading ? "Submitting..." : "Stay Updated"}
          </button>
          {responseMessage && (
            <p className="text-sm mt-3 font-medium text-gray-600">{responseMessage}</p>
          )}
        </div>
      </motion.div>
    </div>
    </>
  );
};

export default TeachPage;
