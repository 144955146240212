import React, { useEffect, useState } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import { FaChartBar } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { motion } from "framer-motion";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement,
} from "chart.js";
import { useAdminAuth } from "../../context/AdminAuthContext";
import axios from "axios";

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  PointElement,
  LineElement
);

const backendUrl = process.env.REACT_APP_API_URL;

const AdminSurveyDashboard: React.FC = () => {
  const [surveyData, setSurveyData] = useState<any[]>([]);
  const [surveyNames, setSurveyNames] = useState<any[]>([]);
  const [selectedSurveyId, setSelectedSurveyId] = useState<string | null>(null);
  const [selectedSurveyName, setSelectedSurveyName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const { logoutAdmin } = useAdminAuth();

  // Fetch all survey names
  useEffect(() => {
    const fetchSurveyNames = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${backendUrl}/admin/surveyNames`);
        setSurveyNames(response.data);
      } catch (err) {
        setError("Failed to load survey names.");
      } finally {
        setLoading(false);
      }
    };
    fetchSurveyNames();
  }, []);

  // Fetch survey results when a survey is selected
  const fetchSurveyResults = async (surveyId: string, surveyName: string) => {
    setLoading(true);
    setSelectedSurveyId(surveyId);
    setSelectedSurveyName(surveyName);

    try {
      const response = await axios.get(`${backendUrl}/admin/surveys/surveyResults/${surveyId}`);
      setSurveyData([response.data]); // Wrapping in array to maintain consistency
    } catch (err) {
      setError("Failed to load survey results.");
    } finally {
      setLoading(false);
    }
  };


  const exportAsJSON = () => {
    const jsonBlob = new Blob([JSON.stringify(surveyData, null, 2)], { type: "application/json" });
    saveAs(jsonBlob, "survey-results.json");
  };

  // Export survey data as CSV
  const exportAsCSV = () => {
    let csvContent = "Survey,Question,Option,Responses\n";
    surveyData.forEach((survey) => {
      survey.results.forEach((question) => {
        Object.entries(question.responses).forEach(([option, count]) => {
          csvContent += `"${survey.survey}","${question.question}","${option}",${count}\n`;
        });
      });
    });
    saveAs(new Blob([csvContent], { type: "text/csv;charset=utf-8;" }), "survey-results.csv");
  };

  return (
    <div className="min-h-screen bg-black text-white p-8">
      {/* ✅ Page Header */}
      <div className="flex justify-between items-center mb-4">
        <motion.h1
          className="text-4xl font-bold text-red-500"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1 }}
        >
          📊 Admin Survey Dashboard
        </motion.h1>
        <div className="flex space-x-4">
          <NavLink
            to="/admin/dashboard"
            className="bg-yellow-400 text-black px-4 py-2 rounded-md font-semibold hover:bg-yellow-300 transition"
          >
            🔙 Back to Dashboard
          </NavLink>
          <button
            onClick={logoutAdmin}
            className="bg-red-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-red-500 transition"
          >
            🚪 Logout
          </button>
        </div>
      </div>

      {/* ✅ Loading and Error Handling */}
      {loading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="w-24 h-24 border-4 border-transparent animate-spin border-t-blue-400 rounded-full"></div>
        </div>
      ) : error ? (
        <p className="text-center text-red-500">{error}</p>
      ) : (
        <div className="w-full">
          {/* ✅ Survey Selection */}
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {surveyNames.map((survey) => (
              <button
                key={survey.id}
                className={`bg-gray-900 p-4 rounded-lg shadow-md border ${
                  selectedSurveyId === survey.id ? "border-yellow-400" : "border-red-600"
                } hover:bg-red-800 transition`}
                onClick={() => fetchSurveyResults(survey.id, survey.title)}
              >
                <FaChartBar className="text-yellow-400 mb-2 text-2xl" />
                <h2 className="text-lg font-bold">{survey.title}</h2>
              </button>
            ))}
          </div>

          {/* ✅ Survey Results Display */}
          {selectedSurveyId && (
            <div className="mt-6">
              <h2 className="text-2xl font-semibold text-red-500 mb-4">{selectedSurveyName} Results</h2>
              <p className="text-sm text-gray-400 mb-2">
                📊 <strong>Responses collected:</strong> {surveyData.reduce((acc, s) => acc + s.answeredCount, 0)}
              </p>

              {/* Export Buttons */}
              <div className="flex space-x-4 mb-4">
                <button onClick={exportAsJSON} className="bg-blue-600 px-4 py-2 rounded-lg">📥 JSON</button>
                <button onClick={exportAsCSV} className="bg-green-600 px-4 py-2 rounded-lg">📥 CSV</button>
              </div>

              {/* Chart Representation */}
              {surveyData.length > 0 ? (
                surveyData.map((result, index) => (
                  <div key={index} className="bg-gray-800 p-4 rounded-lg mb-6 border border-red-500">
                    <h3 className="text-xl font-semibold text-yellow-400">{result.question}</h3>

                    {result.type === "text" ? (
                      <div className="mt-2 text-sm text-gray-300 p-4 bg-gray-900 rounded-lg">
                        {Object.entries(result.responses).map(([textResponse, count]) => (
                          <div key={textResponse} className="mb-2 p-3 bg-gray-800 rounded-lg">
                            <p className="text-white">{textResponse}</p>
                            <p className="text-xs text-gray-400">({count as number} responses)</p>
                          </div>
                        ))}
                      </div>
                    ) : (
                      <Bar data={{
                        labels: Object.keys(result.responses || {}),
                        datasets: [{ data: Object.values(result.responses || {}), backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"] }]
                      }} options={{ responsive: true, indexAxis: "y", plugins: { legend: { display: false } } }} />
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-400">No survey data available.</p>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AdminSurveyDashboard;
