import React, { useEffect, useRef, useState } from "react";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { z } from "zod"; // Form validation
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import OTPInput from "react-otp-input";
import Swal from "sweetalert2";

const backendUrl = process.env.REACT_APP_API_URL;

const signupSchema = z
  .object({
    email: z.string().email("Invalid email address").optional(),
    // mobile: z
    //   .string()
    //   .regex(/^\d{10}$/, "Mobile must be a valid 10-digit number")
    //   .optional(),
    username: z.string().min(3, "Username must be at least 3 characters"),
    password: z
      .string()
      .min(6, "Password must be at least 6 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/\d/, "Password must contain at least one number"),
    confirmPassword: z.string(),
  })
  .refine((data) => data.password === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  });

interface SignupFormProps {
  onOtpVerified: () => void;
}

const SignupForm: React.FC<SignupFormProps> = ({ onOtpVerified }) => {
  const { languages, fetchLanguages } = useUser();
  const navigate = useNavigate();
  const [isOtpStep, setIsOtpStep] = useState(false);
  const [otp, setOtp] = useState("");
  const [sentMessage, setSentMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [usernameValid, setUsernameValid] = useState(false);

  const {
    register,
    handleSubmit,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(signupSchema),
  });

  const password = watch("password", "");
  const confirmPassword = watch("confirmPassword", "");
  const username = watch("username", "");
  const [passwordConditions, setPasswordConditions] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });
  useEffect(() => {
    let newConditions = {
      length: password.length >= 6,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /\d/.test(password),
    };
    setPasswordConditions(newConditions)
  }, [password]);
  useEffect(() => {
    setUsernameValid(username.length >= 3);
  }, [username]);

  const allConditionsMet = Object.values(passwordConditions).every(Boolean);


  const onSignupSubmit = async (data: any) => {
    setLoading(true);
    try {
      const response = await fetch(`${backendUrl}/auth/signup/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: data.email || null,
          // mobile: data.mobile || null,
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setSentMessage(result.message);
        setIsOtpStep(true);
        setOtp("");
        Swal.fire({
          title: "OTP sent Successfully! ",
          html: `<p class="text-blue-800"> ${result.message} <br/> If you haven't received OTP, Check the entered emailId again</p>`,
          // icon: "success",
          imageUrl: "https://media1.tenor.com/m/ofmvSb5_TxIAAAAd/snoopyinmailbox-kiss.gif",
          imageWidth: 250, // ✅ Adjust size
          imageHeight: 180,
          // timer: 5000, // Auto-close after 2 seconds
          showConfirmButton: false,
          showCancelButton: true,
          position: "center",
          cancelButtonText: "❌", // ✅ Custom text for the Cancel button
          cancelButtonColor: "transparent",
          customClass: {
            popup: "bg-white shadow-lg rounded-lg p-6 text-gray-800",
            title: "text-xl font-semibold",
            cancelButton: "absolute top-2 right-2 text-white px-4 py-2 rounded-md "
          },
        });
      } else {
        setError("root", { message: result.message || "Signup failed" });
      }
    } catch (error) {
      console.error("Error during signup:", error);
      setError("root", { message: "Network error. Please try again." });
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  
  const handleOtpPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text").trim();

    if (/^\d{6}$/.test(pastedData)) { // Ensure exactly 6 numeric digits
      setOtp(pastedData); // Set OTP
    }
  };
  

  const onOtpSubmit = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${backendUrl}/auth/signup/verifyOtp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: watch("email") || null,
          // mobile: watch("mobile") || null,
          otp,
          password: watch("password"),
          firstName: watch("username"),
        }),
      });

      const result = await response.json();
      if (response.ok) {
        setSuccessMessage("👏 Great job!  Your path to mastery starts here.");
        setTimeout(() => {
          setSuccessMessage("");
          onOtpVerified();
        }, 5000);
      } else {
        setError("root", { message: result.message || "OTP verification failed" });
      }
    } catch (error) {
      console.error("Error during OTP verification:", error);
      setError("root", { message: "Network error. Please try again." });
    }
    setTimeout(() => {
      setLoading(false)
    }, 1000);
  };
  
  

  return (
    <>
    {loading && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-75 z-50">
        <div className="flex flex-col gap-6 w-full items-center justify-center">
          <div className="w-32 h-32 border-8 border-transparent text-blue-400 text-5xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
            <div className="w-24 h-24 border-8 border-transparent text-red-400 text-3xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
          </div>
        </div>
      </div>
    )}

      <div className="px-4 sm:px-7 md:px-8 relative">
        {successMessage && (
          <div className="fixed top-20 left-1/2 transform -translate-x-1/2 bg-white text-green-400 py-2 px-4 rounded-lg shadow-md transition-opacity duration-500">
            {successMessage}
          </div>
        )}

        {!isOtpStep && (
          <>
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-center">Sign Up</h2>
            {errors.root && <p className="text-red-600 text-center">{errors.root.message}</p>}
            <form onSubmit={handleSubmit(onSignupSubmit)}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  {...register("email")}
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.email && <p className="text-red-600">{errors.email.message}</p>}
              </div>

              {/* <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Mobile</label>
                <input
                  type="text"
                  {...register("mobile")}
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                {errors.mobile && <p className="text-red-600">{errors.mobile.message}</p>}
              </div> */}

              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Username</label>
                <input
                  type="text"
                  {...register("username")}
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                {!usernameValid && <p className="text-red-600 text-xs">Username must be at least 3 characters</p>}
                {/* {errors.username && <p className="text-red-600">{errors.username.message}</p>} */}
              </div>

              <div className="mb-4 relative">
                <label className="block text-sm font-medium text-gray-700">Password</label>
                <input
                  type={showPassword ? "text" : "password"}
                  {...register("password")}
                  // onBlur={() => setPasswordTouched(true)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500 px-2 py-1"
                />
                 {!allConditionsMet && (
                  <ul className="text-xs text-gray-600 mt-1">
                    {!passwordConditions.length && <li className="text-red-600">At least 6 characters</li>}
                    {!passwordConditions.uppercase && <li className="text-red-600">One uppercase letter</li>}
                    {!passwordConditions.lowercase && <li className="text-red-600">One lowercase letter</li>}
                    {!passwordConditions.number && <li className="text-red-600">One number</li>}
                  </ul>
                )}
                <span
                  className="absolute top-6 right-1 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? "👁️" : "😵"}
                </span>
                {/* {errors.password && <p className="text-red-600">{errors.password.message}</p>} */}
              </div>

              <div className="mb-4 relative">
                <label className="block text-sm font-medium text-gray-700">Confirm Password</label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  {...register("confirmPassword")}
                  onBlur={() => setConfirmPasswordTouched(true)}
                  className="mt-1 block w-full px-2 py-1 rounded-md border-gray-300 shadow-sm focus:ring-blue-500 focus:border-blue-500"
                />
                <span
                  className="absolute top-6 right-1 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? "👁️" : "😵"}
                </span>
                {confirmPasswordTouched && confirmPassword !== password && (
                  <p className="text-red-600">Passwords do not match</p>
                )}
                {/* {errors.confirmPassword && <p className="text-red-600">{errors.confirmPassword.message}</p>} */}
              </div>

              <button type="submit" className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
                Get OTP
              </button>
            </form>
          </>
        )}

        {isOtpStep && (
          <div className="p-6">
            <h3 className="text-lg font-bold mb-4 text-center">Verify OTP</h3>
            <div className="flex justify-center mb-2">
            <OTPInput
                value={otp}
                onChange={setOtp}
                numInputs={6} // Adjust based on OTP length
                renderInput={(props) => <input {...props} onPaste={handleOtpPaste}/>}
                inputStyle={{
                  width: "3rem",
                  height: "3rem",
                  fontSize: "1.5rem",
                  borderRadius: "0.5rem",
                  border: "2px solid #2563eb",
                  textAlign: "center",
                  marginRight:"5px"
                }}
              />
            </div>

            <button onClick={onOtpSubmit} className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700">
              Verify OTP
            </button>
            <p className="mt-4 text-center text-sm text-gray-600">
              Entered the wrong email?{" "}
              <button
                onClick={() => setIsOtpStep(false)}
                className="text-blue-600 hover:underline"
              >
                Go back to Sign Up
              </button>
            </p>
          </div>
        )}
      </div>
    </>

  );
};

export default SignupForm;
