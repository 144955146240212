import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { fetchSurveyById } from "../../services/surveyService"; // API call
import { FaArrowLeft } from "react-icons/fa";
import { submitSurveyResponses } from "../../services/surveyService"; // ✅ Import the submission function

interface Question {
  id: string;
  text: string;
  type: "text" | "multiple-choice" | "single-choice" | "rating-scale";
  options?: string[]; // Only for choice-based questions
}

const SurveyDetail: React.FC = () => {
  const { id } = useParams(); // Get survey ID from URL
  const navigate = useNavigate();
  const [survey, setSurvey] = useState<{ title: string; description: string; questions: Question[], submissionStatus:boolean } | null>(null);
  const [loading, setLoading] = useState(true);
  const [responses, setResponses] = useState<{ [key: string]: string | string[] }>({});

  useEffect(() => {
    const loadSurvey = async () => {
      try {
        const surveyData = await fetchSurveyById(id);
        setSurvey({
          title: surveyData?.title || "", 
          description: surveyData?.description || "", 
          questions: surveyData?.questions || [], 
          submissionStatus: surveyData?.statusSubmitted || false
        });
        console.log('submitted',surveyData?.statusSubmitted)
        
      } catch (error) {
        console.error("❌ Error fetching survey:", error);
      } finally {
        setLoading(false);
      }
    };

    loadSurvey();
  }, [id]);

  // Handle user responses
  const handleResponseChange = (questionIndex: string, answer: string) => {
    setResponses((prev) => ({
      ...prev,
      [questionIndex]: answer,
    }));
  };

  const handleCheckboxChange = (questionId: string, option: string) => {
    setResponses((prev) => {
      // Ensure the current response for this question is always an array
      const existing = Array.isArray(prev[questionId]) ? prev[questionId] as string[] : [];
  
      return {
        ...prev,
        [questionId]: existing.includes(option)
          ? existing.filter((opt) => opt !== option) // Remove if already selected
          : [...existing, option], // Add if not selected
      };
    });
  };
  

// Submit Survey (Send to Backend)
const handleSubmit = async () => {
  if (!survey || !id) return;

  // ✅ Check if all questions are answered
  if (Object.keys(responses).length !== survey.questions.length) {
    alert("❌ Please answer all questions before submitting.");
    return;
  }

  // ✅ Correctly format responses
  const formattedResponses: { [key: string]: string | string[] } = {};

  Object.keys(responses).forEach((key) => {
    const value = responses[key];
    formattedResponses[key] = Array.isArray(value) ? [...value] : value;
  });

  console.log("🚀 Submitting Responses:", formattedResponses);

  // ✅ Send formatted data to backend
  const response = await submitSurveyResponses(id, formattedResponses);
  console.log("✅ Submission Status:", response?.submitted);

  if (response?.submitted) {
    alert("🎉 Survey submitted successfully!");

    // ✅ Store in local state to reflect immediately
    setSurvey((prevSurvey) =>
      prevSurvey ? { ...prevSurvey, submitted: true } : null
    );

    navigate("/beta"); // ✅ Redirect back to Beta page
  } else {
    alert("❌ Failed to submit survey. Please try again.");
  }
};



  

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-gradient-to-br from-[#21717b] to-black px-6 py-20">
      <div className="bg-white/10 backdrop-blur-lg shadow-xl rounded-2xl p-8 w-full max-w-4xl border border-white/20">
        <button onClick={() => navigate("/beta")} className="flex items-center text-white text-lg mb-4 hover:text-gray-300 transition-all">
          <FaArrowLeft className="mr-2" /> Back to Beta Hub
        </button>

        {loading ? (
          <p className="text-gray-400 text-lg text-center">Loading survey...</p>
        ) : survey ? (
          <>
            <h1 className="text-3xl font-bold text-white text-center mb-4">{survey.title}</h1>
            <p className="text-gray-300 text-lg text-center mb-6">{survey.description}</p>

            <form className="bg-white/10 p-6 rounded-xl border border-white/20 shadow-md w-full">
              <h2 className="text-xl text-white font-semibold mb-3">📝 Answer the Questions</h2>
              <div className="space-y-4">
                {survey.questions.map((question, index) => (
                  <div key={question.id} className="bg-white/10 p-4 rounded-lg border border-white/20">
                    <label className="text-white text-lg block mb-2">{question.text}</label>
                    {question.type === "text" && (
                      <input
                        type="text"
                        className="w-full bg-gray-800 text-white px-4 py-2 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={responses[question.id] || ""}
                        onChange={(e) => handleResponseChange(question.id, e.target.value)}
                        placeholder="Type your answer..."
                      />
                    )}

                    {question.type === "single-choice" && question.options && (
                        <div className="space-y-2">
                          {question.options.map((option, index) => (
                            <label key={index} className="flex items-center space-x-2 text-white">
                              <input
                                type="radio"
                                name={`single-choice-${question.id}`}
                                value={option}
                                checked={responses[question.id] === option}
                                onChange={() => handleResponseChange(question.id, option)}
                                className="w-5 h-5 text-blue-500"
                              />
                              <span>{option}</span>
                            </label>
                          ))}
                        </div>
                    )}

                    {question.type === "multiple-choice" && question.options && (
                      <div className="space-y-2">
                        {question.options.map((option, index) => (
                          <label key={index} className="flex items-center space-x-2 text-white">
                            <input
                              type="checkbox"
                              value={option}
                              checked={(Array.isArray(responses[question.id]) ? responses[question.id] as string[] : []).includes(option)}
                              onChange={() => handleCheckboxChange(question.id, option)}
                              className="w-5 h-5 text-blue-500"
                            />
                            <span>{option}</span>
                          </label>
                        ))}
                      </div>
                    )}

                    {question.type === "rating-scale" && (
                      <input type="range" min="1" max="5"
                        value={responses[question.id] || "3"}
                        onChange={(e) => handleResponseChange(question.id, e.target.value)}
                        className="w-full mt-2"
                      />
                    )}

                    </div>
                  ))}
              </div>

              <button
                type="button"
                onClick={handleSubmit}
                className="mt-6 bg-green-500 hover:bg-green-600 text-white text-lg font-medium py-3 px-6 rounded-lg shadow-md w-full"
              >
                Submit Survey
              </button>
            </form>
          </>
        ) : (
          <p className="text-gray-400 text-lg text-center">Survey not found.</p>
        )}
      </div>
    </div>
  );
};

export default SurveyDetail;
