import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

/**
 * WelcomeScreen Component
 * - Displays a one-time welcome message to the user.
 * - Handles API update to mark welcome screen as seen.
 * - Smooth transition to the platform page.
 */
const WelcomeScreen = () => {
  const [isVisible, setIsVisible] = useState(true);
  const navigate = useNavigate();

  /**
   * Handles the transition when "Start Exploring" is clicked.
   * - Updates the backend flag for hasSeenWelcomeScreen.
   * - Stores the flag locally to prevent future displays.
   * - Smoothly transitions to the platform page.
   */
  const handleStartExploring = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/update-welcome`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ hasSeenWelcomeScreen: true }),
      });

      if (!response.ok) {
        console.error("Failed to update welcome screen status.");
        return;
      }

      localStorage.setItem("hasSeenWelcomeScreen", "true");

      // Animate fade-out before navigating
      setIsVisible(false);
      setTimeout(() => navigate("/platform"), 500);
    } catch (error) {
      console.error("Error updating welcome screen status:", error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="fixed inset-0 flex items-center justify-center bg-gradient-to-b from-black via-gray-900 to-gray-800 text-white"
    >
      <div className="text-center max-w-lg p-8">
        <motion.h1
          className="text-4xl font-bold mb-6"
          initial={{ y: -20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Welcome to Totle
        </motion.h1>
        <motion.p
          className="text-lg mb-8"
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          Discover a new way of learning with personalized one-on-one experiences.
        </motion.p>
        <motion.button
          onClick={handleStartExploring}
          className="px-6 py-3 bg-blue-500 hover:bg-blue-600 rounded-2xl text-lg font-semibold transition-all shadow-lg"
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          Start Exploring
        </motion.button>
      </div>
    </motion.div>
  );
};

export default WelcomeScreen;
