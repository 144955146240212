import React, { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { FaArrowRight, FaArrowDown } from "react-icons/fa";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Import Images
import marketPlaceImg from "../../images/marketplace.jpg";
import teachImg from "../../images/teacher.jpg";
import learnImg from "../../images/learn.jpg";
import endeavourImg from "../../images/endeavour.jpg";

// Register GSAP Plugin
gsap.registerPlugin(ScrollTrigger);

const platforms = [
  {
    title: "Marketplace",
    description:
      "Explore trending topics, analytics, and available educators. Find a vibrant marketplace of educational tools and resources.",
    image: marketPlaceImg,
    link: "/marketplace",
    buttonText: "Enter Marketplace",
  },
  {
    title: "Teacher Platform",
    description:
      "Join or explore the Teachers Platform for educators of all kinds. Connect with peers, access resources, and expand your knowledge base.",
    image: teachImg,
    link: "/teach",
    buttonText: "Enter Teacher Platform",
  },
  {
    title: "Learner Platform",
    description:
      "Your personalized learning dashboard and sessions. Access tailored resources, track progress, and achieve your learning goals.",
    image: learnImg,
    link: "/learn",
    buttonText: "Enter Learner Platform",
  },
  {
    title: "Endeavour Platform",
    description:
      "Participate in competitions, portfolio building, and projects. Showcase your skills, collaborate with others, and grow professionally.",
    image: endeavourImg,
    link: "/endeavour",
    buttonText: "Enter Endeavour Platform",
  },
];

const PlatformPage: React.FC = () => {
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sections = gsap.utils.toArray(".platform-section");

    sections.forEach((section: any, index) => {
      gsap.fromTo(
        section,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: section,
            start: "top 75%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  const handleNavigation = (link: string) => {
    navigate(link);
    window.scrollTo(0, 0);
  };

  const scrollToNext = (index: number) => {
    const sections = document.querySelectorAll(".platform-section");
    if (sections[index + 1]) {
      sections[index + 1].scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div ref={containerRef} className="bg-[#21717b] text-white min-h-screen">
      {platforms.map(({ title, description, image, link, buttonText }, index) => (
        <section
          key={title}
          className="platform-section relative min-h-screen flex flex-col justify-center items-center text-center px-6"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black bg-opacity-50"></div>

          {/* Content */}
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, ease: "easeOut", delay: index * 0.3 }}
            className="relative z-10 max-w-2xl"
          >
            <h1 className="text-5xl md:text-6xl font-extrabold text-white drop-shadow-lg">
              {title}
            </h1>
            <p className="mt-4 text-lg text-gray-200">{description}</p>

            {/* Animated Button */}
            <motion.button
              onClick={() => handleNavigation(link)}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              className="mt-6 flex items-center px-6 py-3 rounded-lg bg-orange-500 text-white font-semibold text-lg tracking-wide hover:bg-orange-600 transition-all"
            >
              {buttonText} <FaArrowRight className="ml-3" />
            </motion.button>
          </motion.div>

          {/* Scroll Down Indicator (First Section Only) */}
          {index === 0 && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ repeat: Infinity, repeatType: "reverse", duration: 1 }}
              className="absolute bottom-6 left-1/2 transform -translate-x-1/2 text-white"
            >
              <p className="text-sm">Scroll Down</p>
              <FaArrowDown className="text-2xl animate-bounce" />
            </motion.div>
          )}

          {/* Gradient Fade for Next Section Preview */}
          {index < platforms.length - 1 && (
            <div className="absolute bottom-0 left-0 w-full h-20 bg-gradient-to-t from-black/50 to-transparent"></div>
          )}

          {/* "Next Platform" Scroll Button */}
          {index < platforms.length - 1 && (
            <button
              onClick={() => scrollToNext(index)}
              className="absolute bottom-6 left-1/2 transform -translate-x-1/2 bg-white text-black px-4 py-2 rounded-md hover:bg-gray-200 transition-all"
            >
              Next Platform ↓
            </button>
          )}
        </section>
      ))}
    </div>
  );
};

export default PlatformPage;
