import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { FaPlus, FaTrash, FaGripLines } from "react-icons/fa";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { NavLink } from "react-router-dom/dist";
import { useAdminAuth } from "../../context/AdminAuthContext";
import { fetchAdminSurveys, deleteSurvey, updateSurvey } from "../../services/surveyService";
import { useRef } from "react"; // ✅ Import useRef

// Define question types
interface Question {
  id: string;
  text: string;
  type: "text" | "multiple-choice" | "single-choice";
  options?: string[];
}

const backendUrl = process.env.REACT_APP_API_URL;

const AdminSurveyBuilder: React.FC = () => {
  const [surveyTitle, setSurveyTitle] = useState("");
  const [questions, setQuestions] = useState<Question[]>([]);
  const [loading, setLoading] = useState(false);
  const [existingSurveys, setExistingSurveys] = useState<{ id: string; title: string }[]>([]);
  const [editingSurveyId, setEditingSurveyId] = useState<string | null>(null);
  // const [loading, setLoading] = useState(false); // ✅ Loader for form actions
  const [loadingSurveys, setLoadingSurveys] = useState(false); // ✅ Loader for fetching surveys
  const [loadingSurveyData, setLoadingSurveyData] = useState(false); // ✅ Loader for fetching a single survey
  const [success, setSuccess] = useState(false); // ✅ Track success state
  const [deletingSurveyId, setDeletingSurveyId] = useState<string | null>(null);
  const optionRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});
  const titleRef = useRef<HTMLInputElement | null>(null);
  const questionRefs = useRef<{ [key: string]: HTMLInputElement | null }>({});



  useEffect(() => {
    titleRef.current?.focus();
    fetchSurveys(); // ✅ Fetch surveys when component loads
  }, []);
  

  // ✅ Add a new question dynamically
  const addQuestion = (type: Question["type"]) => {
    const newQuestion: Question = {
      id: uuidv4(),
      text: "",
      type,
      options: type === "multiple-choice" || type === "single-choice" ? [""] : undefined,
    };
    setQuestions([...questions, newQuestion]);
    setTimeout(() => {
      questionRefs.current[newQuestion.id]?.focus();
    }, 100);
  };

  // ✅ Update question text
  const updateQuestionText = (id: string, text: string) => {
    setQuestions(questions.map((q) => (q.id === id ? { ...q, text } : q)));
  };

  // ✅ Update option text
  const updateOption = (questionId: string, optionIndex: number, text: string) => {
    setQuestions(
      questions.map((q) =>
        q.id === questionId && q.options
          ? { ...q, options: q.options.map((opt, index) => (index === optionIndex ? text : opt)) }
          : q
      )
    );
  };

  // ✅ Add an option to MCQ or Single Choice questions
  const addOption = (id: string) => {
    setQuestions((prevQuestions) =>
      prevQuestions.map((q) =>
        q.id === id && q.options
          ? { ...q, options: [...q.options, ""] }
          : q
      )
    );
  
    // ✅ Focus the newly added input field after it renders
    setTimeout(() => {
      const optionsLength = questions.find((q) => q.id === id)?.options?.length || 0;
      optionRefs.current[`${id}-${optionsLength}`]?.focus();
    }, 100);
  };

  // ✅ Remove an option from MCQ or Single Choice questions
  const removeOption = (questionId: string, optionIndex: number) => {
    setQuestions(
      questions.map((q) =>
        q.id === questionId && q.options
          ? { ...q, options: q.options.filter((_, index) => index !== optionIndex) }
          : q
      )
    );
  };

  // ✅ Delete a question
  const deleteQuestion = (id: string) => {
    setQuestions(questions => questions.filter((q) => q.id !== id));
  };

  const handleEditSurvey = async (surveyId: string) => {
    setLoadingSurveyData(true);
    try {
      const response = await axios.get(`${backendUrl}/admin/surveys/questions/${surveyId}`);
      const survey = response.data;
  
      console.log("Survey Data:", survey); // ✅ Debugging log to check response
  
      setEditingSurveyId(surveyId);
      setSurveyTitle(survey.title||"");
      setQuestions(Array.isArray(survey.questions) ? survey.questions : []); // ✅ Ensure questions is always an array
    } catch (error) {
      console.error("❌ Error loading survey for editing:", error);
      setQuestions([]); // ✅ Prevent undefined errors by setting an empty array
    } finally {
      setLoadingSurveyData(false); // ✅ Hide loader when done
    }

  };
  const { admin } = useAdminAuth(); // Assuming it contains admin info


  const handleReset = () => {
    setSurveyTitle(""); // ✅ Clear the survey title
    setQuestions([]);   // ✅ Clear the questions list
    setEditingSurveyId(null); // ✅ Exit edit mode
    setTimeout(() => {
      titleRef.current?.focus(); // ✅ Focus on title input safely
    }, 100);
  };
  const fetchSurveys = async () => {
    try {
      setLoadingSurveys(true); // ✅ Show loader while fetching
      const surveys = await fetchAdminSurveys();
      setExistingSurveys(surveys);
    } catch (error) {
      console.error("❌ Error loading surveys:", error);
    } finally {
      setLoadingSurveys(false); // ✅ Hide loader when done
    }
  };
  
  
  // ✅ Submit survey data
  const handleSubmit = async () => {
    if (!surveyTitle.trim() || questions.length === 0) {
      console.error("Survey title and questions are required");
      return;
    }
    
    setSuccess(false);
    setLoading(true);
    try {
      console.log('admin', admin)
      const adminId = admin?.id || null;
  
      if (!adminId) {
        console.error("❌ Admin ID is missing");
        alert("Admin ID is missing. Please log in again.");
        return;
      }
      const payload = {
        adminId,
        title: surveyTitle,
        questions: questions.map((q) => ({
          text: q.text,
          type: q.type,
          options: q.options || [],
        })),
      };

      let successResponse  = false;
      
      if (editingSurveyId) {
        successResponse  = await updateSurvey(editingSurveyId, payload);
      } else {
        successResponse  = await axios.post(`${backendUrl}/admin/surveys`, payload);
      }

      if (successResponse ) {
        setSurveyTitle("");
        setQuestions([]);
        setEditingSurveyId(null);
        setSuccess(true);
        fetchSurveys();
        alert(editingSurveyId ? "✅ Survey updated!" : "✅ Survey created!");
        setTimeout(() => setSuccess(false), 3000);
      } else {
        alert("❌ Failed to save survey.");
      }
    } catch (error: any) {
      console.error("❌ Error saving survey:", error.response?.data || error.message);
    } finally {
      setLoading(false);
    }
  };
  const { logoutAdmin } = useAdminAuth();
  const handleDeleteSurvey = async (surveyId: string) => {
    if (!window.confirm("Are you sure you want to delete this survey?")) return; // ✅ Confirmation before deleting
    setDeletingSurveyId(surveyId);
    try {
      await deleteSurvey(surveyId); // ✅ Call the delete function from `surveyService`
      setSurveyTitle("");
      setQuestions([]);
      setEditingSurveyId(null);

      fetchSurveys(); // ✅ Refresh the list after deletion
    } catch (error) {
      console.error("❌ Error deleting survey:", error);
    }finally {
      setDeletingSurveyId(null); // ✅ Hide loader after deletion
    }
  };
  

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="min-h-svh bg-black text-white p-8">
        <div className="flex justify-between items-center mb-4">
          <motion.h1
            className="text-4xl font-bold text-red-500 "
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
          >
            🛠️ Admin Survey Builder
          </motion.h1>
          <div className="flex space-x-4">
            <NavLink
              to="/admin/dashboard"
              className="bg-yellow-400 text-black px-4 py-2 rounded-md font-semibold hover:bg-yellow-300 transition"
            >
              🔙 Back to Dashboard
            </NavLink>
            <button
              onClick={logoutAdmin}
              className="bg-red-600 text-white px-4 py-2 rounded-md font-semibold hover:bg-red-500 transition"
            >
              🚪 Logout
            </button>
          </div>
        </div>

        {/* Survey Title */}
        <input
         ref={titleRef}
          type="text"
          placeholder="Survey Title"
          value={surveyTitle||""}
          onChange={(e) => setSurveyTitle(e.target.value)}
          className="w-full p-3 mb-4 text-black bg-gray-300 rounded-lg"
        />

        {/* Question List */}
        <div className="space-y-4">
          {questions.map((q, index) => (
            <div key={q.id} className="bg-gray-800 p-4 rounded-md border border-red-500">
              <input
                ref={(el) => {
                  if (el) questionRefs.current[q.id] = el; // ✅ Store ref
                }}
                type="text"
                placeholder="Enter question"
                value={q.text||""}
                onChange={(e) => updateQuestionText(q.id, e.target.value)}
                className="w-full p-2 mb-2 text-black bg-white rounded"
              />

              {/* Render MCQ/Single Choice options */}
              {(q.type === "multiple-choice" || q.type === "single-choice") && (
                <div>
                  {q.options?.map((option, optionIndex) => (
                    <div key={optionIndex} className="flex items-center space-x-2 mb-2">
                      <input
                        type={q.type === "multiple-choice" ? "checkbox" : "radio"}
                        disabled
                      />
                      <input
                      ref={(el) => {
                        if (el) optionRefs.current[`${q.id}-${optionIndex}`] = el; // ✅ Store ref
                      }}
                        type="text"
                        value={option||""}
                        onChange={(e) => updateOption(q.id, optionIndex, e.target.value)}
                        className="p-1 text-black rounded"
                      />
                      <button
                        onClick={() => removeOption(q.id, optionIndex)}
                        className="bg-red-500 px-2 rounded text-white"
                      >
                        ❌
                      </button>
                    </div>
                  ))}
                  <button
                    onClick={() => addOption(q.id)}
                    className="bg-blue-500 px-3 py-1 rounded text-white"
                  >
                    ➕ Add Option
                  </button>
                </div>
              )}

              <button onClick={() => deleteQuestion(q.id)} className="bg-red-600 px-3 py-1 mt-2 rounded">
                🗑️ Delete Question
              </button>
            </div>
          ))}
        </div>

        {/* Add Question Buttons */}
        <div className="mt-6 flex flex-wrap gap-4">
          <button onClick={() => addQuestion("text")} className="bg-green-600 px-4 py-2 rounded-md">+ Text</button>
          <button onClick={() => addQuestion("single-choice")} className="bg-blue-600 px-4 py-2 rounded-md">+ Single Choice</button>
          <button onClick={() => addQuestion("multiple-choice")} className="bg-yellow-600 px-4 py-2 rounded-md">+ Multiple Choice</button>
          <button onClick={handleReset} className="bg-gray-500 px-4 py-2 rounded-md text-white font-bold hover:bg-gray-400 transition">🔄 Reset Form</button>
        </div>

        {/* Submit Button */}
        {/* Loader for Saving or Fetching Survey Data */}
        {loading || loadingSurveyData ? (
          <div className="flex-col gap-4 w-full flex items-center justify-center">
            <div className="w-20 h-20 border-4 border-transparent text-blue-400 text-4xl animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
              <div className="w-16 h-16 border-4 border-transparent text-red-400 text-2xl animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
            </div>
          </div>
        ) : (
          <>
            {/* Submit Button */}
            <button onClick={handleSubmit} disabled={loading}
              className={`w-full mt-6 py-3 text-white font-bold rounded-md transition ${
                success ? "bg-green-600 hover:bg-green-500" : "bg-red-700 hover:bg-red-600"
              }`}
            >
              {loading ? "Saving..." : success ? "✅ Saved!" : "Save Survey"}
            </button>
          </>
        )}

        {/* Existing Surveys List */}
        <div className="mt-8 bg-gray-900 p-4">
          <h2 className="text-xl font-semibold text-white">📋 Existing Surveys</h2>
          {loadingSurveys ? (
            <div className="flex justify-center py-4">
              <div className="w-12 h-12 border-4 border-transparent text-blue-400 animate-spin flex items-center justify-center border-t-blue-400 rounded-full">
                <div className="w-10 h-10 border-4 border-transparent text-red-400 animate-spin flex items-center justify-center border-t-red-400 rounded-full"></div>
              </div>
            </div>
          ) : existingSurveys.length === 0 ? (
            <p className="text-gray-400">No surveys created yet.</p>
          ) : (
            <ul>
              {existingSurveys.map((survey) => (
                <li
                  key={survey.id}
                  className="flex justify-between items-center  border my-2 text-xl border-gray-200 text-white p-2 hover:underline rounded-md hover:bg-blue-400"
                  
                >
                  <span className="cursor-pointer" onClick={() => handleEditSurvey(survey.id)}>

                  {survey.title}
                  </span>
                  <button onClick={() => handleDeleteSurvey(survey.id)}
                    className="group relative flex h-14 w-14 flex-col items-center justify-center overflow-hidden rounded-xl border-2 border-red-800 bg-red-400 hover:bg-red-600 transition-all"
                  >
                  {/* Rotating Trash Animation */}
                  <svg
                    viewBox="0 0 1.625 1.625"
                    className="absolute -top-7 fill-white delay-100 group-hover:top-6 group-hover:animate-spin group-hover:duration-1000"
                    height="15"
                    width="15"
                  >
                    <path d="M.471 1.024v-.52a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099h-.39c-.107 0-.195 0-.195-.195" />
                    <path d="M1.219.601h-.163A.1.1 0 0 1 .959.504V.341A.033.033 0 0 0 .926.309h-.26a.1.1 0 0 0-.098.098v.618c0 .054.044.098.098.098h.487a.1.1 0 0 0 .098-.099v-.39a.033.033 0 0 0-.032-.033" />
                    <path d="m1.245.465-.15-.15a.02.02 0 0 0-.016-.006.023.023 0 0 0-.023.022v.108c0 .036.029.065.065.065h.107a.023.023 0 0 0 .023-.023.02.02 0 0 0-.007-.016" />
                  </svg>

                  {/* Horizontal Line */}
                  <svg
                    width="16"
                    fill="none"
                    viewBox="0 0 39 7"
                    className="origin-right duration-500 group-hover:rotate-90"
                  >
                    <line strokeWidth="4" stroke="white" y2="5" x2="39" y1="5" />
                    <line strokeWidth="3" stroke="white" y2="1.5" x2="26.0357" y1="1.5" x1="12" />
                  </svg>

                  {/* Trash Bin Icon */}
                  <svg width="16" fill="none" viewBox="0 0 33 39">
                    <mask fill="white" id="path-1-inside-1_8_19">
                      <path d="M0 0H33V35C33 37.2091 31.2091 39 29 39H4C1.79086 39 0 37.2091 0 35V0Z" />
                    </mask>
                    <path mask="url(#path-1-inside-1_8_19)" fill="white" d="M0 0H33H0ZM37 35C37 39.4183 33.4183 43 29 43H4C-0.418278 43 -4 39.4183 -4 35H4H29H37ZM4 43C-0.418278 43 -4 39.4183 -4 35V0H4V35V43ZM37 0V35C37 39.4183 33.4183 43 29 43V35V0H37Z" />
                    <path strokeWidth="4" stroke="white" d="M12 6L12 29" />
                    <path strokeWidth="4" stroke="white" d="M21 6V29" />
                  </svg>
                </button>
                </li>
                
              ))}
            </ul>
          )}
        </div>

      </div>

    </DndProvider>
  );
};

export default AdminSurveyBuilder;