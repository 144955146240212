import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

interface User {
  id: number;
  firstName: string;  // ✅ Added
  lastName?: string;  // ✅ Added (Optional)
  email: string;
  dob: string;
  gender: string;
  known_language_ids: number[];
  preferred_language_id: number;
  // educational_qualifications: Array<string>;
  // status: "Student" | "Working" | "Hybrid";
  // currentOccupation?: string;
  // skills?: string[];
  // years_of_experience?: number;
  location: string;
}


interface AuthContextType {
  isLoggedIn: boolean;
  user: User | null;
  loading: boolean;
  login: (token: string, userData: User) => void;
  logout: () => void;
  setUser: (user: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const backendUrl = process.env.REACT_APP_API_URL;;

  useEffect(() => {
    const checkAuthStatus = async () => {
      const token = localStorage.getItem("userToken");
      if (!token) {
        // console.log("No token found, logging out.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${backendUrl}/auth/user`, {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        });

        const data = await response.json();
        // console.log('data', data)
        if (data.user) {
          // ✅ Ensure `roles` exists, fallback to empty object
          // const userWithRoles: User = {
          //   ...data.user,
          //   roles: data.user.roles || {},
          // };

          setUser(data.user);
          setIsLoggedIn(true);
        } else {
          // console.log("Invalid token, logging out.");
          logout();
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
        logout();
      }
      setLoading(false);
    };

    checkAuthStatus();
  }, []);

  const login = (token: string,userData: User) => {
    localStorage.setItem("userToken", token);
    localStorage.setItem("isLoggedIn", "true");
    setUser(userData);
    setIsLoggedIn(true);
  };

  const logout = async () => {
    const token = localStorage.getItem("userToken");
    if (!token) return;
    try {
      await fetch(` ${process.env.REACT_APP_API_URL}/auth/logout`, {
        method: "POST",
        headers: {
          "Authorization": `Bearer ${token}`,
        },
      });
  
      localStorage.removeItem("userToken");
      setIsLoggedIn(false);
      setUser(null);
      navigate("/auth");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  

  return (
    <AuthContext.Provider value={{ isLoggedIn, user, loading, login, logout, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export type { User }; // ✅ Now `User` type can be used in other files
