import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import * as THREE from "three";
import { ForceGraph3D } from "react-force-graph";

const DashboardPage = () => {
  const [interest, setInterest] = useState("");
  const [trendingTopics] = useState([
    "AI in Education",
    "Python for Data Science",
    "Blockchain Security",
    "Machine Learning Trends",
    "Web3 & Decentralized Apps",
  ]);
  const [currentTopic, setCurrentTopic] = useState(trendingTopics[0]);
  const sceneRef = useRef<HTMLDivElement | null>(null);
  const [message, setMessage] = useState<{ text: string; type: "success" | "error" | null }>({ text: "", type: null });
  const [selectedMode, setSelectedMode] = useState<"teach" | "learn" | null>(null);

  useEffect(() => {
    const sceneContainer = sceneRef.current; // ✅ Store ref at the beginning
  
    if (!sceneContainer) return;
  
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true });
  
    renderer.setSize(window.innerWidth, window.innerHeight);
    sceneContainer.appendChild(renderer.domElement);
  
    // Setup Grid
    const gridHelper = new THREE.GridHelper(30, 60, 0x0055ff, 0x002266);
    scene.add(gridHelper);
  
    camera.position.z = 15;
    const animate = () => {
      requestAnimationFrame(animate);
      renderer.render(scene, camera);
    };
    animate();
  
    // ✅ Fixed Cleanup Function
    return () => {
      if (sceneContainer) {
        sceneContainer.innerHTML = "";
      }
    };
  }, []);
  

const handleSubmit = async () => {
  if (!interest.trim() || !selectedMode) {
    setMessage({ text: "Please enter a topic and select Teach or Learn.", type: "error" });
    return;
  }

  const modeText = selectedMode === "teach" ? " - I can teach" : " - I want to learn"; 
  const formattedInterest = interest + modeText;
 
  const token = localStorage.getItem("userToken");

  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/suggestion`,
      { interest: formattedInterest },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    setMessage({ text: "Interest submitted successfully!", type: "success" });
    setInterest(""); // Clear input after success
    setSelectedMode(null); // Reset selection
  } catch (error) {
    setMessage({ text: "Failed to submit interest. Try again.", type: "error" });
  }
  setTimeout(() => setMessage({ text: "", type: null }), 3000); // Auto-hide notification
};


  useEffect(() => {
    // Cycle through trending topics every 5 seconds
    const interval = setInterval(() => {
      setCurrentTopic((prev) => {
        const currentIndex = trendingTopics.indexOf(prev);
        return trendingTopics[(currentIndex + 1) % trendingTopics.length];
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [trendingTopics]);

  useEffect(() => {
    if (!sceneRef.current) return;

    // Initialize Three.js scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    const renderer = new THREE.WebGLRenderer({ alpha: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    sceneRef.current.appendChild(renderer.domElement);

    // ----------------------------------
    // 1) Elegant Grid
    // ----------------------------------
    const gridHelper = new THREE.GridHelper(30, 60, 0x0055ff, 0x002266);
    scene.add(gridHelper);

    // ----------------------------------
    // 2) Swirling Particle Field
    // ----------------------------------
    const particleCount = 500; // can increase if performance allows
    const radius = 10;         // overall spread of particles
    const positions = new Float32Array(particleCount * 3);

    // Assign initial random positions
    for (let i = 0; i < particleCount; i++) {
      const angle = Math.random() * Math.PI * 2;
      const r = Math.random() * radius;
      positions[i * 3 + 0] = Math.cos(angle) * r;
      positions[i * 3 + 1] = (Math.random() - 0.5) * 10; // vertical spread
      positions[i * 3 + 2] = Math.sin(angle) * r;
    }

    const particleGeometry = new THREE.BufferGeometry();
    particleGeometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );

    // Material with additive blending for neon glow
    const particleMaterial = new THREE.PointsMaterial({
      color: 0x66aaff,
      size: 0.3,
      transparent: true,
      opacity: 0.8,
      blending: THREE.AdditiveBlending,
      depthWrite: false,
    });

    const particleSystem = new THREE.Points(particleGeometry, particleMaterial);
    scene.add(particleSystem);

    // 3) Setup Camera
    camera.position.z = 15;

    // Animate function
    let clock = new THREE.Clock();

    const animate = () => {
      requestAnimationFrame(animate);
      const elapsed = clock.getElapsedTime();

      // Update swirl movement
      const positionsArray = particleGeometry.attributes.position.array as Float32Array;
      for (let i = 0; i < particleCount; i++) {
        const index = i * 3;

        // swirl logic: positions revolve around center with a subtle vertical wave
        const x = positionsArray[index + 0];
        const y = positionsArray[index + 1];
        const z = positionsArray[index + 2];

        // Convert x,z to polar
        const angle = Math.atan2(z, x);
        const dist = Math.sqrt(x * x + z * z);

        // swirl speed
        const swirlSpeed = 0.2;
        const newAngle = angle + swirlSpeed * 0.01;

        // Vertical wave factor
        const waveFreq = 0.8;
        const waveAmp = 0.5;
        const wave = Math.sin(elapsed * waveFreq + i) * waveAmp;

        // Recalculate new positions
        positionsArray[index + 0] = Math.cos(newAngle) * dist;
        positionsArray[index + 2] = Math.sin(newAngle) * dist;
        positionsArray[index + 1] = y + (wave * 0.01); // subtle wave
      }
      particleGeometry.attributes.position.needsUpdate = true;

      renderer.render(scene, camera);
    };
    animate();


    
  }, []);

  return (
    <div className="relative flex justify-center items-center min-h-screen bg-black text-white overflow-hidden">
      {/* Background Animation */}
      <div ref={sceneRef} className="absolute inset-0 opacity-40"></div>

      {/* 3D Force Graph Showing Data Flow */}
      <div className="absolute inset-0">
        <ForceGraph3D
          graphData={{
            nodes: Array.from({ length: 20 }, (_, i) => ({
              id: `Node ${i}`,
              group: Math.floor(Math.random() * 5),
            })),
            links: Array.from({ length: 30 }, () => ({
              source: `Node ${Math.floor(Math.random() * 20)}`,
              target: `Node ${Math.floor(Math.random() * 20)}`,
            })),
          }}
          nodeAutoColorBy="group"
          linkOpacity={0.3}
          backgroundColor="rgba(0, 0, 0, 0)"
        />
      </div>

      {/* Floating Live Data & Trends */}
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 2, ease: "easeOut" }}
        className="absolute top-16 text-lg text-blue-400 tracking-wide animate-pulse"
      >
        Trending Now: {currentTopic}
      </motion.div>

      {/* Glassmorphism Floating Input Box with Background Reactions */}
      <motion.div
  initial={{ y: -50, opacity: 0 }}
  animate={{ y: 0, opacity: 1 }}
  transition={{ duration: 1, ease: "easeOut" }}
  whileHover={{ scale: 1.05, backgroundColor: "rgba(0, 102, 255, 0.4)" }}
  className="relative bg-opacity-50 backdrop-blur-md text-white px-8 py-5 rounded-xl shadow-lg text-center border border-blue-400"
>
  <p className="mb-3 font-semibold text-lg">What is your interest?</p>
  
  {/* Input Box */}
  <input
    type="text"
    value={interest}
    onChange={(e) => setInterest(e.target.value)}
    placeholder="Enter a topic..."
    className="w-full p-3 rounded-md bg-black bg-opacity-50 text-white border border-blue-400 focus:outline-none"
  />

  {/* Teach or Learn Buttons */}
  <div className="flex justify-center gap-4 mt-3">
    <button
      onClick={() => setSelectedMode("teach")}
      className={`px-6 py-2 rounded-lg font-semibold transition ${
        selectedMode === "teach" ? "bg-blue-700 text-white" : "bg-blue-500 hover:bg-blue-700 text-white"
      }`}
    >
      Teach
    </button>

    <button
      onClick={() => setSelectedMode("learn")}
      className={`px-6 py-2 rounded-lg font-semibold transition ${
        selectedMode === "learn" ? "bg-green-700 text-white" : "bg-green-500 hover:bg-green-700 text-white"
      }`}
    >
      Learn
    </button>
  </div>

  {/* Submit Button */}
  <button
    onClick={handleSubmit}
    className="mt-3 px-6 py-2 bg-blue-500 hover:bg-blue-700 text-white font-semibold rounded-lg transition"
  >
    Submit
  </button>

  {/* Error or Success Message */}
  {message.text && (
    <div
      className={`mt-3 p-2 rounded-md text-sm font-semibold ${
        message.type === "success" ? "bg-green-500 text-white" : "bg-red-500 text-white"
      }`}
    >
      {message.text}
    </div>
  )}
</motion.div>


    </div>
  );
};

export default DashboardPage;
