import React, { useState } from "react";

interface TabsProps {
  defaultValue: string;
  children: React.ReactNode;
  className?: string; // ✅ Allow className
}

interface TabsContextType {
  selected: string;
  setSelected: (value: string) => void;
}

const TabsContext = React.createContext<TabsContextType | null>(null);

export const Tabs: React.FC<TabsProps> = ({ defaultValue, children, className }) => {
  const [selected, setSelected] = useState(defaultValue);
  return (
    <TabsContext.Provider value={{ selected, setSelected }}>
      <div className={`w-full ${className}`}>{children}</div> {/* ✅ Apply className */}
    </TabsContext.Provider>
  );
};

export const TabsList: React.FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => {
  return <div className={`flex bg-gray-700 p-2 rounded-lg mb-4 ${className}`}>{children}</div>; // ✅ Apply className
};

export const TabsTrigger: React.FC<{ 
  value: string; 
  children: React.ReactNode; 
  className?: string;
  onClick?: () => void; // ✅ Added onClick prop support
}> = ({ value, children, className, onClick }) => {
  const context = React.useContext(TabsContext);
  if (!context) throw new Error("TabsTrigger must be used within Tabs");

  return (
    <button
      className={`px-4 py-2 rounded-lg transition-all ${
        context.selected === value ? "bg-gray-900 text-white" : "text-gray-400 hover:bg-gray-800"
      } ${className}`}
      onClick={() => context.setSelected(value)}
    >
      {children}
    </button>
  );
};

export const TabsContent: React.FC<{ value: string; children: React.ReactNode; className?: string }> = ({
  value,
  children,
  className,
}) => {
  const context = React.useContext(TabsContext);
  if (!context) throw new Error("TabsContent must be used within Tabs");

  return context.selected === value ? <div className={className}>{children}</div> : null;
};
