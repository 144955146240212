import React from "react";
import { Routes, Route } from "react-router-dom";

import DashboardPage from "./DashboardPage";

const MarketplacePage: React.FC = () => {
  return (
    <div className="w-full h-screen bg-black text-white">
      {/* Full Screen Dashboard */}
      <Routes>
        <Route path="/" element={<DashboardPage />} />
      </Routes>
    </div>
  );
};

export default MarketplacePage;
