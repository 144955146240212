import React, { useState } from "react";
import axios from "axios";
import { NavLink, useNavigate } from "react-router-dom";
import {  toast } from "react-toastify"; // Import toast components
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare, FaWhatsapp } from 'react-icons/fa'; // Icons for social media
import Footer from '../Footer/Footer';

let backendUrl = process.env.REACT_APP_BACKEND_URL;
const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const navigate = useNavigate();
    const handleLinkClick = () => {
      navigate('/');
      window.scrollTo(0, 0);
    };

  // const handleLinkClick = ()=>{
  //   window.scrollTo(0, 0);
  // }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Here, you can send the form data to an API endpoint (e.g., via fetch or axios)
    try {
      const response = await axios.post(
        `${backendUrl}/auth/contactus`,
        formData
      );
      console.log(response.data);
      // setSubmitted(true);
      toast.success("Message sent successfully! We will get back to you soon.");
      setFormData({
        name: "",
        email: "",
        message: "",
      });
      setTimeout(() => {
        setSubmitted(false);
      }, 1000);
    } catch (error) {
      console.error("Error submitting the form", error);
      toast.error("Error sending the message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="pt-20 bg-gray-100">
      <div className="container mx-auto px-6">
        <div className="flex flex-col lg:flex-row h-full">
        {/* Left side - Full Height Image */}
          <div className="w-full lg:w-1/2 bg-cover rounded bg-center bg-no-repeat" style={{backgroundImage:"url('/images/contact.jpg')"}}>
          </div>

        {/* Right side - Text and Form */}
          <div className="w-full lg:w-1/2 flex flex-col justify-center px-6">
            <h2 className="text-4xl font-bold mb-2 text-center">Get in touch with us</h2>
            <div className="flex flex-col items-center text-center space-y-4">
  <p className="p-2">
    Connect with our customer support experts for assistance with any issues/questions you might have on:
  </p>

  {/* WhatsApp Button */}
  <a
    href="https://wa.me/919381281438"
    target="_blank"
    rel="noopener noreferrer"
    className="flex items-center justify-center gap-2 text-green-500 font-semibold text-lg border border-green-500 px-4 py-2 rounded-lg hover:bg-green-500 hover:text-white transition"
  >
    <FaWhatsapp size={24} /> Chat on WhatsApp
  </a>

  <p className="text-gray-400 font-medium">Or</p>

  <p>Drop us a mail below</p>
</div>


            {/* Display success message after form submission */}
            {submitted ? (
              <div className="bg-green-500 text-white p-4 rounded-md">
                <p>We received your message, Our team will get in touch with you shortly!</p>
              </div>
            ) : (
              <form
                onSubmit={handleSubmit}
                className="bg-white p-6 pb-3 mb-8 rounded-lg shadow-lg "
              >
                <div className="mb-4">
                  <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={formData.name}
                    onChange={handleChange}
                    className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    value={formData.email}
                    onChange={handleChange}
                    className="w-full p-2 rounded-lg border border-gray-300 focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>

                <div className="mb-3">
                  <label htmlFor="message" className="block text-gray-700 font-semibold mb-2">
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    value={formData.message}
                    onChange={handleChange}
                    rows="4"
                    className="w-full p-2 rounded-lg resize-none overflow-y-auto border border-gray-300 focus:outline-none focus:border-blue-500"
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  disabled={loading}
                  className="bg-orange-500 text-white py-2 px-4 rounded-lg hover:bg-orange-600 transition"
                >
                  {loading ? "Submitting..." : "Submit"}
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
      {/* Footer */}
      <Footer handleLinkClick={handleLinkClick}/>

    </section>
  );
};

export default Contact;