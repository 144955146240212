import axios from 'axios';
import React, { createContext, useState, useContext } from 'react';

// Create UserContext
interface UserContextType {
  isLoggedIn: boolean;
  login: () => void;
  logout: () => void;
  languages: string[];
  fetchLanguages: () => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

// Context Provider
export const UserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Check localStorage for login state
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
    () => localStorage.getItem('isLoggedIn') === 'true'
  );

  const [languages, setLanguages] = useState<string[]>([]);
  // let backendUrl = "https://totle-webisite-backend.onrender.com";
  const backendUrl = process.env.REACT_APP_API_URL;;
  const fetchLanguages = async () => {
    try {
      const response = await axios.get(`${backendUrl}/languages`);
      // console.log('res', response)
      const languageNames = response.data.map((language: { language_name: string }) => language.language_name);
      // console.log('languaegs', languageNames);
      setLanguages(languageNames);
    } catch (error) {
      console.error("Error fetching languages:", error);
    }
  };
  const login = () => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', 'true'); // Persist login state
  };

  const logout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn'); // Clear login state
  };

  return (
    <UserContext.Provider value={{ isLoggedIn, login, logout, languages, fetchLanguages }}>
      {children}
    </UserContext.Provider>
  );
};

// Custom Hook
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
