import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchUserInterests } from "../../services/adminUserService";
import { NavLink } from "react-router-dom/dist";
import { useAdminAuth } from "../../context/AdminAuthContext";

const AdminUserInterest: React.FC = () => {
  const { data: interests, isLoading } = useQuery({
    queryKey: ["userInterests"],
    queryFn: fetchUserInterests,
  });

  const { logoutAdmin } = useAdminAuth();

  return (
    <div className="min-h-screen bg-gray-950 text-white p-10 font-sans relative overflow-hidden flex flex-col items-center justify-center">
      {/* Elegant Futuristic UI */}
      <div className="absolute inset-0 bg-gradient-to-br from-black via-gray-900 to-gray-800 opacity-80 z-0" />
      <div className="relative w-full max-w-6xl bg-black/60 backdrop-blur-lg p-8 rounded-2xl border border-gray-700 shadow-[0_0_30px_rgba(255,0,0,0.3)] flex flex-col items-center">
        
        <h2 className="text-5xl font-extrabold text-red-500 tracking-wide drop-shadow-[0_0_15px_red] mb-8">
          User Interests
        </h2>
        
        <div className="flex justify-between w-full mb-6">
          <NavLink
            to="/admin/dashboard"
            className="bg-yellow-500 text-black px-6 py-3 rounded-lg font-semibold hover:bg-yellow-400 transition transform hover:scale-105 shadow-md border border-yellow-600"
          >
            🔙 Dashboard
          </NavLink>
          <button
            onClick={logoutAdmin}
            className="bg-red-700 text-white px-6 py-3 rounded-lg font-semibold hover:bg-red-600 transition transform hover:scale-105 shadow-md border border-red-900"
          >
            🚪 Logout
          </button>
        </div>

        {isLoading ? (
          <p className="text-yellow-400 text-xl animate-pulse">⚙️ Loading user interests...</p>
        ) : (
          <div className="w-full overflow-hidden rounded-lg shadow-lg">
            <table className="w-full border border-gray-700 rounded-lg text-white text-left">
              <thead className="bg-red-800 text-white text-lg tracking-wider uppercase border-b-2 border-gray-600">
                <tr>
                  <th className="p-4 border-r border-gray-700">User Name</th>
                  <th className="p-4 border-r border-gray-700">Interest</th>
                  <th className="p-4">Submitted On</th>
                </tr>
              </thead>
              <tbody className="text-md text-gray-300">
                {interests?.map((interest) => (
                  <tr
                    key={interest.id}
                    className="border-b border-gray-700 hover:bg-gray-800/80 transition-all duration-300 transform hover:scale-105 hover:shadow-md"
                  >
                    <td className="p-4 border-r border-gray-700">{interest.userName}</td>
                    <td className="p-4 border-r border-gray-700">{interest.message}</td>
                    <td className="p-4">{new Date(interest.createdAt).toLocaleString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdminUserInterest;
