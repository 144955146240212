import React, { useState, useEffect, useRef } from "react";
import { FaEdit, FaSave, FaUserCircle } from "react-icons/fa";
import { useParams, useNavigate } from "react-router-dom"; // ✅ Get user ID from URL

import { useAuth } from "../../context/AuthContext";
import { fetchUserProfile, updateUserProfile } from "../../services/userService";
import Select from "react-select";
import type { User } from "../../context/AuthContext";
import { getLanguages } from "../../services/userService";

// ✅ Default Profile Data (Ensures no `undefined` errors)
const defaultProfile: User = {
  id: 0,
  firstName: "",
  lastName: "",
  email: "",
  dob: "",
  gender: "",
  known_language_ids: [],
  preferred_language_id: 0,
  // educational_qualifications: [],
  // status: "Student",
  // currentOccupation: "",
  // skills: [],
  // years_of_experience: 0,
  location: "",
};

const Profile: React.FC = () => {
  const { user, setUser, logout } = useAuth();
  const { id } = useParams(); // ✅ Get the user ID from the URL
  const navigate = useNavigate();

  const isCurrentUser = user?.id?.toString?.() === id; // ✅ Check if viewing own profile

  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState<User>(user || defaultProfile);
  const [loading, setLoading] = useState(false);
  const [languageOptions, setLanguageOptions] = useState<{ value: number; label: string }[]>([]);
  const [languageSearch, setLanguageSearch] = useState("");
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [showPreferredDropdown, setShowPreferredDropdown] = useState(false);
  const preferredDropdownRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        preferredDropdownRef.current &&
        !preferredDropdownRef.current.contains(event.target as Node)
      ) {
        setShowPreferredDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  const toggleLanguage = (value: number) => {
    const current = Array.isArray(profileData.known_language_ids) ? profileData.known_language_ids : [];
    const updated = current.includes(value) ? current.filter((id) => id !== value) : [...current, value];
    handleInputChange("known_language_ids", updated);
  };

  useEffect(() => {
    if (!showLanguageDropdown) setLanguageSearch("");
  }, [showLanguageDropdown]);
  

  const dropdownRef = useRef<HTMLDivElement | null>(null);

  // Close dropdown on outside click
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setShowLanguageDropdown(false);
        setLanguageSearch("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    const fetchLanguages = async () => {
      const languages = await getLanguages();
      setLanguageOptions(languages); // ✅ Store directly
    };
  
    fetchLanguages();
  }, []);
  
  

  useEffect(() => {
    const loadUserProfile = async () => {
      setLoading(true);
      try {
        if (!isCurrentUser) {
          alert("❌ Unauthorized: You can only edit your own profile.");
          return;
        }
        
  
        const userData = await fetchUserProfile();
        if (userData) {
          setUser(userData);
          setProfileData({
            ...userData,
            known_language_ids: (userData.known_language_ids || []).map(Number),
            preferred_language_id: Number(userData.preferred_language_id),
          });
          
          
        }
      } catch (error) {
        console.error("❌ Error fetching user profile:", error);
      } finally {
        setLoading(false);
      }
    };
  
    if (id) loadUserProfile();
  }, [id]);

  const exitEditMode = () => {
    setShowLanguageDropdown(false);
    setLanguageSearch("");
    setIsEditing(false);
  };
  
  
  
  const handleInputChange = (field: keyof User, value: any) => {
    setProfileData((prevData) => ({
      ...prevData,
      [field]: value ?? prevData[field],
    }));
  };

  const handleSave = async () => {
    if (!isEditing) {
      setIsEditing(true);
      return;
    }
  
    setLoading(true); // ✅ Start loading
  
    try {
      const token = localStorage.getItem("userToken");
      if (!token) throw new Error("User is not authenticated.");
  
      const updatedUser = await updateUserProfile(token, profileData);
      // console.log('Updated User:', updatedUser);
  
      if (updatedUser && JSON.stringify(updatedUser) !== JSON.stringify(profileData)) {
        const formattedUser: User = {
          id: updatedUser.id,
          firstName: updatedUser.firstName || "",
          lastName: updatedUser.lastName || "",
          email: updatedUser.email || "",
          dob: updatedUser.dob ? updatedUser.dob.split("T")[0] : "",
          gender: updatedUser.gender || "",
          known_language_ids: Array.isArray(updatedUser.known_language_ids)
            ? updatedUser.known_language_ids.map(Number)
            : [],
          preferred_language_id: Number(updatedUser.preferred_language_id) || 0,
          // educational_qualifications: Array.isArray(updatedUser.educational_qualifications) ? updatedUser.educational_qualifications: [],
          // status: updatedUser.status || "Student",
          // currentOccupation: updatedUser.currentOccupation || "",
          // skills: Array.isArray(updatedUser.skills) ? updatedUser.skills : [],
          // years_of_experience: updatedUser.years_of_experience || 0,
          location: updatedUser.location || "",
        };
        console.log('ui updated user', formattedUser)
  
        setUser(formattedUser);
        setProfileData(formattedUser);
        // localStorage.setItem("userToken", JSON.stringify(formattedUser));
  
        alert("✅ Profile updated successfully!");
      } else {
        alert("⚠️ No changes detected.");
      }
    } catch (error) {
      console.error("❌ Error updating profile:", error);
      alert("❌ Failed to update profile. Please try again.");
    } finally {
      setTimeout(() => {
        setLoading(false);
        setShowLanguageDropdown(false);
        setIsEditing(false);
        exitEditMode();
      }, 300);
    }
  };
  
  
  
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-[#21717b] to-black px-6 py-20">
      <div className="bg-white/10 backdrop-blur-lg shadow-xl rounded-2xl p-8 w-full max-w-4xl border border-white/20 grid grid-cols-1 md:grid-cols-2 gap-6">
        
        {/* Left Panel - Profile Picture & Key Info */}
        <div className="flex flex-col items-center space-y-4">
          <div className="relative w-32 h-32">
            <FaUserCircle className="text-gray-300 text-8xl drop-shadow-lg" />
          </div>
          <h1 className="text-3xl font-bold text-white mt-3 drop-shadow-md">
            {profileData?.firstName ? `${profileData.firstName} ${profileData.lastName || ""}`.trim() : "Loading..."}
          </h1>
          {/* <p className="text-[#ff7b00] text-lg">{profileData?.currentOccupation || "No Occupation Listed"}</p> */}
          <p className="text-gray-400 text-sm">{profileData?.location || "Location not provided"}</p>
          {/* <div className="mt-4 flex space-x-3"> */}
            {/* <span className="bg-[#ff7b00] text-white text-xs px-3 py-1 rounded-full">{profileData?.status}</span> */}
            {/* <span className="bg-gray-700 text-white text-xs px-3 py-1 rounded-full">{profileData?.educational_qualifications || "Not provided"}</span> */}
          {/* </div> */}
          {isCurrentUser && (
  <button
    onClick={logout}
    className="mt-4 bg-red-600 hover:bg-red-700 text-white font-semibold py-2 px-6 rounded-lg shadow-md transition-all"
  >
    Logout
  </button>
)}

        </div>

        {/* Right Panel - Editable Profile Form */}
        <div className="bg-white/10 p-6 rounded-xl border border-white/20 shadow-md w-full">
          <div className="flex flex-row items-center justify-between mb-4">
          <h2 className="text-xl text-white font-semibold">Personal Details</h2>
          {isCurrentUser && (
  <button
    onClick={handleSave}
    disabled={loading}
    className={`flex items-center justify-center gap-2 px-6 py-2 font-semibold rounded-full shadow-md transition-all duration-300
    ${loading ? "cursor-not-allowed opacity-50" : "hover:scale-105"}
    ${isEditing ? "bg-green-500 hover:bg-green-600 text-white" : "bg-blue-500 hover:bg-blue-600 text-white"}`}
  >
    {loading ? (
      <span className="animate-spin border-t-2 border-white rounded-full w-4 h-4"></span>
    ) : (
      isEditing ? (
        <>
          <FaSave className="text-lg" /> Save
        </>
      ) : (
        <>
          <FaEdit className="text-lg" /> Edit
        </>
      )
    )}
  </button>
)}

          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {/* First Name */}
            <div>
              <label className="text-sm text-gray-300 uppercase">First Name</label>
              <input 
                type="text" 
                value={profileData?.firstName || ""} 
                onChange={(e) => handleInputChange("firstName", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div>

            {/* Last Name */}
            <div>
              <label className="text-sm text-gray-300 uppercase">Last Name</label>
              <input 
                type="text" 
                value={profileData?.lastName || ""} 
                onChange={(e) => handleInputChange("lastName", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div>

            {/* Date of Birth */}
            <div>
              <label className="text-sm text-gray-300 uppercase">Date of Birth<span className="text-red-500 font-bold text-xl">*</span></label>
              <input 
                type="date" 
                value={profileData.dob ? profileData.dob.split("T")[0] : ""}
                onChange={(e) => handleInputChange("dob", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div>

            {/* Gender */}
            <div>
              <label className="text-sm text-gray-300 uppercase">Gender<span className="text-red-500 font-bold text-xl">*</span></label>
              <select 
                value={profileData?.gender || ""} 
                onChange={(e) => handleInputChange("gender", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              >
                <option className="text-black" value="Male">Male</option>
                <option className="text-black" value="Female">Female</option>
                <option className="text-black" value="Other">Other</option>
              </select>
            </div>


            {/* Qualification */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Qualification<span className="text-red-500 font-bold text-xl">*</span></label>
              <input 
                type="text" 
                value={Array.isArray(profileData.educational_qualifications)
                  ? profileData.educational_qualifications.join(", ")
                  : profileData.educational_qualifications || ""}
                onChange={(e) => handleInputChange("educational_qualifications", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div> */}

            {/* Status */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Status</label>
              <select 
                value={profileData?.status || "Student"} 
                onChange={(e) => handleInputChange("status", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              >
                <option className="text-black" value="Student">Student</option>
                <option className="text-black" value="Working">Working</option>
                <option className="text-black" value="Hybrid">Hybrid</option>
              </select>
            </div> */}

            {/* Current Occupation */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Current Occupation</label>
              <input 
                type="text" 
                value={profileData?.currentOccupation || ""} 
                onChange={(e) => handleInputChange("currentOccupation", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div> */}

            {/* Known languages */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Known Languages</label>
              <Select
                isMulti
                options={languageOptions}
                value={languageOptions.filter(lang =>(profileData.known_language_ids || []).includes(lang.value) )}
                onChange={selected => handleInputChange("known_language_ids", selected.map(lang => lang.value))}
                className="basic-multi-select"
                isDisabled={!isEditing}
              />

            </div> */}

            <div className="relative">
              <label className="text-sm text-gray-300 uppercase">Known Languages<span className="text-red-500 font-bold text-xl">*</span></label>
              <div className="relative">
                <button
                  type="button"
                  onClick={() => setShowLanguageDropdown((prev) => !prev)}
                  className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 text-left focus:outline-none focus:border-[#ff7b00] transition-all"
                  disabled={!isEditing}
                >
                  {Array.isArray(profileData.known_language_ids) &&
                  profileData.known_language_ids.length > 0
                    ? languageOptions
                        .filter((lang) =>
                          profileData.known_language_ids.includes(lang.value)
                        )
                        .map((lang) => lang.label)
                        .join(", ")
                    : "Select languages"}
                </button>

                {showLanguageDropdown && isEditing && (
                  <div
                    ref={dropdownRef}
                    className="absolute z-10 mt-2 w-full bg-white rounded-lg shadow-lg border border-gray-300 max-h-60 overflow-y-auto transition-all duration-150 ease-in-out"
                  >
                    {/* 🔍 Search Box */}
                    <div className="px-3 py-2 sticky top-0 bg-white border-b border-gray-200">
                      <input
                        type="text"
                        value={languageSearch}
                        onChange={(e) => setLanguageSearch(e.target.value)}
                        placeholder="Search languages..."
                        className="w-full px-2 py-1 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-[#ff7b00]"
                      />
                    </div>

                    {/* ✅ Language List */}
                    {languageOptions
                      .filter((lang) =>
                        lang.label.toLowerCase().includes(languageSearch.toLowerCase())
                      )
                      .map((lang) => (
                        <label
                          key={lang.value}
                          className="flex items-center px-4 py-2 hover:bg-gray-100 text-gray-800 cursor-pointer"
                        >
                          <input
                            type="checkbox"
                            checked={
                              Array.isArray(profileData.known_language_ids) &&
                              profileData.known_language_ids.includes(lang.value)
                            }
                            onChange={() => toggleLanguage(lang.value)}
                            className="mr-2"
                          />
                          {lang.label}
                        </label>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Preferred Languages */}
            <div className="relative z-10">
              <label className="text-sm text-gray-300 uppercase">Preferred Language<span className="text-red-500 font-bold text-xl">*</span></label>
                <div ref={preferredDropdownRef}>
                  <button
                    type="button"
                    onClick={() => setShowPreferredDropdown((prev) => !prev)}
                    className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 text-left focus:outline-none focus:border-[#ff7b00] transition-all"
                    disabled={!isEditing}
                  >
                    {
                      languageOptions.find(
                        (lang) => lang.value === profileData.preferred_language_id
                      )?.label || "Select Language"
                    }
                  </button>
                  {showPreferredDropdown && isEditing && (
                    <div className="absolute z-20 mt-1 w-full bg-white rounded-lg shadow-lg border border-gray-300 max-h-60 overflow-y-auto transition-all duration-150 ease-in-out">
                      {languageOptions.map((lang) => (
                        <div
                          key={lang.value}
                          onClick={() => {
                            handleInputChange("preferred_language_id", lang.value);
                            setShowPreferredDropdown(false);
                          }}
                          className="px-4 py-2 hover:bg-gray-100 text-gray-800 cursor-pointer"
                        >
                          {lang.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
            </div>

            {/* Skills (Free-text input) */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Skills (comma-separated)</label>
              <input
                type="text"
                value={profileData?.skills?.join(", ") || ""}
                onChange={(e) => handleInputChange("skills", e.target.value.split(",").map(skill => skill.trim()))}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
                placeholder="E.g., JavaScript, Python, UI/UX Design"
              />
            </div> */}



            {/* Experience */}
            {/* <div>
              <label className="text-sm text-gray-300 uppercase">Years of Experience</label>
              <input 
                type="number" 
                value={profileData?.years_of_experience || 0} 
                onChange={(e) => handleInputChange("years_of_experience", Math.max(0, Math.min(50, parseInt(e.target.value, 10) || 0)))}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
                min="0"
                max="60"
              />
            </div> */}

            {/* Location */}
            <div>
              <label className="text-sm text-gray-300 uppercase">Location</label>
              <input 
                type="text" 
                value={profileData?.location || ""} 
                onChange={(e) => handleInputChange("location", e.target.value)}
                className="w-full bg-transparent border-b border-gray-400 text-white px-3 py-2 focus:outline-none focus:border-[#ff7b00] transition-all"
                disabled={!isEditing}
              />
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
