import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FaBookOpen, FaChalkboardTeacher, FaAward, FaChartLine } from 'react-icons/fa';
import { FaLinkedin, FaTwitter, FaInstagram, FaFacebookSquare, FaGlobe } from 'react-icons/fa';
import { NavLink, useNavigate } from 'react-router-dom';
import Footer from '../Footer/Footer';

const Product = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  const navigate = useNavigate();

  const handleLinkClick = () => {
    navigate('/auth');
    window.scrollTo(0, 0);
  };

  return (
    <>
      {/* Hero Section */}
      <section
        className="relative min-h-screen flex items-center justify-center text-center bg-cover bg-fixed"
        style={{
          backgroundImage: "url('/images/product-hero.jpg')",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <div className="absolute inset-0 bg-black bg-opacity-50"></div>
        <div className="relative z-10 text-white px-6">
          <h1
            className="text-5xl md:text-6xl font-bold text-white bg-clip-text mb-6"
            data-aos="fade-up"
          >
            Redefining How the World Learns
          </h1>
          <p
            className="max-w-3xl mx-auto text-lg md:text-xl"
            data-aos="fade-up"
            data-aos-delay="300"
          >
            TOTLE isn’t just an education platform—it’s an ecosystem.  
            Learn what you want, teach what you know, compete, grow, and build your future in a system that finally works for you.
          </p>
        </div>
      </section>

      {/* Key Features Section */}
      <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 px-6 md:px-12 py-20 bg-gradient-to-r from-orange-50 via-white to-orange-50 dark:bg-gradient-to-r dark:from-gray-800 dark:via-gray-900 dark:to-gray-800">
        {[
      {
        icon: <FaChalkboardTeacher className="text-6xl text-green-500 mb-4" />,
        title: 'Teach Platform',
        description:
          'You don’t need a fancy degree or years of experience to teach—if you know a topic well, you can share it. TOTLE makes teaching accessible to everyone by breaking down education into small, manageable topics. We give you the tools, structured progression, and AI-driven insights to maximize your impact. Set your own schedule, teach only the topics you love, and earn for your expertise—without the hassle of long-term commitments. It’s education on your terms.',
      },
      
          {
            icon: <FaBookOpen className="text-6xl text-blue-500 mb-4" />,
            title: 'Learn Platform',
            description:
              'The internet is full of free resources—use them. Learn on your own, experiment, explore. But when you hit a wall, when you need clarity on a topic, come to us. TOTLE lets you pay only for what you need—no overpriced courses, no unnecessary content. Pick a topic, get unstuck, move forward. One-on-one expert guidance, AI-curated learning paths, and total flexibility. Learn smarter, not harder.',
          },
          
          {
            icon: <FaAward className="text-6xl text-yellow-500 mb-4" />,
            title: 'Endeavour Platform',
            description:
              'Education isn’t just about absorbing knowledge—it’s about applying it. The Endeavour Platform is where learners put theory into practice, build real-world skills, and prove what they know. Engage in competitions, hands-on projects, and challenges designed to push your limits and showcase your abilities. Work on practical problems, collaborate with peers, and make your learning count where it truly matters—the real world.',
          },
          
          {
            icon: <FaChartLine className="text-6xl text-purple-500 mb-4" />,
            title: 'Marketplace',
            description:
              'No more peak pricing, no more artificial demand spikes. TOTLE’s Marketplace reveals real-time learning trends, giving teachers visibility on what’s in demand and allowing them to strategically meet the need. By distributing demand across multiple educators, we prevent price inflation—ensuring learners always get fair pricing and more options.',
          },
          
        ].map((platform, index) => (
          <div
            key={index}
            className="flex flex-col items-center  group bg-white dark:bg-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300"
            data-aos="fade-up"
            data-aos-delay={400 + index * 100}
          >
            {platform.icon}
            <div className="flex items-center justify-center">
            <h3 className="text-2xl font-semibold text-gray-800 dark:text-white mb-4">
              {platform.title}
            </h3>
            </div>
            <p className="text-gray-600 dark:text-gray-300 text-center">
              {platform.description}
            </p>
          </div>
        ))}
      </section>

      {/* User Journey Section */}
      <section
  className="py-20 relative text-white bg-fixed bg-cover"
  style={{
    backgroundImage: "url('/images/user-journey-bg.jpg')",
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  }}
>
  <div className="absolute inset-0 bg-black bg-opacity-60"></div>
  <div className="relative z-10 container mx-auto px-6">
    <h2 className="text-4xl text-tangering font-bold text-center mb-12" data-aos="fade-up">
      The Journey of a TOTLE User
    </h2>
    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-8">
      {[
        {
          step: 'Step 1',
          title: 'Choose Your Starting Point',
          description:
            'Not everyone learns the same way. Whether you want to explore topics freely, follow structured learning paths, or dive straight into expert sessions, TOTLE adapts to your needs.',
        },
        {
          step: 'Step 2',
          title: 'AI-Powered Personalization',
          description:
            'Our AI understands your strengths, weaknesses, and goals. It suggests what to learn next, refines your study plan, and keeps you on track to maximize efficiency.',
        },
        {
          step: 'Step 3',
          title: 'One-on-One Interactive Learning',
          description:
            'Learn at your own pace with educators who match your learning style. Ask questions, get personalized feedback, and focus only on what truly matters to you.',
        },
        {
          step: 'Step 4',
          title: 'Apply Your Knowledge',
          description:
            'True learning happens when you use what you know. Participate in hands-on projects, join competitions, and solve real-world challenges that make your knowledge practical.',
        },
        {
          step: 'Step 5',
          title: 'Teach and Earn',
          description:
            'Mastered a topic? Start teaching. Share your expertise, help others, and generate income by guiding learners who need your knowledge.',
        },
        {
          step: 'Step 6',
          title: 'Unlock Your Future',
          description:
            'TOTLE isn’t just about learning. It’s about growth. Whether you become an industry expert, an in-demand educator, or an innovator, the platform helps you take the next step.',
        },
      ].map((journey, index) => (
        <div
          key={index}
          className="bg-gradient-to-r from-orange-50 via-white to-orange-50 dark:from-gray-700 dark:via-gray-800 dark:to-gray-700 p-8 rounded-lg shadow-lg text-center"
          data-aos="fade-up"
          data-aos-delay={300 + index * 100}
        >
          <h3 className="text-2xl font-bold text-gray-800 dark:text-white mb-4">
            {journey.step}: {journey.title}
          </h3>
          <p className="text-gray-600 dark:text-gray-300">{journey.description}</p>
        </div>
      ))}
    </div>
  </div>
</section>


      {/* Call to Action */}
      <section className="py-16 bg-white text-center shadow-lg rounded-lg mx-auto max-w-5xl">
        <div className="container mx-auto px-6 sm:px-10 lg:px-16">
          <h2 className="text-5xl font-extrabold text-[#016f66] mb-6" data-aos="fade-up">
            🚀 Join the Beta Now
          </h2>
          <p className="text-lg text-gray-700 max-w-3xl mx-auto leading-relaxed" data-aos="fade-up" data-aos-delay="200">
            Be part of the future of education. TOTLE is redefining how learning and teaching work. Join the Beta today.
          </p>
          <div className="mt-8" data-aos="fade-up" data-aos-delay="400">
            <NavLink to="/auth" onClick={handleLinkClick} state={{ fromSignup: true }}  className="bg-[#f46b24] hover:bg-[#016f66] text-white font-bold text-xl py-4 px-12 rounded-full shadow-lg hover:shadow-xl transition duration-300 transform hover:scale-105">
              Join Beta Now
            </NavLink>
          </div>
        </div>
      </section>

      
            {/* Footer */}
      <Footer handleLinkClick={handleLinkClick}/>

    </>
  );
};

export default Product;
